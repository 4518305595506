#map {
    /* height: 600px !important; */
    width: 100% !important;
}

/* Search box */
#search-box {
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 -1px 0 rgb(0 0 0 / 2%);
    height: 48px;
    width: 350px;
    font-size: 15px;
    border-radius: 5px;
    position: absolute;
    left: calc(calc(100% - 650px) / 2);
    transform: translateX(calc(-50% + 338px));
    top: 40px;
    box-sizing: border-box;
    z-index: 9999;
}
#search-box img:hover {
    cursor: pointer;
    opacity: .6;
}
#search-input {
    border: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    text-indent: 15px;
    left: 0;
    top: 0;
    font-weight: 400;
    font-size: 14px;
    font-weight: 500;
}
#search-box img {
    position: absolute;
    z-index: 99999;
    right: 15px;
    top: 12px;
    width: 20px;
}
::placeholder {
    color: #A1AAAF;
}

/* Location control */
#location-control {
    position: absolute;
    right: 35px;
    bottom: 106px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 5px;
    background-color: #FFF;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 -1px 0 rgb(0 0 0 / 2%);
    z-index: 999999;
    text-align: center;
    box-sizing: border-box;
}

#location-control:hover {
    cursor: pointer;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #2ca358;
    border-bottom: 16px solid #2ca358;
    width: 100px;
    height: 100px;
    margin: auto;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
