@charset "UTF-8";
.dark #preloader{
    background-color:#232323
}
#preloader{
    background-color:#f7f7f7;
    bottom:0;
    left:0;
    position:fixed;
    right:0;
    top:0;
    z-index:999999
}
.form-group{
    margin-bottom:15px
}
.preloader{
    box-sizing:border-box;
    left:50%;
    margin-left:-25px;
    margin-top:-25px;
    padding:0;
    text-align:left;
    top:50%
}
.preloader,.preloader span{
    display:inline-block;
    height:50px;
    position:absolute;
    width:50px
}
.preloader span{
    animation:preloader 1.3s linear infinite;
    background:#00a94f;
    border-radius:100%
}
.preloader span:last-child{
    animation-delay:-.8s;
    -webkit-animation-delay:-.8s
}
@keyframes preloader{
    0%{
        opacity:.5;
        transform:scale(0)
    }
    to{
        opacity:0;
        transform:scale(1)
    }
}
i.req{
    color:#e41515
}
*{
    outline:none
}
body{
    background:#fff;
    color:#4e5c79;
    font-family: Arial, Helvetica, sans-serif;
    font-size:16px;
    font-weight:400;
    margin:0;
    overflow-x:hidden!important
}
.modal-open{
    overflow:hidden!important
}
html{
    background:#fff;
    min-height:100%;
    position:relative
}
a{
    -webkit-text-decoration-skip:objects;
    background-color:transparent;
    color:#2d3954;
    text-decoration:none
}
a:focus,a:hover{
    text-decoration:none
}
a.link,a.link:focus,a.link:hover,a:focus,a:hover{
    color:#fd5332
}
a.text-success:focus,a.text-success:hover{
    color:#0fca98!important
}
a.text-primary:focus,a.text-primary:hover{
    color:#fd5332!important
}
a.text-warning:focus,a.text-warning:hover{
    color:#ff9b20!important
}
a.text-danger:focus,a.text-danger:hover{
    color:#f33066!important
}
a.white-link{
    color:#fff
}
a.white-link:focus,a.white-link:hover{
    color:#263238
}
.img-responsive{
    display:inline-block;
    height:auto;
    width:100%
}
.img-rounded{
    border-radius:4px
}
html body .mdi-set,html body .mdi:before{
    line-height:normal
}
section{
    padding:80px 0
}
section.min{
    padding:50px 0
}
p{
    line-height:1.8
}
.gray-bg{
    background:#f7f9fc ;
    background-position:50%;
    background-size:cover
}
.gray{
    background:#f7f9fc
}
.gray-simple{
    background:#f3f7fd
}
.bg-title{
    background:#2540a2
}
.bg-1{
    background:#f7d0cb
}
.bg-2{
    background:#dde9ed
}
.tbl{
    display:table
}
.full-height{
    height:100%
}
.rtl{
    position:relative
}
.mr-2{
    margin-right:8px
}
.mr-1{
    margin-right:4.5px
}
.alert p{
    margin:0
}
.table-cell{
    display:table-cell;
    vertical-align:middle
}
.no-ul-list{
    padding:0
}
.no-ul-list li{
    list-style:none
}
.progress{
    margin-bottom:1.5em
}
.full-width{
    width:100%
}
address,blockquote,dd,dl,dt,ol,p,ul{
    margin:0 0 10px
}
.owl-carousel .item{
    padding:0 15px
}
.explore-content p{
    font-family:sans-serif;
    font-size:19px;
}
.explore-content h1,.explore-content h2{
    font-family:sans-serif;
    font-weight:600
}
.shadow-0{
    box-shadow:none!important
}
h1,h2,h3,h4,h5,h6{
    color:#2d3954;
    font-family:var(--font-heading);
    font-weight:700;
    text-transform:none
}
h5,h6{
    color:#2d3954
}
.font-2{
    font-family:Montserrat,sans-serif
}
h1{
    font-size:36px;
    line-height:40px
}
h2{
    font-size:30px;
    line-height:36px
}
h3{
    font-size:24px;
    line-height:30px
}
h4{
    font-size:21px;
    line-height:26px
}
h5{
    font-size:18px;
    line-height:22px
}
h6{
    font-size:16px;
    line-height:20px
}
.lead{
    font-size:20px!important
}
.lead-i{
    font-family:Lora,serif;
    font-size:22px!important;
    font-style:italic
}
.display-5{
    font-size:3rem
}
.display-6{
    font-size:36px
}
.display-7{
    font-size:26px
}
.box{
    border-radius:4px;
    padding:10px
}
html body .dl{
    display:inline-block
}
html body .db{
    display:block
}
.no-wrap td,.no-wrap th{
    white-space:nowrap
}
.circle{
    border-radius:100%
}
.theme-cl{
    color:#fd5332
}
.theme-bg{
    background:#fd5332
}
ul.list-style{
    margin:0;
    padding:0
}
ul.list-style li{
    line-height:1.5;
    list-style:none;
    margin-bottom:1.2em;
    padding-left:30px;
    position:relative
}
ul.list-style li:before{
    color:#27b737;
    content:"\e64c";
    font-family:themify;
    font-size:16px;
    left:0;
    position:absolute;
    top:0
}
ul.list-style.style-2 li:before{
    color:#0273ff;
    content:"\e628"!important
}
.ht-10{
    height:10px
}
.ht-20{
    height:20px
}
.ht-30{
    height:30px
}
.ht-40{
    height:40px
}
.ht-50{
    height:50px
}
.ht-60{
    height:60px
}
.ht-70{
    height:70px
}
.ht-80{
    height:80px
}
.ht-100{
    height:100px
}
.ht-110{
    height:110px
}
.ht-120{
    height:120px
}
.ht-130{
    height:130px
}
.ht-140{
    height:140px
}
.ht-150{
    height:150px
}
.ht-160{
    height:160px
}
.ht-170{
    height:170px
}
.ht-180{
    height:180px
}
.ht-190{
    height:190px
}
.ht-200{
    height:200px
}
.ht-100{
    height:100%
}
.h-100,.ht-100{
    min-height:580px
}
.h-100{
    height:100vh
}
.type--fade{
    opacity:.5
}
.type--uppercase{
    text-transform:uppercase
}
.type--bold{
    font-weight:700
}
.type--italic{
    font-style:italic
}
.type--fine-print{
    font-size:.85714286em
}
.type--strikethrough{
    opacity:.5;
    text-decoration:line-through
}
.type--underline{
    text-decoration:underline
}
.pos-vertical-center{
    position:relative;
    top:50%;
    transform:translateY(-50%);
    -webkit-transform:translateY(-50%)
}
blockquote{
    border-left:0;
    font-size:1.78571429em;
    font-style:italic;
    line-height:1.5em;
    margin:0;
    padding:0
}
blockquote:not(:last-child){
    margin-bottom:1.04em
}
.list-inline li{
    margin-left:0;
    padding:0 1em
}
.list-inline li:first-child{
    padding-left:0
}
.list-inline li:last-child{
    padding-right:0
}
.list-inline.list-inline--narrow li{
    padding:0 .5em
}
.list-inline.list-inline--wide li{
    padding:0 2em
}
.color--white{
    color:#fff!important
}
.color--dark{
    color:#252525!important
}
.color--success{
    color:#4ebf56!important
}
.color--error{
    color:#e23636!important
}
.bg--dark{
    background:#1f2025!important
}
.bg--dark:not(.nav-bar):not(.bar){
    color:#fff
}
.bg--dark .boxed:not([class*=bg-]),.bg--dark .large-features-2,.bg--dark .large-features-box,.bg--dark .pr-table-box,.bg--dark .pricing-wrap,.bg--dark .veticle-large-features-box,.imagebg:not(.image--light) .boxed:not([class*=bg-]){
    background:none;
    color:#fff
}
.bg--dark .boxed:not([class*=bg-]).boxed--border,.imagebg:not(.image--light) .boxed:not([class*=bg-]).boxed--border{
    border-color:#2a2b31
}
.bg--dark:not(.nav-bar):not(.bar) a:not(.btn),.bg--dark:not(.nav-bar):not(.bar) h1,.bg--dark:not(.nav-bar):not(.bar) h2,.bg--dark:not(.nav-bar):not(.bar) h3,.bg--dark:not(.nav-bar):not(.bar) h4,.bg--dark:not(.nav-bar):not(.bar) h5,.bg--dark:not(.nav-bar):not(.bar) h6,.bg--dark:not(.nav-bar):not(.bar) i,.bg--dark:not(.nav-bar):not(.bar) p,.bg--dark:not(.nav-bar):not(.bar) span:not(.btn__text){
    color:#fff
}
.bg--site{
    background:#fff
}
.bg--primary a:not(.btn),.bg--primary h1,.bg--primary h2,.bg--primary h3,.bg--primary h4,.bg--primary h5,.bg--primary h6,.bg--primary i,.bg--primary p,.bg--primary span,.bg--primary ul{
    color:#fff
}
.bg--primary .color--primary{
    color:#fff!important
}
.bg--light{
    background:#fff
}
.bg--light a:not(.btn),.bg--light p,.bg--light span,.bg--light ul{
    color:#666
}
.bg--light h1,.bg--light h2,.bg--light h3,.bg--light h4,.bg--light h5,.bg--light h6,.bg--light i{
    color:#252525
}
.bg--error{
    background:#e23636
}
.bg--success{
    background:#4ebf56
}
.imagebg:not(.image--light) .bg--light a:not(.btn),.imagebg:not(.image--light) .bg--light p,.imagebg:not(.image--light) .bg--light span,.imagebg:not(.image--light) .bg--light ul{
    color:#666
}
.imagebg:not(.image--light) .bg--light h1,.imagebg:not(.image--light) .bg--light h2,.imagebg:not(.image--light) .bg--light h3,.imagebg:not(.image--light) .bg--light h4,.imagebg:not(.image--light) .bg--light h5,.imagebg:not(.image--light) .bg--light h6,.imagebg:not(.image--light) .bg--light i{
    color:#252525
}
.imagebg:not(.image--light) .bg--secondary{
    background:hsla(0,0%,98%,.2)
}
.image-bg:not(.image-light) :not(a){
    color:#fff
}
.color--facebook{
    color:#3b5998!important
}
.color--twitter{
    color:#00aced!important
}
.color--googleplus{
    color:#dd4b39!important
}
.color--instagram{
    color:#125688!important
}
.color--pinterest{
    color:#cb2027!important
}
.color--dribbble{
    color:#ea4c89!important
}
.color--behance{
    color:#053eff!important
}
.color--linkedin{
    color:#1685b9!important
}
.bg--facebook{
    background:#3b5998!important;
    color:#fff!important
}
.bg--twitter{
    background:#00aced!important;
    color:#fff!important
}
.bg--googleplus{
    background:#dd4b39!important;
    color:#fff!important
}
.bg--instagram{
    background:#125688!important;
    color:#fff!important
}
.bg--pinterest{
    background:#cb2027!important;
    color:#fff!important
}
.bg--dribbble{
    background:#ea4c89!important;
    color:#fff!important
}
.bg--behance{
    background:#053eff!important;
    color:#fff!important
}
.bg--linkedin{
    background:#1685b9!important;
    color:#fff!important
}
.height-10,.height-100,.height-20,.height-30,.height-40,.height-50,.height-60,.height-70,.height-80,.height-90{
    height:auto;
    padding:5em 0
}
.bg-img-holder{
    background-position:50% 50%!important;
    background-size:cover!important;
    background:#252525;
    height:100%;
    left:0;
    opacity:0;
    position:absolute;
    top:0;
    transition:opacity .3s linear;
    -webkit-transition:opacity .3s linear;
    z-index:0
}
.bg-img-holder:not([class*=col-]){
    width:100%
}
.bg-img-holder.background--bottom{
    background-position:50% 100%!important
}
.bg-img-holder.background--top{
    background-position:50% 0!important
}
.image--light .bg-img-holder{
    background:none
}
.bg-img-holder img{
    display:none
}
.imagebg.border--round{
    overflow:hidden
}
[data-overlay]:before{
    background:#252525
}
[data-overlay] :not(.container):not(.bg-img-holder){
    z-index:2
}
[data-overlay].image--light:before{
    background:#fff
}
[data-overlay].bg--primary:before{
    background:#4a90e2
}
[data-overlay="10"]:before{
    opacity:1
}
[data-overlay="0"]:before{
    opacity:0
}
[data-scrim-bottom]{
    position:relative
}
[data-scrim-bottom]:before{
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    background:linear-gradient(180deg,transparent 0,#252525);
    bottom:0;
    content:"";
    height:80%;
    left:0;
    position:absolute;
    width:100%;
    z-index:1
}
[data-scrim-bottom]:not(.image--light) h1,[data-scrim-bottom]:not(.image--light) h2,[data-scrim-bottom]:not(.image--light) h3,[data-scrim-bottom]:not(.image--light) h4,[data-scrim-bottom]:not(.image--light) h5,[data-scrim-bottom]:not(.image--light) h6{
    color:#a5a5a5;
    color:#f1f1f1
}
[data-scrim-bottom]:not(.image--light) p,[data-scrim-bottom]:not(.image--light) span,[data-scrim-bottom]:not(.image--light) ul{
    color:#e6e6e6
}
[data-scrim-bottom].image--light:before{
    background:#fff
}
[data-scrim-bottom="1"]:before{
    opacity:.1
}
[data-scrim-bottom="2"]:before{
    opacity:.2
}
[data-scrim-bottom="3"]:before{
    opacity:.3
}
[data-scrim-bottom="4"]:before{
    opacity:.4
}
[data-scrim-bottom="5"]:before{
    opacity:.5
}
[data-scrim-bottom="6"]:before{
    opacity:.6
}
[data-scrim-bottom="7"]:before{
    opacity:.7
}
[data-scrim-bottom="8"]:before{
    opacity:.8
}
[data-scrim-bottom="9"]:before{
    opacity:.9
}
[data-scrim-bottom="10"]:before{
    opacity:1
}
[data-scrim-top]{
    position:relative
}
[data-scrim-top]:before{
    background:linear-gradient(180deg,#252525 0,transparent);
    content:"";
    height:80%;
    left:0;
    position:absolute;
    top:0;
    width:100%;
    z-index:1
}
[data-scrim-top]:not(.image--light) h1,[data-scrim-top]:not(.image--light) h2,[data-scrim-top]:not(.image--light) h3,[data-scrim-top]:not(.image--light) h4,[data-scrim-top]:not(.image--light) h5,[data-scrim-top]:not(.image--light) h6{
    color:#fff
}
[data-scrim-top]:not(.image--light) p,[data-scrim-top]:not(.image--light) span,[data-scrim-top]:not(.image--light) ul{
    color:#e6e6e6
}
[data-scrim-top].image--light:before{
    background:#fff
}
[data-scrim-top="1"]:before{
    opacity:.1
}
[data-scrim-top="2"]:before{
    opacity:.2
}
[data-scrim-top="3"]:before{
    opacity:.3
}
[data-scrim-top="4"]:before{
    opacity:.4
}
[data-scrim-top="5"]:before{
    opacity:.5
}
[data-scrim-top="6"]:before{
    opacity:.6
}
[data-scrim-top="7"]:before{
    opacity:.7
}
[data-scrim-top="8"]:before{
    opacity:.8
}
[data-scrim-top="9"]:before{
    opacity:.9
}
[data-scrim-top="10"]:before{
    opacity:1
}
.imagebg{
    position:relative
}
.imagebg .container{
    z-index:2
}
.imagebg .container:not(.pos-absolute){
    position:relative
}
.imagebg:not(.image--light) blockquote,.imagebg:not(.image--light) h1,.imagebg:not(.image--light) h2,.imagebg:not(.image--light) h3,.imagebg:not(.image--light) h4,.imagebg:not(.image--light) h5,.imagebg:not(.image--light) h6,.imagebg:not(.image--light) p,.imagebg:not(.image--light) ul{
    color:#fff
}
.imagebg:not(.image--light) .bg--white h1,.imagebg:not(.image--light) .bg--white h2,.imagebg:not(.image--light) .bg--white h3,.imagebg:not(.image--light) .bg--white h4,.imagebg:not(.image--light) .bg--white h5,.imagebg:not(.image--light) .bg--white h6{
    color:#252525
}
.imagebg:not(.image--light) .bg--white p,.imagebg:not(.image--light) .bg--white ul{
    color:#666
}
div[data-overlay] h1,div[data-overlay] h2,div[data-overlay] h3,div[data-overlay] h4,div[data-overlay] h5,div[data-overlay] h6,div[data-overlay] p{
    color:#fff
}
.parallax{
    overflow:hidden
}
.icon{
    font-size:3.14285714em;
    line-height:1em
}
.icon--xs{
    font-size:1em
}
.icon--sm{
    font-size:2.35714286em
}
.icon--lg{
    font-size:5.57142857em
}
.imagebg:not(.image--light) span{
    color:#fff
}
@keyframes spinner-border{
    to{
        transform:rotate(1turn)
    }
}
.spinner-border{
    animation:spinner-border .75s linear infinite;
    border:3px solid;
    border-radius:50%;
    border-right:3px solid transparent;
    display:inline-block;
    height:30px;
    vertical-align:text-bottom;
    width:30px
}
.sr-only{
    clip:rect(0,0,0,0);
    border:0;
    height:1px;
    overflow:hidden;
    padding:0;
    position:absolute;
    white-space:nowrap;
    width:1px
}
@keyframes spinner-grow{
    0%{
        transform:scale(0)
    }
    50%{
        opacity:1
    }
}
.spinner-grow{
    animation:spinner-grow .75s linear infinite;
    background-color:#fff;
    border-radius:50%;
    display:inline-block;
    opacity:0;
    vertical-align:text-bottom
}
.spinner-grow,.spinner-grow-sm{
    height:30px;
    width:30px
}
.clear{
    clear:both
}
ol li{
    margin:5px 0
}
.bg-cover{
    background-position:50%!important;
    background-size:cover!important
}
.default-bg{
    background-position:0 0!important;
    background-size:initial!important
}
.image-bg-wrap{
    background-position:50%!important;
    background-size:cover!important;
    position:relative
}
.image-bg-wrap:before{
    background:#182538;
    bottom:0;
    content:"";
    display:block;
    left:0;
    opacity:.8;
    position:absolute;
    right:0;
    top:0
}
html body .p-0{
    padding:0
}
html body .p-10{
    padding:10px
}
html body .p-15{
    padding:15px
}
html body .p-20{
    padding:20px
}
html body .p-30{
    padding:30px
}
html body .p-40{
    padding:40px
}
html body .p-l-0{
    padding-left:0
}
html body .p-l-10{
    padding-left:10px
}
html body .p-l-20{
    padding-left:20px
}
html body .p-r-0{
    padding-right:0
}
html body .p-r-10{
    padding-right:10px
}
html body .p-r-20{
    padding-right:20px
}
html body .p-r-30{
    padding-right:30px
}
html body .p-r-40{
    padding-right:40px
}
html body .p-t-0{
    padding-top:0
}
html body .p-t-10{
    padding-top:10px
}
html body .p-t-20{
    padding-top:20px
}
html body .p-t-30{
    padding-top:30px
}
html body .p-b-0{
    padding-bottom:0
}
html body .p-b-5{
    padding-bottom:5px
}
html body .p-b-10{
    padding-bottom:10px
}
html body .p-b-20{
    padding-bottom:20px
}
html body .p-b-30{
    padding-bottom:30px
}
html body .p-b-40{
    padding-bottom:40px
}
html body .mr-auto{
    margin:auto
}
html body .m-0{
    margin:0
}
html body .m-l-5{
    margin-left:5px
}
html body .m-l-10{
    margin-left:10px
}
html body .m-l-15{
    margin-left:15px
}
html body .m-l-20{
    margin-left:20px
}
html body .m-l-30{
    margin-left:30px
}
html body .m-l-40{
    margin-left:40px
}
html body .m-r-5{
    margin-right:5px
}
html body .m-r-10{
    margin-right:10px
}
html body .m-r-15{
    margin-right:15px
}
html body .m-r-20{
    margin-right:20px
}
html body .m-r-30{
    margin-right:30px
}
html body .m-r-40{
    margin-right:40px
}
html body .m-t-0{
    margin-top:0
}
html body .m-t-5{
    margin-top:5px
}
html body .m-t-10{
    margin-top:10px
}
html body .m-t-15{
    margin-top:15px
}
html body .m-t-20{
    margin-top:20px
}
html body .m-t-30{
    margin-top:30px
}
html body .m-t-40{
    margin-top:40px
}
html body .m-b-0{
    margin-bottom:0
}
html body .m-b-5{
    margin-bottom:5px
}
html body .m-b-10{
    margin-bottom:10px
}
html body .m-b-15{
    margin-bottom:15px
}
html body .m-b-20{
    margin-bottom:20px
}
html body .m-b-30{
    margin-bottom:30px
}
html body .m-b-40{
    margin-bottom:40px
}
html body .vt{
    vertical-align:top
}
html body .vm{
    vertical-align:middle
}
html body .vb{
    vertical-align:bottom
}
.op-8{
    opacity:.8
}
.op-7{
    opacity:.7
}
.op-5{
    opacity:.5
}
.op-3{
    opacity:.3
}
html body .font-bold{
    font-weight:600
}
html body .font-normal{
    font-weight:400
}
html body .font-light{
    font-weight:300
}
html body .font-medium{
    font-weight:500
}
html body .font-16{
    font-size:16px
}
html body .font-14{
    font-size:14px
}
html body .font-13{
    font-size:13px
}
html body .font-10{
    font-size:10px
}
html body .font-18{
    font-size:18px
}
html body .font-20{
    font-size:20px
}
html body .b-0{
    border:none!important
}
html body .b-r{
    border-right:1px solid #e0ecf5!important
}
html body .b-l{
    border-left:1px solid #e0ecf5!important
}
html body .b-b{
    border-bottom:1px solid #e0ecf5!important
}
html body .b-t{
    border-top:1px solid #e0ecf5!important
}
html body .b-all{
    border:1px solid #e0ecf5!important
}
.thumb-sm{
    height:32px;
    width:32px
}
.thumb-md{
    height:48px;
    width:48px
}
.thumb-lg{
    height:88px;
    width:88px
}
.hide{
    display:none
}
.img-circle{
    border-radius:100%
}
.radius{
    border-radius:4px
}
.text-white{
    color:#fff!important
}
.text-danger{
    color:#f33066!important
}
.text-muted{
    color:#8d97ad!important
}
.text-warning{
    color:#ff9b20!important
}
.text-success{
    color:#0fca98!important
}
.text-info{
    color:#fd5332!important
}
.text-inverse{
    color:#3e4555!important
}
html body .text-blue{
    color:#1d96ff
}
html body .text-purple{
    color:#7460ee
}
html body .text-primary{
    color:#fd5332!important
}
html body .text-megna{
    color:#1dc8cd
}
html body .text-dark{
    color:#8d97ad
}
html body .text-themecolor{
    color:#fd5332
}
.alert-primary{
    background-color:#eaf1ff;
    border-color:#eaf1ff;
    color:#05f
}
.alert-warning{
    background-color:#fff5e9;
    border-color:#fff5e9;
    color:#ff9b20
}
.alert-info{
    background-color:#effdff;
    border-color:#effdff;
    color:#08a7c1
}
.alert-danger{
    background-color:#ffe9e9;
    border-color:#ffe9e9;
    color:#f33066
}
.alert-dark{
    background-color:#eff4ff;
    border-color:#eff4ff;
    color:#3e4758
}
.alert-secondary{
    background-color:#d6dfe8;
    border-color:#d6dfe8;
    color:#4b5d6f
}
.bg-primary{
    background-color:#fd5332!important
}
.bg-success{
    background-color:#27b737!important
}
.bg-info{
    background-color:#1ac790!important
}
.bg-warning{
    background-color:#ff9b20!important
}
.bg-danger{
    background-color:#f33066!important
}
.bg-orange{
    background-color:#ec2828!important
}
.bg-yellow{
    background-color:#fed700
}
.bg-facebook{
    background-color:#3b5a9a
}
.bg-twitter{
    background-color:#56adf2
}
html body .bg-megna{
    background-color:#1dc8cd
}
html body .bg-theme{
    background-color:#fd5332
}
html body .bg-inverse{
    background-color:#374158
}
html body .bg-purple{
    background-color:#7460ee
}
html body .bg-orange{
    background-color:#fff6f5!important
}
html body .bg-light{
    background-color:#f5faff!important
}
html body .bg-light-primary{
    background-color:#f1effd
}
html body .bg-light-success{
    background-color:#e8fdeb
}
html body .bg-light-info{
    background-color:#cfecfe
}
html body .bg-light-extra{
    background-color:#ebf3f5
}
html body .bg-light-warning{
    background-color:#fff8ec
}
html body .bg-light-danger{
    background-color:#f9e7eb
}
html body .bg-light-inverse{
    background-color:#f6f6f6
}
html body .bg-light{
    background-color:#f5f7f9
}
html body .bg-white{
    background-color:#fff
}
html body .bg-whites{
    background-color:#f4f5f7
}
html body .bg-red{
    background-color:#e21137
}
html body .bg-green{
    background-color:#4caf50
}
html body .bg-sky{
    background-color:#20b2c1
}
html body .bg-blue{
    background-color:#03a9f4
}
html body .bg-dark-blue{
    background-color:#192c48
}
.round{
    background:#fd5332;
    color:#fff;
    display:inline-block;
    font-weight:400;
    height:50px;
    line-height:48px;
    text-align:center;
    width:50px
}
.round,.round img{
    border-radius:100%
}
.round-lg{
    font-size:30px;
    height:60px;
    line-height:65px;
    width:60px
}
.round.round-info{
    background:#fd5332
}
.round.round-warning{
    background:#ff9b20
}
.round.round-danger{
    background:#f33066
}
.round.round-success{
    background:#27b737
}
.round.round-primary{
    background:#fd5332
}
ul.simple-list{
    margin:0;
    padding:0
}
ul.simple-list li{
    list-style:none;
    padding:10px 5px 10px 28px;
    position:relative
}
ul.simple-list li:before{
    content:"\e6af";
    font-family:themify;
    left:0;
    position:absolute
}
.label{
    border-radius:4px;
    color:#fff;
    font-size:75%;
    font-weight:400;
    padding:3px 15px
}
.label-rounded{
    border-radius:60px
}
.label-custom{
    background-color:#1dc8cd
}
.label-success{
    background-color:#27b737
}
.label-info{
    background-color:#fd5332
}
.label-warning{
    background-color:#ff9b20
}
.label-danger{
    background-color:#f33066
}
.label-megna{
    background-color:#1dc8cd
}
.label-primary{
    background-color:#fd5332
}
.label-purple{
    background-color:#7460ee
}
.label-red{
    background-color:#fb3a3a
}
.label-inverse{
    background-color:#3e4555
}
.label-default{
    background-color:#f4f8fa
}
.label-white{
    background-color:#fff
}
.label-light-success{
    background-color:#e8fdeb;
    color:#27b737
}
.label-light-info{
    background-color:#cfecfe;
    color:#fd5332
}
.label-light-warning{
    background-color:#fff8ec;
    color:#ff9b20
}
.label-light-danger{
    background-color:#f9e7eb;
    color:#f33066
}
.label-light-megna{
    background-color:#e0f2f4;
    color:#1dc8cd
}
.label-light-primary{
    background-color:#f1effd;
    color:#fd5332
}
.label-light-inverse{
    background-color:#f6f6f6;
    color:#3e4555
}
.table thead th{
    border-bottom:1px solid transparent;
    border-top:0!important;
    vertical-align:bottom
}
.table-striped tbody tr:nth-of-type(odd){
    background-color:#f7f9fb
}
.table>tbody>tr>td,.table>tbody>tr>th,.table>tfoot>tr>td,.table>tfoot>tr>th,.table>thead>tr>td,.table>thead>tr>th{
    border-top:1px solid #f7f9fb
}
.table tr td,.table tr th{
    border-color:#eaeff5;
    padding:12px 15px;
    vertical-align:middle
}
.table.tbl-big tr td,.table.tbl-big tr th{
    padding:20px 15px
}
.table.tbl-big.center tr td,.table.tbl-big.center tr th{
    padding:20px 15px;
    text-align:center
}
table.table tr th{
    font-weight:600
}
.table-dark{
    background-color:#212529;
    color:#fff
}
.table-dark td,.table-dark th,.table-dark thead th{
    border-color:#32383e!important
}
.table .thead-dark th{
    background-color:#212529;
    border-color:#32383e;
    color:#fff
}
.badge{
    font-weight:400
}
.badge-xs{
    font-size:9px
}
.badge-sm,.badge-xs{
    transform:translateY(-2px)
}
.badge-success{
    background-color:#27b737
}
.badge-info,.badge-primary{
    background-color:#fd5332
}
.badge-warning{
    background-color:#ff9b20
}
.badge-danger{
    background-color:#f33066
}
.badge-purple{
    background-color:#7460ee
}
.badge-red{
    background-color:#fb3a3a
}
.badge-inverse{
    background-color:#3e4555
}
ul.list-style-none{
    margin:0;
    padding:0
}
ul.list-style-none li{
    list-style:none
}
ul.list-style-none li a{
    color:#8d97ad;
    display:block;
    font-size:15.5px;
    padding:8px 0;
    text-decoration:none
}
ul.list-style-none li a:hover{
    color:#fd5332
}
.dropdown-item{
    color:#607394;
    font-size:15.5px;
    padding:8px 1rem
}
.btn{
    border:2px solid transparent;
    border-radius:.1rem;
    color:#fff;
    cursor:pointer;
    padding:12px 25px;
    transition:0s
}
a.btn-trans-video{
    color:#fd5332;
    font-size:1em;
    padding:.8em 1.6em
}
a.btn-trans-video i{
    background:#fd5332;
    border-radius:50%;
    box-shadow:0 0 0 12px #b3dcff;
    -webkit-box-shadow:0 0 0 12px #b3dcff;
    color:#fff;
    display:inline-block;
    height:36px;
    line-height:36px;
    margin-right:20px;
    text-align:center;
    width:36px
}
.light-vid a.btn-trans-video{
    color:#fff
}
.light-vid a.btn-trans-video i{
    background:#3583ff;
    box-shadow:0 0 0 7px hsla(0,0%,100%,.5);
    -webkit-box-shadow:0 0 0 7px hsla(0,0%,100%,.5);
    color:#fff
}
.btn:hover{
    color:#fff
}
.btn-link,.btn:focus{
    box-shadow:none
}
.btn-link{
    color:#263238;
    padding:10px 15px
}
.btn-link .underline{
    border-bottom:1px solid #263238
}
.btn-link:hover{
    color:#27b737
}
.btn-light{
    color:#263238
}
.btn-light:hover{
    background:#fd5332;
    border-color:#fd5332;
    color:#fff
}
.btn-lg{
    font-size:1em;
    padding:1.2em 2em
}
.btn-md{
    font-size:1em;
    padding:1em 1.5em
}
.btn-sm{
    font-size:12px
}
.btn-sm,.btn-xs{
    padding:.25rem .5rem
}
.btn-xs{
    font-size:10px
}
.btn-circle{
    border-radius:100%;
    height:40px;
    padding:10px;
    width:40px
}
.btn-circle.btn-md{
    font-size:20px;
    height:60px;
    padding:18px 0;
    width:60px
}
.btn-circle.btn-sm{
    font-size:14px;
    height:35px;
    padding:8px 10px;
    width:35px
}
.btn-circle.btn-lg{
    font-size:20px;
    height:70px;
    line-height:23px;
    padding:24px 15px;
    width:70px
}
.btn-circle.btn-xl{
    font-size:24px;
    height:70px;
    padding:14px 15px;
    width:70px
}
.btn-rounded{
    border-radius:50px!important;
    -webkit-border-radius:50px!important
}
.btn-arrow,.btn-arrow span{
    position:relative
}
.btn-arrow span{
    display:inline-block;
    transition:all .3s ease-out;
    will-change:transform
}
.btn-arrow:focus span,.btn-arrow:hover span{
    transform:translate3d(-1rem,0,0)
}
.btn-arrow i{
    opacity:0;
    position:absolute;
    right:0;
    top:50%;
    transform:translateY(-50%);
    transition:all .3s ease-out;
    width:1.1em;
    will-change:right,opacity
}
.btn-arrow:focus i,.btn-arrow:hover i{
    opacity:1;
    right:-2rem
}
.btn-theme{
    background:#fd5332;
    border-color:#fd5332
}
.btn-theme,.btn-theme-2{
    color:#fff;
    transition:all .4s ease
}
.btn-theme-2{
    background:#3151b7;
    border-color:#3151b7
}
.btn-theme-2:focus,.btn-theme-2:hover{
    background:#fd5332;
    border-color:#fd5332;
    color:#fff!important
}
.btn-outline-theme{
    background-color:transparent;
    border:2px solid #fd5332;
    color:#fd5332;
    transition:.2s ease-in
}
.btn-outline-theme:focus,.btn-outline-theme:hover{
    background-color:#fd5332;
    border-color:#fd5332;
    color:#fff!important
}
.btn-outline-theme-2{
    background-color:transparent;
    border:2px solid #3151b7;
    color:#3151b7;
    transition:.2s ease-in
}
.btn-outline-theme-2:focus,.btn-outline-theme-2:hover{
    background-color:#3151b7;
    border-color:#3151b7;
    color:#fff
}
.btn-secondary,.btn-secondary.disabled{
    background-color:#eef0f3;
    color:#8d97ad;
    transition:.2s ease-in
}
.btn-secondary.disabled:hover,.btn-secondary:hover{
    color:#fff!important
}
.btn-secondary.active,.btn-secondary.disabled.active,.btn-secondary.disabled:active,.btn-secondary.disabled:focus,.btn-secondary:active,.btn-secondary:focus{
    background:#263238;
    border-color:#263238;
    color:#fff!important
}
.btn-primary,.btn-primary.disabled{

    background:#2677e6;
    border:1px solid #2677e6;
    transition:.2s ease-in
}

.active_menu{
    background:#345bcb;
    padding:.6rem 2.5rem !important;
    color: #fff !important;
    border-radius:3rem !important;
    border:none !important;
    outline:none !important;
    cursor:pointer !important;
    margin-top:20px;

}
.btn-primary.active,.btn-primary.disabled.active,.btn-primary.disabled:active,.btn-primary.disabled:focus,.btn-primary:active,.btn-primary:focus{
    background:#345bcb;
}
.btn-themecolor,.btn-themecolor.disabled{
    background:#fd5332;
    border:1px solid #fd5332;
    color:#fff
}
.btn-themecolor.disabled:hover,.btn-themecolor:hover{
    background:#028ee1;
    border:1px solid #028ee1
}
.btn-black{
    background:#263547;
    border:1px solid #263547;
    color:#fff
}
.btn-black:focus,.btn-black:hover{
    background:#0f7dff;
    border:1px solid #0f7dff
}
.btn-themecolor.active,.btn-themecolor.disabled.active,.btn-themecolor.disabled:active,.btn-themecolor.disabled:focus,.btn-themecolor:active,.btn-themecolor:focus{
    background:#028ee1
}
.btn-success,.btn-success.disabled{
    background:#27b737;
    border:1px solid #27b737;
    color:#fff
}
.btn-success.disabled:hover,.btn-success:hover{
    background:#1fd628;
    border:1px solid #1fd628
}
.btn-success.active,.btn-success.disabled.active,.btn-success.disabled:active,.btn-success.disabled:focus,.btn-success:active,.btn-success:focus{
    background:#1fd628
}
.btn-info,.btn-info.disabled{
    transition:.2s ease-in
}
.btn-info,.btn-info.disabled,.btn-info.disabled:hover,.btn-info:hover{
    background:#1ac790;
    border:1px solid #1ac790
}
.btn-info.active,.btn-info.disabled.active,.btn-info.disabled:active,.btn-info.disabled:focus,.btn-info:active,.btn-info:focus{
    background:#0976ea
}
.btn-warning,.btn-warning.disabled{
    background:#ff9b20;
    border:1px solid #ff9b20;
    color:#fff
}
.btn-warning.disabled:hover,.btn-warning:hover{
    background:#f1901a;
    border:1px solid #f1901a;
    color:#fff
}
.btn-warning.active,.btn-warning.disabled.active,.btn-warning.disabled:active,.btn-warning.disabled:focus,.btn-warning:active,.btn-warning:focus{
    background:#f1901a;
    color:#fff
}
.btn-danger,.btn-danger.disabled{
    background:#f33066;
    border:1px solid #f33066;
    transition:.2s ease-in
}
.btn-danger.disabled:hover,.btn-danger:hover{
    background:#d73e6b;
    border:1px solid #d73e6b
}
.btn-danger.active,.btn-danger.disabled.active,.btn-danger.disabled:active,.btn-danger.disabled:focus,.btn-danger:active,.btn-danger:focus{
    background:#d73e6b
}
.btn-inverse,.btn-inverse.disabled{
    background:#3e4555;
    border:1px solid #3e4555;
    color:#fff
}
.btn-inverse.disabled:hover,.btn-inverse:hover{
    background:#232a37;
    border:1px solid #232a37;
    color:#fff
}
.btn-inverse.active,.btn-inverse.disabled.active,.btn-inverse.disabled:active,.btn-inverse.disabled:focus,.btn-inverse:active,.btn-inverse:focus{
    background:#232a37;
    color:#fff
}
.btn-red,.btn-red.disabled{
    background:#fb3a3a;
    border:1px solid #fb3a3a;
    color:#fff
}
.btn-red.disabled:hover,.btn-red:hover{
    background:#d61f1f;
    border:1px solid #d61f1f
}
.btn-red.active,.btn-red.disabled.active,.btn-red.disabled:active,.btn-red.disabled:focus,.btn-red:active,.btn-red:focus{
    background:#d73e6b
}
.btn-success-gradiant{
    background:#27b737;
    background:linear-gradient(90deg,#27b737 0,#1fd628);
    border:0
}
.btn-success-gradiant:hover{
    background:#1fd628;
    background:linear-gradient(90deg,#1fd628 0,#27b737)
}
.btn-success-gradiant.active,.btn-success-gradiant:active,.btn-success-gradiant:focus{
    box-shadow:0;
    opacity:1
}
.btn-danger-gradiant{
    background:#f33066;
    background:linear-gradient(90deg,#f33066 0,#ec2828);
    border:0
}
.btn-danger-gradiant:hover{
    background:#ec2828;
    background:linear-gradient(90deg,#ec2828 0,#f33066)
}
.btn-danger-gradiant.active,.btn-danger-gradiant:active,.btn-danger-gradiant:focus{
    box-shadow:0;
    opacity:1
}
.btn-warning-gradiant{
    background:#ff9b20;
    background:linear-gradient(90deg,#ff9b20 0,#f48624);
    border:0
}
.btn-warning-gradiant:hover{
    background:#f48624;
    background:linear-gradient(90deg,#f48624 0,#ff9b20)
}
.btn-warning-gradiant.active,.btn-warning-gradiant:active,.btn-warning-gradiant:focus{
    box-shadow:0;
    opacity:1
}
.btn-info-gradiant,.btn-primary-gradiant{
    border:0;
    color:#fff
}
.btn-info-gradiant,.btn-info-gradiant:hover,.btn-primary-gradiant,.btn-primary-gradiant:hover{
    background:#fd5332;
    background:linear-gradient(90deg,#fd5332 0,#fd5332)
}
.btn-info-gradiant.active,.btn-info-gradiant:active,.btn-info-gradiant:focus{
    box-shadow:0;
    opacity:1
}
.btn-outline-secondary{
    background-color:#fff;
    color:#727b84;
    transition:.2s ease-in
}
.btn-outline-secondary.active,.btn-outline-secondary:active,.btn-outline-secondary:focus{
    background:#263238
}
.btn-outline-primary{
    background-color:#fff;
    border-color:#fd5332;
    color:#fd5332;
    transition:.2s ease-in
}
.btn-outline-primary.focus,.btn-outline-primary:focus,.btn-outline-primary:hover{
    background:#fd5332;
    border-color:#fd5332;
    color:#fff
}
.btn-outline-primary.active,.btn-outline-primary:active,.btn-outline-primary:focus{
    background:#345bcb
}
.btn-outline-success,a.btn-outline-success{
    background-color:transparent;
    border-color:#27b737;
    color:#27b737;
    transition:.2s ease-in
}
.btn-outline-success.focus,.btn-outline-success:focus,.btn-outline-success:hover,a.btn-outline-success.focus,a.btn-outline-success:focus,a.btn-outline-success:hover{
    background:#27b737;
    border-color:#27b737;
    color:#fff
}
.btn-outline-success.active,.btn-outline-success:active,.btn-outline-success:focus,a.btn-outline-success.active,a.btn-outline-success:active,a.btn-outline-success:focus{
    background:#1fd628
}
.btn-outline-info{
    background-color:transparent;
    border-color:#1ac790;
    color:#1ac790;
    transition:.2s ease-in
}
.btn-outline-info.focus,.btn-outline-info:focus,.btn-outline-info:hover{
    background:#1ac790;
    border-color:#1ac790;
    color:#fff
}
.btn-outline-info.active,.btn-outline-info:active,.btn-outline-info:focus{
    background:#1ac790
}
.btn-outline-warning{
    background-color:transparent;
    border-color:#ff9b20;
    color:#ff9b20;
    transition:.2s ease-in
}
.btn-outline-warning.focus,.btn-outline-warning:focus,.btn-outline-warning:hover{
    background:#ff9b20;
    border-color:#ff9b20;
    color:#fff
}
.btn-outline-warning.active,.btn-outline-warning:active,.btn-outline-warning:focus{
    background:#f1901a
}
.btn-outline-danger{
    background-color:transparent;
    border-color:#f33066;
    color:#f33066;
    transition:.2s ease-in
}
.btn-outline-danger.focus,.btn-outline-danger:focus,.btn-outline-danger:hover{
    background:#f33066;
    border-color:#f33066;
    color:#fff
}
.btn-outline-danger.active,.btn-outline-danger:active,.btn-outline-danger:focus{
    background:#d73e6b
}
.btn-outline-red{
    background-color:transparent;
    border-color:#fb3a3a;
    color:#fb3a3a
}
.btn-outline-red.focus,.btn-outline-red:focus,.btn-outline-red:hover{
    background:#fb3a3a;
    border-color:#fb3a3a;
    color:#fff
}
.btn-outline-red.active,.btn-outline-red:active,.btn-outline-red:focus{
    background:#d73e6b
}
.btn-outline-inverse{
    background-color:transparent;
    border-color:#3e4555;
    color:#3e4555
}
.btn-outline-inverse.focus,.btn-outline-inverse:focus,.btn-outline-inverse:hover{
    background:#3e4555;
    border-color:#3e4555;
    color:#fff
}
.btn-outline-light:hover{
    color:#263238!important
}
.btn-primary.active.focus,.btn-primary.active:focus,.btn-primary.active:hover,.btn-primary.focus,.btn-primary.focus:active,.btn-primary:active:focus,.btn-primary:active:hover,.btn-primary:focus,.open>.dropdown-toggle.btn-primary.focus,.open>.dropdown-toggle.btn-primary:focus,.open>.dropdown-toggle.btn-primary:hover{
    background-color:#345bcb;
    border:1px solid #345bcb
}
.btn-success.active.focus,.btn-success.active:focus,.btn-success.active:hover,.btn-success.focus,.btn-success.focus:active,.btn-success:active:focus,.btn-success:active:hover,.btn-success:focus,.open>.dropdown-toggle.btn-success.focus,.open>.dropdown-toggle.btn-success:focus,.open>.dropdown-toggle.btn-success:hover{
    background-color:#1fd628;
    border:1px solid #1fd628
}
.btn-info.active.focus,.btn-info.active:focus,.btn-info.active:hover,.btn-info.focus,.btn-info.focus:active,.btn-info:active:focus,.btn-info:active:hover,.btn-info:focus,.open>.dropdown-toggle.btn-info.focus,.open>.dropdown-toggle.btn-info:focus,.open>.dropdown-toggle.btn-info:hover{
    background-color:#1ac790;
    border:1px solid #1ac790;
    color:#fff
}
.btn-warning.active.focus,.btn-warning.active:focus,.btn-warning.active:hover,.btn-warning.focus,.btn-warning.focus:active,.btn-warning:active:focus,.btn-warning:active:hover,.btn-warning:focus,.open>.dropdown-toggle.btn-warning.focus,.open>.dropdown-toggle.btn-warning:focus,.open>.dropdown-toggle.btn-warning:hover{
    background-color:#f1901a;
    border:1px solid #f1901a;
    color:#fff
}
.btn-danger.active.focus,.btn-danger.active:focus,.btn-danger.active:hover,.btn-danger.focus,.btn-danger.focus:active,.btn-danger:active:focus,.btn-danger:active:hover,.btn-danger:focus,.open>.dropdown-toggle.btn-danger.focus,.open>.dropdown-toggle.btn-danger:focus,.open>.dropdown-toggle.btn-danger:hover{
    background-color:#d73e6b;
    border:1px solid #d73e6b;
    color:#fff
}
.btn-inverse.active,.btn-inverse.focus,.btn-inverse:active,.btn-inverse:focus,.btn-inverse:hover,.open>.dropdown-toggle.btn-inverse{
    background-color:#232a37;
    border:1px solid #232a37;
    color:#fff
}
.btn-red.active,.btn-red.focus,.btn-red:active,.btn-red:focus,.btn-red:hover,.open>.dropdown-toggle.btn-red{
    background-color:#d61f1f;
    border:1px solid #d61f1f;
    color:#fff
}
.btn span.btn-devider{
    display:inline-block;
    padding-left:10px
}
.btn-warning-light{
    background:rgba(255,143,0,.1);
    border-color:transparent;
    color:#ff8f00!important
}
.btn-warning-light:focus,.btn-warning-light:hover{
    background:#ff8f00;
    border-color:#ff8f00;
    color:#fff!important
}
.btn-success-light{
    background:rgba(41,207,141,.1);
    border-color:transparent;
    color:#29cf8d!important
}
.btn-success-light:focus,.btn-success-light:hover{
    background:#29cf8d;
    border-color:#29cf8d;
    color:#fff!important
}
.btn-primary-light{
    background:rgba(51,137,255,.1);
    border-color:transparent;
    color:#fd5332!important
}
.btn-primary-light:focus,.btn-primary-light:hover{
    background:#fd5332;
    border-color:#fd5332;
    color:#fff!important
}
.btn-danger-light{
    background:rgba(237,41,41,.1);
    border-color:transparent;
    color:#ed2929!important
}
.btn-danger-light:focus,.btn-danger-light:hover{
    background:#ed2929;
    border-color:#ed2929;
    color:#fff!important
}
.btn-over-light{
    background:hsla(0,0%,100%,.1);
    border-color:hsla(0,0%,100%,.2);
    color:#fff!important
}
.btn-over-light:focus,.btn-over-light:hover{
    background:#ff8f00;
    border-color:hsla(0,0%,100%,.2);
    color:#fff!important
}
.cover .video-block:not(:first-child):not(:last-child){
    margin:2.77em 0
}
.video-play-icon{
    background:#fff;
    border:2px solid #fff;
    border-radius:50%;
    box-shadow:0 0 0 15px hsla(0,0%,100%,.2);
    -webkit-box-shadow:0 0 0 15px hsla(0,0%,100%,.2);
    cursor:pointer;
    display:inline-block;
    height:6.42857143em;
    line-height:6em;
    position:relative;
    width:6.42857143em;
    z-index:2
}
.video-play-icon a{
    color:#fd5332;
    font-size:3em
}
.pagination{
    border-radius:4px;
    display:table;
    margin:20px auto 0!important;
    padding-left:0
}
.pagination>li>a,.pagination>li>span{
    background-color:#fff;
    border:1px solid #eaeff5;
    border-radius:2px;
    box-shadow:0 2px 10px 0 #d8dde6;
    color:#5a6f7c;
    float:left;
    height:37px;
    line-height:37px;
    margin:5px;
    padding:0;
    position:relative;
    text-align:center;
    text-decoration:none;
    width:37px
}
.pagination>.active>a,.pagination>.active>a:focus,.pagination>.active>a:hover,.pagination>.active>span,.pagination>.active>span:focus,.pagination>.active>span:hover,.pagination>li>a:focus,.pagination>li>a:hover{
    background-color:#fd5332;
    border-color:#fd5332;
    color:#fff;
    cursor:pointer;
    z-index:2
}
.pagination li:first-child a{
    background:#fd5332;
    border:1px solid #fd5332;
    border-radius:2px;
    color:#fff
}
.pagination li:last-child a{
    background:#35434e;
    border:1px solid #35434e;
    border-radius:2px;
    color:#fff
}
.pagination>li{
    display:inline
}
.page-item.active .page-link{
    background-color:#fd5332;
    border-color:#fd5332;
    color:#fff;
    z-index:2
}
.checkbox-custom,.radio-custom{
    opacity:0;
    position:absolute
}
.checkbox-custom,.checkbox-custom-label,.radio-custom,.radio-custom-label{
    align-items:center;
    cursor:pointer;
    display:flex;
    margin:5px
}
.checkbox-custom-label,.radio-custom-label{
    position:relative
}
.checkbox-custom+.checkbox-custom-label:before{
    border:2px solid #dae3ec;
    border-radius:2px
}
.checkbox-custom+.checkbox-custom-label:before,.radio-custom+.radio-custom-label:before{
    background:#fff;
    content:"";
    display:inline-block;
    height:20px;
    margin-right:10px;
    padding:2px;
    text-align:center;
    vertical-align:middle;
    width:20px
}
.radio-custom+.radio-custom-label:before{
    border:2px solid #dae3ec
}
.checkbox-custom:checked+.checkbox-custom-label:before{
    border-radius:2px;
    box-shadow:none;
    display:inline-flex
}
.checkbox-custom:checked+.checkbox-custom-label:before,[type=radio]:checked+label:after,[type=radio]:not(:checked)+label:after{
    align-items:center;
    background:#140c40!important;
    color:#fff;
    content:"\e64c";
    font-family:Themify;
    font-size:8px;
    justify-content:center
}
[type=radio]:checked+label:after,[type=radio]:not(:checked)+label:after{
    display:flex;
    height:20px;
    left:0;
    top:0;
    width:20px
}
.radio-custom+.radio-custom-label:before{
    border-radius:50%
}
.radio-custom:checked+.radio-custom-label:before{
    background:#140c40!important;
    border:1px solid #140c40;
    box-shadow:inset 0 0 0 4px #140c40
}
.company-brands label{
    color:#828f99;
    font-size:15px;
    font-weight:400
}
.checkbox-custom:focus+.checkbox-custom-label,.company-brands input[type=checkbox]:focus,.company-brands label:focus,.radio-custom:focus+.radio-custom-label{
    outline:none
}
.filter_wraps [type=radio]:checked+label:after,.filter_wraps [type=radio]:not(:checked)+label:after{
    right:0
}
.onoffswitch{
    margin:0 auto;
    position:relative;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    width:70px
}
.onoffswitch-checkbox{
    display:none
}
.onoffswitch-label{
    border:2px solid transparent;
    border-radius:20px;
    cursor:pointer;
    display:block;
    overflow:hidden
}
.onoffswitch-inner{
    display:block;
    margin-left:-100%;
    transition:margin .3s ease-in 0s;
    width:200%
}
.onoffswitch-inner:after,.onoffswitch-inner:before{
    box-sizing:border-box;
    color:#fff;
    display:block;
    float:left;
    font-size:14px;
    height:30px;
    line-height:30px;
    padding:0;
    width:50%
}
.onoffswitch-inner:before{
    background-color:#27b737;
    color:#fff;
    content:"";
    padding-right:27px
}
.onoffswitch-inner:after{
    background-color:#3e4555;
    color:#999;
    content:"";
    padding-right:24px;
    text-align:right
}
.onoffswitch-switch{
    background:#fff;
    border-radius:20px;
    bottom:0;
    display:block;
    height:23px;
    margin:6px;
    position:absolute;
    right:35px;
    top:-1px;
    transition:all .3s ease-in 0s;
    width:23px
}
.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner{
    margin-left:0
}
.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch{
    right:0
}
[type=radio]:checked,[type=radio]:not(:checked){
    left:-9999px;
    position:absolute
}
[type=radio]:checked+label,[type=radio]:not(:checked)+label{
    cursor:pointer;
    display:inline-block;
    line-height:20px;
    padding-left:28px;
    position:relative
}
[type=radio]:checked+label:before,[type=radio]:not(:checked)+label:before{
    background:#fff;
    border:1px solid #ddd;
    border-radius:100%;
    height:18px;
    left:0;
    position:absolute;
    top:0;
    width:18px
}
[type=radio]:checked+label:after,[type=radio]:not(:checked)+label:after{
    background:#fd5332;
    border-radius:100%;
    height:10px;
    left:4px;
    position:absolute;
    top:4px;
    transition:all .2s ease;
    width:10px
}
[type=radio]:not(:checked)+label:after{
    opacity:0;
    transform:scale(0)
}
[type=radio]:checked+label:after{
    opacity:1;
    transform:scale(1)
}
.card-columns{
    -moz-column-gap:30px;
    column-gap:30px
}
.card-columns .card{
    margin-bottom:30px
}
.invisible{
    visibility:hidden!important
}
.hidden-xs-up{
    display:none!important
}
@media (max-width:575px){
    .hidden-xs-down{
        display:none!important
    }
}
@media (min-width:576px){
    .hidden-sm-up{
        display:none!important
    }
}
@media (max-width:767px){
    .hidden-sm-down{
        display:none!important
    }
}
@media (min-width:768px){
    .hidden-md-up{
        display:none!important
    }
}
@media (max-width:991px){
    .hidden-md-down{
        display:none!important
    }
}
@media (min-width:992px){
    .hidden-lg-up{
        display:none!important
    }
    .nav-left-side ul.attributes{
        float:none
    }
}
@media (max-width:1199px){
    .hidden-lg-down{
        display:none!important
    }
}
@media (min-width:1200px){
    .hidden-xl-up{
        display:none!important
    }
}
.hidden-xl-down{
    display:none!important
}
.sec-heading{
    margin-bottom:2rem
}
.sec-heading.lg{
    margin-bottom:30px
}
.sec-heading.center{
    text-align:center
}
.sec-heading h2,.sec-heading p{
    margin-bottom:0
}
.sec-heading.light h2,.sec-heading.light p{
    color:#fff
}
.sec-heading p{
    line-height:1.7;
    margin-bottom:5px
}
.single-items{
    padding:0 10px
}
.item-slide,.property-slide{
    margin:0 -15px
}
.sec-heading2{
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    width:100%
}
.sec-left{
    flex:1
}
.sec-left h3{
    font-size:20px;
    margin-bottom:0
}
.sec-right a{
    font-size:14px;
    font-weight:600;
    opacity:.7
}
.form-control{
    background-clip:initial;
    border:1px solid #e0ecf5;
    box-shadow:none;
    font-size:16px;
    height:56px;
    padding:.5rem .75rem
}

.form-submit .form-control,.submit-form .form-control,.submit-page .form-control{
    background:#f9fafc;
    border:1px solid #ebedf5;
    border-radius:3px;
    color:#646e77
}
.form-row>.col,.form-row>[class*=col-]{
    padding-left:15px;
    padding-right:15px
}
.form-control.simple{
    background:#f2f3f5;
    border-color:#f2f3f5;
    border-radius:4px
}
.select2-container{
    width:100%!important
}
.select2-container--default .select2-selection--single{
    background-color:#fff;
    border:none;
    border-radius:0;
    height:57px
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    color:#626a70;
    line-height:57px;
    padding:0 15px 0 0;
    text-align:left
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
    height:50px;
    position:absolute;
    right:10px;
    top:1px;
    width:20px
}
.select2-container--default .select2-selection--single .select2-selection__placeholder{
    color:#6877a5
}
.full-search-2 .form-control::-moz-placeholder{
    color:#6877a5
}
.full-search-2 .form-control::placeholder{
    color:#6877a5
}
.select2-container--default .select2-selection--single .select2-selection__clear{
    right:14px;
    top:1px
}
.bg--dark .form-control,.image-bg .form-control{
    color:#495057!important
}
select.form-control:not([size]):not([multiple]){
    height:56px
}
.form-control.fc-lg,select.form-control.fc-lg:not([size]):not([multiple]){
    height:62px
}
textarea.form-control{
    height:180px
}
.tip-topdata{
    background:#394d8c;
    border-radius:50%;
    color:#fff;
    display:inline-block;
    font-size:9px;
    height:15px;
    line-height:19px;
    margin-left:5px;
    position:relative;
    text-align:center;
    text-decoration:none;
    width:15px
}
.tip-topdata:before{
    background:#394d8c;
    border-radius:3px;
    bottom:9999px;
    color:#fff;
    content:attr(data-tip);
    display:inline-table;
    font-size:11px;
    font-weight:700;
    left:0;
    line-height:25px;
    opacity:0;
    padding:7px 15px;
    position:absolute;
    text-shadow:none;
    top:-42px;
    transition:opacity .4s ease-out;
    white-space:nowrap;
    z-index:999
}
.tip-topdata:hover:before{
    opacity:1
}
.form-submit .select2-container--default .select2-selection--single{
    background-color:#f9fafc;
    border:1px solid #ebedf5;
    border-radius:0;
    border-radius:4px;
    height:56px;
    padding-left:15px
}
.form-submit .select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height:55px
}
.nav-brand,.nav-brand:focus,.nav-brand:hover,.nav-menu>li>a{
    color:#39466d
}
.menu__list,.nav-dropdown,.nav-menu,ul{
    list-style:none
}
.menu__link,.navigation,.navigation *{
    -webkit-tap-highlight-color:transparent
}
.navigation,.navigation *{
    box-sizing:border-box
}
.navigation{
    display:table;
    font-family:inherit;
    height:70px;
    position:relative;
    width:100%
}
.nav-toggle,.navigation-hidden .nav-header{
    display:none
}
.navigation-portrait{
    height:48px
}
.navigation-fixed{
    left:0;
    position:fixed;
    top:0
}
.navigation-hidden{
    height:0!important;
    margin:0!important;
    padding:0!important;
    width:0!important
}
.align-to-right{
    float:right
}
.nav-header{
    float:left
}
.nav-brand{
    font-size:24px;
    margin-right:1rem;
    padding:17px 15px;
    text-decoration:none!important
}
.deatils ul li a,.indicate a,.logo h1 a,.nav-button,.nav-dropdown>li>a,.nav-menu>li>a,nav a{
    text-decoration:none
}
.navigation-portrait .nav-brand{
    font-size:18px;
    line-height:48px
}
.nav-logo>img{
    float:left;
    height:48px;
    margin:11px auto;
    padding:0 15px
}
.nav-logo:focus>img{
    outline:initial
}
.deatils ul li a,.indicate a,.menu__link:focus,.menu__link:hover,nav a,nav a:focus,nav a:hover{
    outline:0
}
.navigation-portrait .nav-logo>img{
    height:36px;
    margin:6px auto 6px 15px;
    padding:0
}
.nav-toggle{
    cursor:pointer;
    height:30px;
    margin-top:-14px;
    padding:6px 2px 0;
    position:absolute;
    right:15px;
    top:50%;
    width:30px
}
.nav-toggle:before{
    background-color:#70798b;
    border-radius:10px;
    box-shadow:0 .5em 0 0 #70798b,0 1em 0 0 #70798b;
    content:"";
    height:2px;
    position:absolute;
    width:24px
}
.navigation-portrait .nav-toggle{
    display:block
}
.navigation-portrait .nav-menus-wrapper{
    -webkit-overflow-scrolling:touch;
    background-color:#fff;
    height:100%;
    left:-400px;
    overflow-y:auto;
    position:fixed;
    top:0;
    transition-duration:.8s;
    transition-timing-function:ease;
    width:320px;
    z-index:20000
}
.navigation-portrait .nav-menus-wrapper.nav-menus-wrapper-right{
    left:auto;
    right:-400px
}
.nav-menus-wrapper.nav-menus-wrapper-open{
    left:0
}
.nav-menus-wrapper.nav-menus-wrapper-right.nav-menus-wrapper-open{
    right:0
}
.nav-menus-wrapper-close-button{
    color:#70798b;
    cursor:pointer;
    display:none;
    float:right;
    font-size:26px;
    height:40px;
    margin:10px 7px;
    width:30px
}
.navigation-portrait .nav-menus-wrapper-close-button{
    display:block;
}
.nav-menu{
    line-height:normal;
    margin:0;
    padding:0
}
.navigation-portrait .nav-menu{
    width:100%
}
.navigation-landscape .nav-menu.nav-menu-centered{
    float:none;
    text-align:center
}
.nav-menu>li{
    display:inline-block;
    float:left;
    text-align:left;
}
.navigation-portrait .nav-menu>li{
    border-top:1px solid #f0f0f0;
    position:relative;
    width:100%
}
.navigation-portrait .nav-menu>li:last-child{
    border-bottom:1px solid #f0f0f0
}
.nav-menu+.nav-menu>li:first-child{
    border-top:none
}
.navigation-landscape .nav-menu.nav-menu-centered>li{
    float:none
}
.nav-menu>li>a{
    display:inline-block;
    font-family:var(--font-heading);
    font-weight:500;
    padding:30px 12px;
    transition:color .3s,background .3s 
}
.navigation-portrait .nav-menu>li>a{
    height:auto;
    padding:12px 15px 12px 26px;
    width:100%
}
.nav-menu>.active>a,.nav-menu>.focus>a,.nav-menu>li:hover>a{
    color:#0fca98!important
}
.nav-menu>li>a>[class*=ion-],.nav-menu>li>a>i{
    height:16px;
    line-height:16px;
    transform:scale(1.4);
    width:18px
}
.nav-menu>li>a>[class*=ion-]{
    display:inline-block;
    transform:scale(1.8);
    width:16px
}
.navigation-portrait .nav-menu.nav-menu-social{
    text-align:center;
    width:100%
}
.nav-menu.nav-menu-social>li{
    border:none!important;
    float:none;
    text-align:center
}
.navigation-portrait .nav-menu.nav-menu-social>li{
    width:auto
}
.nav-menu.nav-menu-social>li>a>[class*=ion-]{
    font-size:12px
}
.nav-menu.nav-menu-social>li>a>.fa{
    font-size:14px
}
.navigation-portrait .nav-menu.nav-menu-social>li>a{
    padding:15px
}
.submenu-indicator{
    float:right;
    margin-left:6px;
    margin-top:6px;
    transition:all .3s
}
.navigation-portrait .submenu-indicator{
    height:44px;
    margin-top:0;
    position:absolute;
    text-align:center;
    width:54px;
    z-index:20000
}
.submenu-indicator-chevron{
    border-color:transparent #39466d #39466d transparent;
    border-style:solid;
    border-width:0 1px 1px 0;
    display:block;
    height:6px;
    transform:rotate(45deg);
    transition:border .3s;
    width:6px
}
.navigation-portrait .submenu-indicator-chevron{
    left:24px;
    position:absolute;
    top:18px
}
.nav-menu>.active>a .submenu-indicator-chevron,.nav-menu>.focus>a .submenu-indicator-chevron,.nav-menu>li:hover>a .submenu-indicator-chevron{
    border-color:transparent #0fca98 #0fca98 transparent
}
.navigation-portrait .submenu-indicator.submenu-indicator-up{
    transform:rotate(-180deg)
}
.nav-overlay-panel{
    display:none;
    height:100%;
    left:0;
    position:fixed;
    top:0;
    width:100%;
    z-index:19999
}
.no-scroll{
    height:100%;
    overflow:hidden;
    width:100%
}
.nav-search{
    float:right;
    height:70px;
    z-index:19998
}
.navigation-portrait .nav-search{
    height:48px;
    margin-right:52px;
    padding:0 10px
}
.navigation-hidden .nav-search{
    display:none
}
.nav-search-button{
    background-color:#fbfcfd;
    cursor:pointer;
    height:70px;
    line-height:70px;
    text-align:center;
    width:70px
}
.nav-search-icon{
    border:2px solid;
    border-radius:50%;
    color:#70798b;
    display:inline-block;
    height:14px;
    margin:2px 8px 8px 4px;
    position:relative;
    text-align:left;
    text-indent:-9999px;
    transform:rotate(-45deg);
    transition:color .3s;
    vertical-align:middle;
    width:14px
}
.nav-search-icon:after,.nav-search-icon:before{
    content:"";
    pointer-events:none
}
.nav-search-icon:before{
    border-radius:0 0 1px 1px;
    box-shadow:inset 0 0 0 32px;
    height:11px;
    left:50%;
    position:absolute;
    top:11px;
    transform:translateX(-50%);
    width:2px
}
.nav-search-button:hover .nav-search-icon{
    color:#00a94f
}
.navigation-portrait .nav-search-button{
    font-size:22px;
    height:48px;
    line-height:46px;
    width:50px
}
.nav-search>form{
    background-color:#fff;
    display:none;
    height:100%;
    left:0;
    padding:0 auto;
    position:absolute;
    top:0;
    width:100%;
    z-index:99
}
.nav-search-inner{
    display:table;
    height:70px;
    margin:auto;
    width:70%
}
.navigation-portrait .nav-search-inner{
    height:48px
}
.nav-search-inner input[type=search],.nav-search-inner input[type=text]{
    background-color:transparent;
    border:none;
    color:#70798b;
    font-size:26px;
    height:70px;
    line-height:70px;
    margin:0;
    outline:0;
    padding:0 12px;
    text-align:center;
    transition:all .3s;
    width:100%
}
.navigation-portrait .nav-search-inner input[type=search],.navigation-portrait .nav-search-inner input[type=text]{
    font-size:18px;
    height:48px;
    line-height:48px
}
.nav-search-close-button{
    color:#70798b;
    cursor:pointer;
    display:block;
    font-size:20px;
    height:28px;
    line-height:normal;
    position:absolute;
    right:20px;
    text-align:center;
    top:20px;
    width:28px
}
.nav-button,.nav-text{
    display:inline-block;
    font-size:14px
}
.navigation-portrait .nav-search-close-button{
    right:14px;
    top:10px
}
.nav-button{
    background-color:#00a94f;
    border-radius:4px;
    color:#fff;
    margin:18px 15px 0;
    padding:8px 14px;
    text-align:center;
    transition:opacity .3s
}
.nav-button:focus,.nav-button:hover{
    color:#fff;
    opacity:.85;
    text-decoration:none
}
.navigation-portrait .nav-button{
    margin:17px 26px;
    width:calc(100% - 52px)
}
.nav-text{
    color:#70798b;
    margin:25px 15px
}
.navigation-portrait .nav-text{
    margin:12px 26px 0;
    width:calc(100% - 52px)
}
.navigation-portrait .nav-text+ul{
    margin-top:15px
}
.nav-dropdown{
    display:none;
    margin:0;
    min-width:250px;
    padding:0;
    position:absolute;
    white-space:nowrap;
    z-index:98
}
.navigation-portrait .nav-dropdown{
    left:0;
    position:static;
    width:100%
}
.nav-dropdown .nav-dropdown{
    left:100%
}
.nav-menu>li .nav-dropdown{
    background:#fff;
    border:none;
    border-radius:.4rem;
    box-shadow:0 0 50px 0 rgba(32,32,32,.15);
    -webkit-box-shadow:0 0 50px 0 rgba(32,32,32,.15);
    padding:15px
}
.nav-menu>li>.nav-dropdown:before{
    background-color:#fff;
    content:"";
    height:20px;
    left:25px;
    position:absolute;
    top:-10px;
    transform:rotate(45deg);
    transition:all .1s ease 0s;
    width:20px
}
.nav-dropdown>li{
    clear:both;
    float:left;
    position:relative;
    text-align:left;
    width:100%
}
.nav-dropdown>li>a{
    border-bottom:1px solid #e4e8ec;
    color:#5b6d90;
    display:inline-block;
    float:left;
    font-family:var(--font-heading);
    padding:14px 20px 14px 10px;
    transition:all .3s ease 0s;
    width:100%
}
.nav-dropdown>li:last-child>a{
    border-bottom:none
}
.nav-dropdown>li>a:focus,.nav-dropdown>li>a:hover{
    color:#0fca98;
    padding-left:20px
}
.social-icon a i,.social-icons a i{
    border-radius:16px;
    display:inline-block;
    height:32px;
    width:32px
}
.nav-dropdown>.focus>a,.nav-dropdown>li:hover>a{
    color:#00a94f
}
.nav-dropdown.nav-dropdown-left{
    right:0
}
.nav-dropdown>li>.nav-dropdown-left{
    left:auto;
    right:100%
}
.navigation-landscape .nav-dropdown-left>li>a{
    text-align:right
}
.navigation-portrait .nav-dropdown>li>a{
    padding:12px 20px 12px 30px
}
.navigation-portrait .nav-dropdown>li>ul>li>a{
    padding-left:50px
}
.navigation-portrait .nav-dropdown>li>ul>li>ul>li>a{
    padding-left:70px
}
.navigation-portrait .nav-dropdown>li>ul>li>ul>li>ul>li>a{
    padding-left:90px
}
.navigation-portrait .nav-dropdown>li>ul>li>ul>li>ul>li>ul>li>a{
    padding-left:110px
}
.nav-dropdown .submenu-indicator{
    position:absolute;
    right:15px;
    top:16px
}
.menu__list,.navbar,nav a{
    position:relative
}
.navigation-portrait .submenu-indicator{
    right:0;
    top:0
}
.nav-dropdown .submenu-indicator .submenu-indicator-chevron{
    transform:rotate(-45deg)
}
.navigation-portrait .nav-dropdown .submenu-indicator .submenu-indicator-chevron{
    transform:rotate(45deg)
}
.nav-dropdown>.focus>a .submenu-indicator-chevron,.nav-dropdown>li:hover>a .submenu-indicator-chevron{
    border-color:transparent #00a94f #00a94f transparent
}
.navigation-landscape .nav-dropdown-left .submenu-indicator{
    left:10px
}
.navigation-landscape .nav-dropdown-left .submenu-indicator .submenu-indicator-chevron{
    transform:rotate(135deg)
}
nav a{
    display:inline-block
}
.header{
    background:#fff;
    position:relative
}
.header-top{
    background:#05222a;
    padding:1em 0
}
.indicate{
    float:left
}
.deatils{
    float:right
}
.indicate a{
    color:#c6c7c7;
    font-size:1.1em;
    vertical-align:middle
}
.deatils ul li i,.indicate i{
    color:#888f90;
    font-size:1.2em;
    vertical-align:middle
}
.indicate i{
    margin-right:.5em
}
.deatils ul li{
    display:inline-block;
    margin:0 5px
}
.deatils ul li i{
    margin-right:.5em
}
.deatils ul li a{
    color:#c6c7c7;
    font-size:1.1em;
    vertical-align:middle
}
.social-icons{
    float:right;
    margin-top:.7em
}
.logo h1 a{
    color:#fff;
    font-size:1.3em
}
.logo span{
    display:block;
    font-size:.32em;
    letter-spacing:4px
}
.header-bottom{
    padding:1em 0
}
.navbar-nav{
    float:left;
    margin:0
}
.navbar-default{
    background:#fff;
    border:none!important
}
.navbar-default .navbar-nav>li>a{
    color:#05222a;
    font-size:1.3em;
    font-weight:900
}
.navbar-default .navbar-nav>.active>a,.navbar-default .navbar-nav>.active>a:focus,.navbar-default .navbar-nav>.active>a:hover,.navbar-default .navbar-nav>li>a:focus,.navbar-default .navbar-nav>li>a:hover{
    background-color:#fff;
    color:#02b875
}
.navbar{
    border:1px solid transparent;
    margin-bottom:0;
    min-height:50px
}
.navbar-default .navbar-nav>.open>a,.navbar-default .navbar-nav>.open>a:focus,.navbar-default .navbar-nav>.open>a:hover{
    background-color:#fff;
    color:#05222a
}
.menu__list{
    flex-wrap:inherit
}
.menu__item{
    display:block;
    margin:1em 0
}
.menu__link{
    -webkit-touch-callout:none;
    cursor:pointer;
    -webkit-user-select:none;
    -moz-user-select:none;
    user-select:none;
    -khtml-user-select:none
}
.menu--francisco .menu__item{
    margin:0 1.5em
}
.menu--francisco .menu__link{
    color:#b5b5b5;
    height:3em;
    overflow:hidden;
    padding:1em 0;
    position:relative;
    text-align:center;
    transition:color .3s
}
.menu--francisco .menu__link:focus,.menu--francisco .menu__link:hover{
    color:#929292
}
.menu--francisco .menu__item--current .menu__link{
    color:#02b875
}
.menu--francisco .menu__link:before{
    background:#02b875;
    content:"";
    height:3px;
    left:0;
    opacity:0;
    position:absolute;
    top:0;
    transform:translate3d(0,-3em,0);
    transition:transform 0s .3s,opacity .2s;
    width:100%
}
.menu--francisco .menu__item--current .menu__link:before,.menu--francisco .menu__link:hover:before{
    opacity:1;
    transform:translateZ(0);
    transition:transform .5s,opacity .1s;
    transition-timing-function:cubic-bezier(.4,0,.2,1)
}
.menu--francisco .menu__helper{
    display:block;
    pointer-events:none
}
.menu--francisco .menu__item--current .menu__helper,.menu__link:hover .menu__helper{
    animation:anim-francisco .3s forwards
}
@keyframes anim-francisco{
    50%{
        opacity:0;
        transform:translate3d(0,100%,0)
    }
    51%{
        opacity:0;
        transform:translate3d(0,-100%,0)
    }
    to{
        opacity:1;
        transform:translateZ(0)
    }
}
.nav-menu.nav-menu-social>li.add-listing{
    background:#08184b;
    border-radius:4px;
    height:50px;
    position:relative;
    top:15px
}
.nav-menu.nav-menu-social>li.add-listing.green{
    background:#0fca98;
    border-radius:4px;
    height:50px;
    position:relative;
    top:15px
}
.nav-menu.nav-menu-social>li.add-listing.purple{
    background:#3d65e4
}
.nav-menu.nav-menu-social>li.add-listing.blue{
    background:#1266e3
}
.nav-menu.nav-menu-social>li.add-listing.light{
    background:#fff
}
.header-fixed .nav-menu.nav-menu-social>li.add-listing.light{
    background:#08184b
}
.nav-menu.nav-menu-social>li.add-listing a{
    color:#fff!important;
    padding:30px 20px;
    top:0
}
.nav-menu.nav-menu-social>li.add-listing.light a{
    color:#08184b!important
}
.header-fixed .nav-menu.nav-menu-social>li.add-listing.light a{
    color:#fff!important
}
.nav-menu.nav-menu-social>li.add-listing.dark-bg{
    background:#2e3654
}
.nav-menu.nav-menu-social>li.add-listing.bg-whit{
    background:#fff!important
}
.header-fixed .nav-menu.nav-menu-social .text-light{
    color:#0fca98!important
}
.nav-menu.nav-menu-social>li.add-listing.bg-whit a{
    color:#333!important
}
.nav-brand img{
    max-width:150px;
    position:relative;
    top:2px
}
.nav-menu.nav-menu-social>li>a>.fas{
    font-size:15px;
    transform:scale(1)
}
.btn-group.account-drop{
    padding:19px 15px;
    position:relative
}
.account-drop .dropdown-menu a{
    font-size:14px;
    padding:.6rem 0
}
.account-drop .dropdown-menu{
    background:#fff;
    border:1px solid #ebedf3;
    box-shadow:none;
    left:auto!important;
    min-width:200px;
    padding:.4rem 1rem;
    right:0!important;
    top:60px!important
}
.account-drop .avater-img{
    border-radius:50%;
    margin-right:10px;
    max-width:30px
}
.account-drop .btn.btn-order-by-filt{
    box-shadow:none;
    font-weight:600;
    padding:6px 0
}
.top-header{
    background:#2b4db9;
    color:#fff;
    padding:12px 0
}
.cn-info ul{
    margin:0;
    padding:0
}
.cn-info ul li{
    display:inline-block;
    list-style:none;
    margin-right:15px
}
.cn-info ul li:last-child{
    margin-right:0
}
.cn-info ul li i{
    margin-right:5px
}
.top-social{
    float:right;
    margin:0;
    padding:0
}
ul:last-child{
    margin:0
}
.top-social li{
    display:inline-block;
    list-style:none;
    margin-right:15px
}
.top-social li:last-child{
    margin-right:0
}
.top-social li a{
    color:#fff
}
.image-bg,.image-cover{
    background-position:50%!important;
    background-size:cover!important
}
.image-bottom{
    background-position:bottom!important;
    background-size:auto!important
}
.hero-banner{
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    min-height:400px;
    padding:5em 0
}
.hero-search-wrap{
    background:#fff;
    border-radius:10px;
    box-shadow:0 20px 30px rgba(0,22,84,.15);
    max-width:540px;
    overflow:hidden;
    padding:30px 40px 40px;
    position:relative
}
.hero-search-wrap.hs-2{
    background:hsla(0,0%,100%,.15);
    border-radius:5px;
    padding:1rem 1rem 0
}
.hero-search-wrap.full-width{
    max-width:100%;
    width:100%
}
.hero-search h1{
    color:#2b4db9!important;
    font-size:35px;
    text-transform:none
}
.btn.search-btn{
    background:#fd5332;
    border-radius:5px;
    box-shadow:0 5px 24px rgba(31,37,59,.15);
    color:#fff;
    font-size:1.2rem;
    padding:17px;
    width:100%
}
.btn.search-btn:focus,.btn.search-btn:hover{
    color:#fff!important
}
.hero-banner.dark-text h1,.hero-banner.dark-text p{
    color:#4d5968
}
.hero-header{
    background:#232323;
    background-position:50%;
    background-size:cover;
    padding:4em 0;
    position:relative
}
.large-banner{
    min-height:660px;
    padding:5em 0 3em
}
.verticle-center{
    text-align:center
}
.hero-header{
    align-items:center;
    -webkit-align-items:center;
    display:-moz-flex;
    display:flex;
    flex-direction:column;
    justify-content:center;
    -webkit-justify-content:center;
    margin:0;
    z-index:2
}
.hero-banner>*{
    position:relative;
    z-index:1
}
.hero-banner h1{
    font-weight:800;
    line-height:1.3
}
.hero-banner p{
    font-size:18px
}
[data-overlay]{
    position:relative
}
[data-overlay]:before{
    background:#19365f;
    content:"";
    height:100%;
    left:0;
    position:absolute;
    top:0;
    width:100%;
    z-index:1
}
[data-overlay="1"]:before{
    opacity:.1
}
[data-overlay="2"]:before{
    opacity:.2
}
[data-overlay="3"]:before{
    opacity:.3
}
[data-overlay="4"]:before{
    opacity:.4
}
[data-overlay="5"]:before{
    opacity:.5
}
[data-overlay="6"]:before{
    opacity:.6
}
[data-overlay="7"]:before{
    opacity:.7
}
[data-overlay="8"]:before{
    opacity:.8
}
[data-overlay="9"]:before{
    opacity:.9
}
.signup-frm{
    display:block;
    margin-top:15px;
    max-width:510px;
    position:relative
}
.signup-frm .form-control{
    background-color:#fff;
    border:1px solid #cfd8dc;
    border-radius:2px;
    font-size:16px;
    font-weight:400;
    height:60px;
    padding:22px 20px 18px;
    width:100%
}
.signup-frm .btn{
    border-radius:2px;
    border-width:0;
    bottom:4px;
    color:#fff;
    cursor:pointer;
    font-size:16px;
    font-weight:500;
    height:52px;
    letter-spacing:1px;
    padding:18px 25px 14px;
    position:absolute;
    right:4px;
    text-align:center;
    text-decoration:none;
    text-transform:uppercase;
    white-space:normal;
    width:auto
}
.full-search-2{
    padding:25px 25px 10px
}
.no-ul-list.third-row{
    display:table
}
.no-ul-list.third-row li{
    width:50%
}
.btn.search-btn-outline:focus,.btn.search-btn-outline:hover{
    color:#fff
}
#module>a,#module>a:focus,#module>a:hover{
    color:#2d3954;
    display:inline-block;
    line-height:1.25;
    padding:14px 10px 14px 45px;
    position:relative;
    width:100%
}
#module a.collapsed:after,#module a:not(.collapsed):after{
    content:"Advanced Search";
    font-size:18px
}
#module a.collapsed:before,#module a:not(.collapsed):before{
    content:"\e61a"
}
#module a.collapsed:before,#module a:not(.collapsed):before{
    background:#000;
    border-radius:50%;
    color:#fff;
    display:inline-block;
    font-family:themify;
    font-size:14px;
    height:30px;
    left:0;
    line-height:30px;
    position:absolute;
    text-align:center;
    top:9px;
    width:30px
}
#module a:not(.collapsed):before{
    content:"\e622"
}
.home-slider .item,.property-slider .item{
    border-radius:0;
    height:600px;
    position:relative;
    transition:all .6s
}
.home-slider .item{
    align-items:center;
    background-position:50%!important;
    background-size:cover!important;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    height:100vh;
    justify-content:center;
    min-height:520px;
    text-align:left
}
.fullwidth-home-slider div{
    height:100%
}
.home-slider-container{
    background:#fff;
    border-radius:.1rem;
    box-shadow:0 0 0 7px hsla(0,0%,100%,.3);
    display:block;
    height:100%;
    max-width:450px;
    padding:2rem;
    position:relative
}
.home-slider .item:before{
    background:linear-gradient(180deg,transparent 10%,#111c52);
    bottom:0;
    content:"";
    display:block;
    left:0;
    position:absolute;
    right:0;
    top:0
}
.home-slider-price{
    border-radius:4px;
    color:#fff;
    display:inline-block;
    font-size:20px;
    font-weight:600;
    margin-bottom:10px
}
.home-slider-container .listing-price-with-compare{
    bottom:auto;
    margin:1rem 0;
    padding:0;
    position:relative
}
.home-slider-container .listing-price-with-compare .lpc-right a{
    background:#f4f7fb;
    color:#687592
}
.home-slider-container .modern-pro-wrap{
    left:auto;
    margin-bottom:15px;
    position:relative;
    top:0
}
.home-slider-container .modern-pro-wrap span{
    border-radius:4px;
    box-shadow:none;
    color:#fff;
    font-size:14px;
    margin-right:10px;
    padding:8px 20px;
    position:relative;
    text-transform:none;
    z-index:1
}
.home-slider-container .modern-pro-wrap .property-type{
    background:#323d50
}
.home-slider-price i{
    font-size:16px;
    font-weight:400;
    opacity:.8
}
.home-slider-title{
    display:inline-block;
    margin-bottom:10px;
    width:100%
}
.home-slider-title a{
    font-size:1.2rem
}
.home-slider-title h3{
    margin-bottom:5px
}
.home-slider-title span i{
    margin-right:5px
}
.simple-search-wrap{
    margin:0;
    max-width:580px
}
.home-slider-desc .read-more{
    background:#fd5332;
    border-radius:4px;
    color:#fff;
    display:inline-block;
    padding:10px 20px
}
.slide-property-info{
    display:block;
    margin:0;
    padding:0;
    width:100%
}
.slide-property-info ul{
    display:flex;
    flex-wrap:wrap;
    margin:0;
    padding:0;
    width:100%
}
.slide-property-info ul li{
    display:inline-block;
    flex:0 0 33.333333%;
    list-style:none;
    width:33.3333%
}
.simple-search-wrap h1{
    color:#fff
}
.simple-search-wrap .pk-input-group{
    background:#fff;
    border-radius:4px;
    display:flex;
    overflow:hidden;
    position:relative
}
.simple-search-wrap .pk-input-group .form-control{
    border:none;
    flex:1 1 auto;
    height:74px;
    margin:0;
    padding-left:3rem;
    position:relative;
    width:1%
}
.simple-search-wrap .pk-input-group .pk-subscribe-submit{
    background:#fd5332;
    border:1px solid #fd5332;
    border-radius:3px;
    color:#fff;
    cursor:pointer;
    margin:10px;
    padding:1rem 2rem;
    text-transform:none
}
.simple-search-wrap .pk-input-group .btn-black{
    background:#1a2340!important;
    border:1px solid #1a2340;
    border-radius:3px;
    color:#fff;
    cursor:pointer;
    margin:5px;
    padding:1rem 1.4rem;
    text-transform:none
}
.simple-search-wrap .pk-input-group .green{
    background:#0fca98;
    border:1px solid #0fca98;
    border-radius:3px;
    color:#fff;
    cursor:pointer;
    margin:10px;
    padding:1rem 2rem;
    text-transform:none
}
.simple-search-wrap .pk-input-group:after{
    align-items:center;
    bottom:0;
    color:#6c757d;
    content:"\e693";
    display:flex;
    font-family:themify;
    font-size:1.25rem;
    justify-content:center;
    left:0;
    position:absolute;
    top:2px;
    width:3rem;
    z-index:3
}
.large-banner{
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    padding:5em 0
}
.full-search-2{
    background:#fff;
    border-radius:6px;
    padding:18px 25px 10px
}
.full-search-2.italian-search .form-group{
    height:100%;
    margin-bottom:0
}
.full-search-2.transparent{
    background:transparent;
    padding:15px 25px
}
.full-search-2 .hero-search h1{
    margin-bottom:1rem;
    text-align:center
}
.btn.reset-btn-outline{
    color:#000;
    padding:14px 25px
}
.btn.reset-btn,.btn.reset-btn-outline{
    border:2px solid #bec2cc;
    transition:all .4s ease;
    width:100%
}
.btn.reset-btn{
    align-items:center;
    background:#000;
    border-radius:4px;
    color:#fff;
    display:flex;
    flex-wrap:wrap;
    height:60px;
    justify-content:center
}
.no-ul-list,.no-ul-list.first-row{
    display:table;
    margin-bottom:15px;
    width:100%
}
.no-ul-list.third-row{
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    margin-bottom:15px;
    width:100%
}
.no-ul-list.second-row li,.no-ul-list.third-row li{
    flex:0 0 50%;
    float:left;
    margin-bottom:7px;
    max-width:50%
}
.btn.reset-btn-outline:focus,.btn.reset-btn-outline:hover{
    background:#000;
    border-color:#000;
    color:#fff
}
.btn.search-btn-outline{
    border:2px solid #fd5332;
    color:#fd5332;
    padding:14px 25px;
    transition:all .4s ease;
    width:100%
}
.btn.search-btn-outline:focus,.btn.search-btn-outline:hover{
    background:#fd5332;
    color:#fff!important
}
h1.italian-header-capt{
    font-family:Lora,serif;
    font-size:50px;
    font-style:italic;
    font-weight:500;
    margin:0 auto 1em;
    text-align:center
}
h1.big-header-capt{
    font-weight:700;
    margin:0 auto .4em;
    text-align:center;
    text-transform:none
}
.italian-search .input-with-icon .form-control,.italian-search .input-with-icon .select2-container,.italian-search .input-with-shadow .form-control,.italian-search .input-with-shadow .select2-container{
    background:#fff;
    border:1px solid #bec2cc;
    border-radius:3px;
    box-shadow:none;
    -webkit-box-shadow:none;
    height:55px;
    padding-left:45px
}
.small-padd{
    padding:0 5px!important
}
.full-search-2.hero-search-radius{
    background:#fff;
    border-radius:.3rem;
    padding:.6rem
}
.full-search-2.hero-search-radius.shadow{
    box-shadow:0 0 0 8px hsla(0,0%,100%,.2);
    -webkit-box-shadow:0 0 0 8px hsla(0,0%,100%,.2)
}
.full-search-2.hero-search-radius.shadow-hard{
    box-shadow:0 10px 12px rgba(77,60,160,.1);
    -webkit-box-shadow:0 10px 12px rgba(77,60,160,.1)
}
.italian-search.hero-search-radius .input-with-icon .form-control,.italian-search.hero-search-radius .input-with-icon .select2-container,.italian-search.hero-search-radius .input-with-shadow .form-control,.italian-search.hero-search-radius .input-with-shadow .select2-container{
    background:#fff;
    border:none;
    border-radius:3px;
    box-shadow:none;
    -webkit-box-shadow:none;
    height:55px;
    padding-left:45px
}
.italian-search.hero-search-radius .btn.search-btn{
    align-items:center;
    border-radius:.2rem;
    display:flex;
    height:55px;
    justify-content:center
}
.italian-search.hero-search-radius .btn.search-btn.black{
    background:#1a2340!important
}
.full-search-2.hero-search-radius{
    position:relative
}
.full-search-2.hero-search-radius #advance-search{
    background:#fff;
    border-radius:.5rem;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    left:0;
    padding:2rem;
    position:absolute;
    right:0;
    top:100px
}
.ad-search,.ad-search:focus,.ad-search:hover{
    align-items:center;
    border-radius:.2rem;
    color:#9db0c3!important;
    display:flex;
    font-size:22px;
    height:60px;
    justify-content:center;
    margin:0 auto;
    text-align:center;
    width:100%
}
.italian-search.hero-search-radius #advance-search .input-with-icon .form-control,.italian-search.hero-search-radius #advance-search .input-with-icon .select2-container,.italian-search.hero-search-radius #advance-search .input-with-shadow .form-control,.italian-search.hero-search-radius #advance-search .input-with-shadow .select2-container{
    border:1px solid #dde0e6
}
.choose-property-type{
    height:100%;
    position:relative;
    width:100%
}
.choose-property-type ul{
    align-items:center;
    display:flex;
    height:100%;
    justify-content:flex-start;
    margin:0;
    padding:0
}
.choose-property-type ul li{
    color:#39466d;
    display:inline-block;
    flex:0 0 33.333333%;
    font-weight:600
}
.choose-property-type [type=radio]:checked+label:before,.choose-property-type [type=radio]:not(:checked)+label:before{
    background:transparent!important;
    border:2px solid #c1cbe0;
    border-radius:50%
}
.choose-property-type [type=radio]:checked+label:after,.choose-property-type [type=radio]:not(:checked)+label:after{
    background:#00ba74!important;
    height:10px;
    left:4px;
    top:4px;
    width:10px
}
.map-search-box{
    background:#fff;
    padding:2em 2em 1em
}
.map-search-box,button.btn.adv-btn{
    border-top-left-radius:4px;
    border-top-right-radius:4px
}
button.btn.adv-btn{
    background:#2b4db9;
    color:#fff
}
.hm-map-container{
    float:left;
    overflow:hidden;
    position:relative;
    width:100%
}
.fw-map{
    height:600px
}
.hm-map-container.column-map{
    overflow:hidden;
    position:fixed;
    -webkit-transform:translateZ(0);
    width:50%
}
.hm-map-container.column-map.right-pos-map{
    right:0
}
.hm-map-container.column-map.left-pos-map{
    left:0
}
.hm-map-container #map{
    height:100%;
    left:0;
    overflow:hidden;
    position:absolute;
    top:0;
    width:100%;
    z-index:10
}
.map-popup-wrap{
    max-width:300px;
    position:relative
}
.infoBox>img{
    cursor:pointer;
    position:absolute;
    right:15px;
    top:38px;
    z-index:2
}
.cluster-visible{
    align-items:center!important;
    background:transparent!important;
    background:radial-gradient(circle,#2540a2 35%,#0c206b 100%)!important;
    border-radius:50%;
    color:#fff!important;
    display:flex!important;
    font-size:16px!important;
    height:50px!important;
    justify-content:center!important;
    width:50px!important
}
.fs-container{
    background:#fff;
    float:left;
    height:100%!important;
    position:relative;
    width:100%;
    z-index:4
}
.fs-left-map-box{
    float:left
}
.fs-container #map{
    height:100%;
    left:0;
    margin-bottom:0;
    position:relative;
    top:0;
    width:100%;
    z-index:990
}
.fs-left-map-box .hm-map-container.fw-map{
    height:100%;
    overflow:hidden;
    position:fixed;
    -webkit-transform:translateZ(0);
    width:40%
}
.half-map .fs-inner-container{
    background:#f9f9f9;
    float:right;
    min-height:100vh;
    position:relative;
    width:60%;
    z-index:10
}
.hm-map-container.fw-map #map{
    height:100%;
    left:0;
    overflow:hidden;
    position:absolute;
    top:0;
    width:100%;
    z-index:10
}
.fs-content{
    max-height:100%;
    overflow:auto;
    overflow:visible;
    padding:0
}
.half-map .fs-content{
    padding:2em
}
.fs-container section.search{
    padding:32px 40px 47px
}
.fs-container .fs-listings,.fs-switcher{
    margin:0;
    padding:0 25px
}
.fs-inner-container{
    display:inline-block;
    float:left;
    height:100%;
    width:50%
}
.img-wrap{
    border-radius:4px;
    display:inline-block;
    height:310px;
    margin:10px 0;
    overflow:hidden;
    position:relative;
    width:100%;
    z-index:1
}
.img-wrap:before{
    background:linear-gradient(180deg,transparent 25%,#1e2a4c);
    content:"";
    display:block;
    height:100%;
    left:0;
    opacity:.8;
    position:absolute;
    top:0;
    transition:.3s ease-out;
    width:100%;
    z-index:11
}
.home-map-banner.full-wrapious{
    height:585px;
    left:0;
    overflow:hidden;
    position:relative;
    top:0;
    width:100%
}
.home-map-banner.full-wrapious .advance-search-container{
    bottom:0;
    left:0;
    margin:0 auto!important;
    max-width:1170px;
    position:absolute;
    right:0;
    transition:all .4s;
    width:100%;
    z-index:33
}
.location-listing{
    border:1px solid #eaebf1;
    border-radius:.4rem;
    margin-top:25px;
    width:100%
}
.location-listing-thumb{
    display:inline-block;
    padding:10px 10px 0;
    width:100%
}
.location-listing-thumb a{
    display:inline-block
}
.location-listing-thumb img{
    border-radius:.4rem
}
.location-listing-caption{
    padding:1rem 0;
    text-align:center
}
.location-listing-caption h4{
    font-size:16px;
    margin:0
}
.location-listing-caption span{
    font-size:13px;
    font-weight:600
}
.hero-header .search-big-form,.hero-header .search-form{
    margin-top:2rem
}
.search-form .form-control{
    padding-left:40px
}
.search-form .btn,.search-form .form-control{
    border-radius:0;
    box-shadow:none;
    height:60px!important
}
.search-form .select2-container--default .select2-selection--single{
    border:none;
    height:60px
}
.search-form .select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height:58px
}
.search-form .select2-container--default .select2-selection--single .select2-selection__arrow{
    height:60px
}
.search-big-form .form-control{
    padding-left:45px
}
.search-big-form .btn,.search-big-form .form-control{
    border-radius:0;
    box-shadow:none;
    height:70px!important;
    padding-bottom:.6rem;
    padding-top:.6rem
}
.search-big-form .select2-container--default .select2-selection--single{
    border:none;
    height:70px
}
.search-big-form .select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height:68px
}
.search-big-form .select2-container--default .select2-selection--single .select2-selection__arrow{
    height:70px
}
.search-form .form-group i{
    color:#bbc6d4;
    left:15px;
    position:absolute;
    top:24px
}
.search-big-form .form-group i{
    color:#bbc6d4;
    font-size:22px;
    left:13px;
    position:absolute;
    top:25px
}
.search-big-form.no-border .btn:focus,.search-big-form.no-border .btn:hover,.search-big-form.no-border .form-control:focus,.search-big-form.no-border .form-control:hover,.search-form.no-border .btn:focus,.search-form.no-border .btn:hover,.search-form.no-border .form-control:focus,.search-form.no-border .form-control:hover{
    border:none;
    box-shadow:none;
    outline:none
}
.search-big-form.no-border .btn,.search-big-form.no-border .form-control,.search-form.no-border .btn,.search-form.no-border .form-control{
    border:none
}
form.search-big-form.search-shadow,form.search-form.search-shadow{
    background:hsla(0,0%,100%,.2);
    border-radius:4px;
    padding:15px
}
.br-all .select2-container--default .select2-selection--single{
    border:1px solid #e0ecf5!important
}
.select2-dropdown{
    z-index:33333
}
.select2-container--default .select2-search--dropdown .select2-search__field,.select2-dropdown{
    border:1px solid #e0ecf5
}
.search-header-banner{
    background:#f6faff;
    padding:2em 0
}
.input-with-icon{
    position:relative;
    width:100%
}
.input-with-icon .form-control,.input-with-icon .select2-container,.input-with-shadow .form-control,.input-with-shadow .select2-container{
    background:#fff;
    border:none;
    border-radius:5px;
    box-shadow:0 0 6px 1px rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 6px 1px rgba(62,28,131,.1);
    height:60px;
    overflow:hidden;
    padding-left:45px
}
.input-with-icon i{
    color:#a2a9bf;
    font-size:18px;
    font-style:normal;
    pointer-events:none
}
.input-with-icon i,.input-with-icon img{
    cursor:normal;
    left:15px;
    position:absolute;
    top:50%;
    transform:translateY(-50%)
}
.hero-search-content.side-form .form-control,.hero-search-content.side-form .select2-container--default .select2-selection--single{
    background:#edf1fb!important;
    box-shadow:none!important
}
.hero-search-content.side-form .select2-container--default .select2-selection--single{
    background:#edf1fb!important;
    border-radius:5px;
    box-shadow:none!important;
    padding-left:15px!important
}
.hero-search-content.side-form label,label{
    color:#455892;
    font-family:var(--font-heading);
    font-size:14px;
    font-weight:600
}
.hero-search-content.side-form .form-control::-moz-placeholder{
    color:#6877a5
}
.hero-search-content.side-form .form-control::placeholder{
    color:#6877a5
}
.location-property-wrap{
    background:#fff;
    border:1px solid #e2e6f1;
    border-radius:8px;
    display:block;
    margin-bottom:30px;
    transition:all .4s ease;
    width:100%
}
.location-property-thumb,.location-property-wrap{
    overflow:hidden;
    position:relative
}
.location-property-thumb img{
    transition:all .4s ease
}
.location-property-content{
    align-items:center;
    display:flex;
    padding:1.5rem 1rem;
    width:100%
}
.lp-content-flex{
    flex:1
}
.lp-content-flex .lp-content-title{
    font-size:17px;
    margin-bottom:0
}
.lp-content-right .lp-property-view{
    align-items:center;
    background:#0fca98;
    border-radius:50%;
    color:#fff;
    display:inline-flex;
    height:40px;
    justify-content:center;
    transition:all .4s ease;
    width:40px
}
.location-property-wrap:focus .location-property-thumb img,.location-property-wrap:hover .location-property-thumb img{
    transform:scale(1.5)
}
.filter-sidebar{
    background-color:#fff;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    height:100vh;
    left:0;
    overflow:hidden;
    padding-top:50px;
    position:fixed;
    top:0;
    transition:.5s;
    width:0;
    z-index:1000
}
.filter-sidebar .show-hide-sidebar{
    display:block;
    height:100%;
    min-height:300px;
    overflow-y:scroll;
    padding:10px 20px;
    position:relative;
    width:calc(100% + 17px)
}
.filter-sidebar .ur-detail-wrap{
    padding:0 1.4em 2em
}
.filter-sidebar .closebtn{
    background:#1ac790;
    border-radius:50%;
    color:#fff;
    font-size:36px;
    font-size:11px;
    height:30px;
    line-height:32px;
    margin-left:50px;
    padding:0;
    position:absolute;
    right:12px;
    text-align:center;
    top:10px;
    width:30px
}
.simple-sidebar{
    background:#fff;
    border:1px solid #e6eaf1;
    border-radius:.4rem;
    margin-bottom:30px;
    padding:25px
}
.simple-sidebar .input-with-icon .form-control,.simple-sidebar .input-with-icon .select2-container,.simple-sidebar .input-with-shadow .form-control,.simple-sidebar .input-with-shadow .select2-container{
    border:1px solid #e7ebf1;
    box-shadow:none
}
.search-sidebar_header{
    background-color:#fff;
    border-bottom:1px solid #dfe4ec;
    display:none;
    height:3rem;
    margin-bottom:1rem;
    padding:0 0 .5rem;
    position:relative;
    width:100%
}
.search-sidebar_header .ssh_heading{
    flex:1;
    font-size:16px;
    margin:0
}
.simple-sidebar button.w3-bar-item.w3-button.w3-large{
    background:#fff;
    border:none!important;
    color:#465169;
    cursor:pointer;
    outline:none!important
}

@keyframes animateleft{
    0%{
        left:-300px;
        opacity:0
    }
    to{
        left:0;
        opacity:1
    }
}
.search-styles-2{
    background:#fff;
    border:1px solid #e6eaf1;
    border-radius:.4rem;
    margin-bottom:30px;
    overflow:hidden
}
.search-inner{
    padding:2rem 0 1rem
}
.single_search_boxed{
    border-top:1px solid #eef0f5;
    display:inline-block;
    padding:0;
    width:100%
}
.search-styles-2 label{
    color:#455892;
    font-size:13px;
    font-weight:500
}
.single_search_boxed:first-child{
    border-top:none
}
.widget-boxed-header{
    display:block;
    padding:0;
    position:relative;
    width:100%
}
.widget-boxed-header h4{
    font-size:14px;
    font-weight:600;
    margin:0;
    text-transform:uppercase
}
.widget-boxed-header h4 a{
    color:#2d3954!important;
    display:block;
    height:100%;
    padding:1rem 0;
    position:relative
}
.widget-boxed-header h4 a:before{
    align-items:center;
    background:#f4f5f7;
    border-radius:50%;
    color:#445977;
    content:"\e622";
    display:flex;
    font-family:themify;
    font-size:10px;
    height:25px;
    justify-content:center;
    position:absolute;
    right:0;
    top:52%;
    transform:translate(-10%,-52%);
    width:25px
}
.widget-boxed-header h4 a.collapsed:before{
    background:#f5f7fb;
    color:#140c40;
    content:"\e61a"
}
.hm_nav{
    align-items:center;
    background-color:#fff;
    border-bottom:1px solid #ecf0f7;
    display:flex;
    justify-content:space-between;
    padding:1.5rem 20px;
    position:relative
}
.hm_nav_title{
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
    font-family:Campton W00 Bold,Helvetica Neue,arial,sans-serif;
    font-size:17px;
    font-style:normal;
    font-weight:400;
    font-weight:800;
    left:0;
    letter-spacing:.4px;
    line-height:1.3;
    margin:0;
    position:absolute;
    right:0;
    text-align:center
}
span.mod-close{
    box-shadow:none!important;
    color:#1a1e2b!important;
    font-size:15px
}
span.hm_nav_clear{
    color:#140c40;
    font-size:13px;
    font-weight:600
}
.filter-search-box .form-group .form-control{
    background:rgba(174,189,219,.12);
    border:1px solid transparent;
    border-radius:4px;
    box-shadow:none!important;
    color:#707e9c;
    margin:0!important
}
.filter-search-box,.form-group.filter_button{
    padding:0
}
.widget-boxed-header .selected{
    color:#72809d;
    display:block;
    font-family:var(--font-body);
    font-size:14px;
    font-weight:400;
    line-height:1.2;
    text-transform:none
}
.filter_wraps .radio-custom+.radio-custom-label:before{
    margin-right:0
}
.filter_wraps [type=radio]:checked+label,.filter_wraps [type=radio]:not(:checked)+label{
    padding-left:0;
    width:100%
}
.filter_wraps [type=radio]:checked+label:before,.filter_wraps [type=radio]:not(:checked)+label:before{
    left:auto;
    right:0
}
.filter_wraps [type=radio]:checked+label:after,.filter_wraps [type=radio]:not(:checked)+label:after{
    left:auto;
    right:3px
}
.filter-list li{
    border-bottom:1px solid rgba(20,12,64,.12);
    padding:1rem 0
}
.filter-list li:last-child{
    border-bottom:none;
    padding-bottom:0
}
.filter_wraps .checkbox-custom+.checkbox-custom-label:before{
    margin-right:0;
    position:absolute;
    right:0
}
.filter_wraps .checkbox-custom:checked+.checkbox-custom-label:before{
    box-shadow:none
}
.filter_wraps .checkbox-custom,.filter_wraps .checkbox-custom-label,.filter_wraps .radio-custom,.radio-custom-label{
    margin:0;
    width:100%
}
.advance-search .form-group{
    margin-bottom:0
}
.advance-search .search-big-form .form-group i{
    color:#fd5332
}
.adv-search-filter{
    display:inline-block;
    float:right;
    margin-bottom:1rem
}
.adv-filter-box{
    display:inline-block;
    margin-left:15px
}
.btn-flit{
    align-items:center;
    border:1px solid #d1dade;
    border-radius:2px;
    color:#646e7b;
    display:flex;
    height:38px;
    justify-content:center;
    width:42px
}
.btn-flit.active{
    color:#fd5332
}
.btn.btn-order-by-filt{
    border:1px solid #d1dade
}
.btn.btn-order-by-filt i{
    margin-left:5px
}
.back-brow{
    display:inline-block;
    margin-bottom:1rem
}
.back-btn{
    font-weight:600;
    opacity:.9
}
.back-btn i{
    margin-right:5px
}
.back-btn:focus,.back-btn:hover{
    opacity:1
}
.slick-slider .agents-grid,.slick-slider .property-listing{
    margin-bottom:10px!important
}
.property-listing{
    border:1px solid #e5e9ec;
    border-radius:6px
}
.property-listing.property-1 .listing-img-wrapper{
    overflow:hidden;
    position:relative
}
.property-listing.property-1 .listing-img-wrapper>a{
    display:block;
    position:relative;
    transition:all .4s ease;
    width:100%
}
.property-listing.property-1 .listing-img-wrapper>a:before{
    background:linear-gradient(180deg,transparent 25%,#1e2a4c);
    bottom:0;
    content:"";
    display:block;
    height:100%;
    left:0;
    position:absolute;
    right:0;
    top:0;
    width:100%
}
.property-listing.property-1 .listing-img-wrapper img{
    max-height:240px;
    width:100%
}
.property-listing.property-1 .listing-detail-wrapper{
    align-items:center;
    display:flex;
    padding:1.5rem 1rem;
    vertical-align:middle;
    width:100%
}
.property-listing.property-1 .listing-short-detail{
    flex:1
}
.avater-30{
    max-width:30px
}
.property-listing.property-1 .listing-name{
    font-size:17px;
    margin-bottom:2px
}
.property-listing.property-1 .listing-location{
    font-size:15px;
    margin-bottom:2px
}
.property-listing.property-1 .listing-location i{
    margin-right:5px
}
.property-listing.property-1 .like-btn{
    border:1px solid #e5e9ec;
    border-radius:50%;
    color:#0ac1a7;
    display:inline-block;
    height:40px;
    line-height:44px;
    text-align:center;
    transition:.4s ease;
    width:40px
}
.property-listing.property-1 .listing-like-top{
    left:20px;
    position:absolute;
    top:20px
}
.property-listing.property-1 .listing-like-top i{
    background:#fff;
    border-radius:50%;
    box-shadow:0 0 0 3px hsla(0,0%,100%,.2);
    color:#fe3e67;
    display:inline-block;
    font-size:18px;
    height:38px;
    line-height:40px;
    margin-right:3px;
    text-align:center;
    width:38px
}
.property-listing.property-1 .listing-features-info ul{
    display:table;
    display:flex;
    flex-wrap:wrap;
    margin:0;
    padding:.5rem 1rem 1.5rem;
    width:100%
}
.property-listing.property-1 .listing-features-info ul li{
    display:inline-block;
    flex:0 0 33.333333%;
    list-style:none;
    width:33.333333%
}
.property-listing.property-1 .listing-features-info ul li:last-child{
    border-right:none
}
.property-listing.property-1 .listing-features-info ul li strong{
    font-weight:600;
    margin-right:5px
}
.property-listing.property-1 .listing-footer-wrapper{
    align-items:center;
    border-top:1px solid #e5e9ec;
    display:flex;
    padding:.9rem 1rem;
    vertical-align:middle;
    width:100%
}
.property-listing.property-1 .listing-footer-wrapper .listing-price{
    color:#0ac1a7;
    flex:1
}
.property-listing.property-1 .listing-price .list-pr{
    color:#2a4fba;
    font-size:18px;
    margin:0
}
.property-listing.property-1 .listing-detail-btn .more-btn{
    background:#0fca98!important;
    border:1px solid #0fca98!important;
    border-radius:2px;
    color:#fff;
    padding:6px 12px
}
.property-listing.property-1 .listing-rating{
    bottom:20px;
    left:20px;
    position:absolute
}
.property-listing.property-1 .listing-rating i{
    color:#fff;
    margin-right:3px
}
.property-listing.property-1 .listing-rating i.filled{
    color:#ff9800
}
.property-listing.property-1 .property-type{
    background:#fff;
    border-radius:50px;
    box-shadow:0 0 0 3px hsla(0,0%,100%,.4);
    -webkit-box-shadow:0 0 0 3px hsla(0,0%,100%,.4);
    color:#21293a;
    font-size:12px;
    padding:4px 15px;
    position:absolute;
    right:20px;
    text-transform:uppercase;
    top:20px
}
.property-listing.property-1 .listing-img-wrapper>a:focus:before,.property-listing.property-1 .listing-img-wrapper>a:hover:before{
    background:linear-gradient(180deg,transparent 5%,#1e2a4c)
}
.property-listing.property-1 .property-listing:focus .like-btn,.property-listing.property-1 .property-listing:hover .like-btn{
    background:#ff9800;
    border-color:#ff9800;
    color:#fff
}
.property_item{
    background:#fff;
    border-radius:6px;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -moz-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    margin-bottom:30px;
    overflow:hidden
}
.image,.property_item{
    position:relative
}
.image a{
    display:block
}
.sb-date{
    background:rgba(23,42,76,.4);
    border-radius:50px;
    color:#fff;
    display:inline-block;
    font-size:14px;
    left:30px;
    padding:8px 15px;
    position:absolute;
    top:30px
}
.sb-date i{
    margin-right:7px
}
span.tag_t{
    background:#22326b;
    border-radius:4px;
    bottom:20px;
    color:#fff;
    display:inline-block;
    padding:7px 15px;
    position:absolute;
    right:20px
}
.proerty_content{
    padding:2em 2em 1.5em;
    position:relative
}
.proerty_text{
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    width:100%
}
.proerty_text .captlize{
    flex:1;
    font-size:16px;
    font-weight:600;
    line-height:1;
    margin-bottom:5px
}
.property_meta{
    display:block;
    margin:2em 0 1rem
}
.classical-list .proerty_price{
    color:#049869;
    font-weight:600;
    margin:0
}
.property_add{
    color:#72809d
}
.classical-list .btn{
    font-size:14px;
    padding:12px 18px
}
.btn.btn-theme{
    background:#fd5332;
    border:1px solid #fd5332;
    border-radius:4px;
    margin-bottom:5px;
    margin-right:4px
}
.btn.btn-theme:focus,.btn.btn-theme:hover{
    background:#fd5332;
    border:1px solid #fd5332;
    color:#fff
}
.btn.btn-theme-light{
    background:rgba(253,83,50,.12);
    border-radius:4px;
    color:#fd5332;
    margin-bottom:5px
}
.btn.btn-theme-light-2,.btn.btn-theme-light-2:focus,.btn.btn-theme-light-2:hover{
    background:rgba(15,202,152,.2)!important;
    border:2px solid rgba(15,202,152,.3)!important;
    color:#0fca98!important;
    padding:16px 50px
}
.btn.btn-theme-light,.btn.btn-theme-light:focus,.btn.btn-theme-light:hover{
    background:rgba(18,102,227,.2)!important;
    border:2px solid rgba(18,102,227,.3)!important;
    color:#1266e3!important;
    padding:16px 50px
}
.property-listing{
    background:#fff;
    border:none;
    border-radius:.5rem;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    margin-bottom:30px;
    overflow:hidden;
    position:relative;
    width:100%
}
.property-listing.shadow-none{
    box-shadow:none;
    -webkit-box-shadow:none
}
.list-img-slide,.property-listing.property-2 .listing-img-wrapper{
    max-height:240px;
    min-height:240px;
    overflow:hidden;
    position:relative
}
.property-listing.property-2 .listing-img-wrapper>a{
    display:block;
    position:relative;
    transition:all .4s ease;
    width:100%
}
.property-listing.property-2 .listing-img-wrapper>a:before{
    background:linear-gradient(180deg,transparent 25%,#1e2a4c);
    bottom:0;
    content:"";
    display:block;
    height:100%;
    left:0;
    position:absolute;
    right:0;
    top:0;
    width:100%
}
.property-listing.property-2 .listing-img-wrapper img{
    max-height:240px;
    min-height:240px;
    width:100%
}
.property-listing.property-2 .listing-detail-wrapper{
    align-items:center;
    display:flex;
    padding:1rem 1.5rem;
    vertical-align:middle;
    width:100%
}
.property-listing.property-2 .listing-short-detail{
    flex:1
}
.property-listing.property-2 .listing-name{
    font-size:16px;
    font-weight:600;
    margin-bottom:0;
    position:relative
}
.property-listing.property-2 .listing-location{
    font-size:14.5px;
    margin-bottom:2px
}
.property-listing.property-2 .list-status{
    background:#0ac186;
    border-radius:50%;
    box-shadow:none;
    color:#fff;
    display:inline-block;
    font-size:9px;
    height:20px;
    line-height:22px;
    margin-left:10px;
    position:relative;
    text-align:center;
    top:-3px;
    width:20px;
    z-index:2
}
.property-listing.property-2 .listing-location i{
    margin-right:5px
}
.property-listing.property-2 .listing-features-info ul{
    display:table;
    margin:0;
    padding:.5rem 1rem 1.5rem;
    width:100%
}
.property-listing.property-2 .listing-features-info ul li{
    display:inline-block;
    list-style:none;
    width:33.333333%
}
.property-listing.property-2 .listing-features-info ul li:last-child{
    border-right:none
}
.property-listing.property-2 .listing-features-info ul li strong{
    font-weight:600;
    margin-right:5px
}
.property-listing.property-2 .listing-footer-wrapper{
    align-items:center;
    border-top:1px solid #e5e9ec;
    display:flex;
    padding:.5rem 1rem;
    vertical-align:middle;
    width:100%
}
.property-listing.property-2 .listing-footer-wrapper .listing-like{
    color:#0ac1a7;
    flex:1
}
.property-listing.property-2 .like-btn{
    background:#f3f4f7;
    border:1px solid #edeff3;
    border-radius:50%;
    display:inline-block;
    height:36px;
    line-height:38px;
    text-align:center;
    transition:all .4s ease;
    width:36px
}
.property-listing.property-2 .listing-contact-info,.property-listing.property-2 .listing-price{
    bottom:20px;
    left:20px;
    position:absolute;
    z-index:1
}
.property-listing.property-2 .listing-contact-info>*,.property-listing.property-2 .listing-price .list-pr,.property-listing.property-2 .listing-price>*{
    color:#fff;
    margin:0
}
.property-listing.property-2 .listing-detail-btn .more-btn{
    background:#ff9800;
    border:1px solid #ff9800;
    border-radius:2px;
    color:#fff;
    padding:6px 15px
}
.rate-btn{
    color:#ff9800;
    font-size:18px;
    font-weight:800
}
.property-listing.property-2 .list-rating i{
    background:#ff9800;
    border:1px solid #ff9800;
    border-radius:50%;
    color:#fff;
    display:inline-block;
    font-size:14px;
    height:30px;
    line-height:26px;
    margin-right:3px;
    text-align:center;
    width:30px
}
.property-listing.property-2 .list-save i{
    background:#fe3e67;
    border:1px solid #fe3e67;
    border-radius:50%;
    color:#fff;
    display:inline-block;
    font-size:18px;
    height:38px;
    line-height:36px;
    margin-right:3px;
    text-align:center;
    width:38px
}
.property-listing.property-2 .list-save-top{
    position:absolute;
    right:20px;
    top:20px
}
.property-listing.property-2 .list-save-top i{
    background:#fff;
    border-radius:50%;
    box-shadow:0 0 0 3px hsla(0,0%,100%,.2);
    color:#fe3e67;
    display:inline-block;
    font-size:18px;
    height:38px;
    line-height:40px;
    margin-right:3px;
    text-align:center;
    width:38px
}
.property-listing.property-2 .listing-category{
    background:hsla(0,0%,100%,.1);
    border-radius:50px;
    bottom:25px;
    box-shadow:0 0 0 5px hsla(0,0%,100%,.2);
    color:#fff;
    font-size:14px;
    padding:5px 15px;
    position:absolute;
    right:20px;
    text-transform:none
}
.list-featured{
    background:#fff;
    border-radius:50px;
    color:#4c5871;
    left:15px;
    padding:7px 20px;
    position:absolute;
    top:15px
}
.list-featured i{
    color:#ff9802;
    margin-right:5px
}
.property-listing.property-2 .listing-post-status i{
    margin-right:7px;
    position:relative;
    top:1px
}
.property-listing.property-2 .listing-img-wrapper>a:focus:before,.property-listing.property-2 .listing-img-wrapper>a:hover:before{
    background:linear-gradient(180deg,transparent 5%,#1e2a4c)
}
.property-listing.property-2:focus .like-btn,.property-listing.property-2:hover .like-btn{
    background:#ff9800;
    border-color:#ff9800;
    color:#fff
}
.price-features-wrapper{
    display:flex;
    flex-wrap:wrap;
    padding:.1rem 1.5rem .6rem
}
.price-features-wrapper .listing-price-fx{
    flex:1
}
.inc-fleat{
    color:#72809d;
    margin-left:15px
}
.list-fx-features{
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    margin-top:5px;
    position:relative;
    width:100%
}
.listing-card-info-icon{
    color:#616e96;
    display:inline-block;
    flex:0 0 33.333333%;
    font-size:14px;
    font-weight:600;
    margin-bottom:0;
    padding-left:0;
    position:relative;
    vertical-align:top;
    width:33.333333%
}
.property-type{
    font-size:14px;
    margin:0
}
.listing-short-detail-wrap{
    align-items:center;
    display:flex;
    position:relative;
    width:100%
}
.inc-fleat-icon{
    align-items:center;
    background:#edf1f9;
    border-radius:50%;
    display:inline-flex;
    height:25px;
    justify-content:center;
    margin-right:7px;
    width:25px
}
.listing-card-info-price{
    color:#08184b;
    font-size:22px;
    font-weight:600;
    line-height:1.4;
    padding-left:.35em;
    position:relative
}
.listing-price-fx .price-suffix{
    color:#72809d;
    font-size:14px;
    font-weight:400
}
.modern-pro-wrap{
    left:30px;
    position:absolute;
    top:30px
}
.property-listing.property-2.modern .property-type{
    background:#323d50;
    bottom:0;
    position:relative;
    right:0
}
.property-listing.property-2.modern .modern-pro-wrap span{
    border-radius:4px;
    box-shadow:none;
    color:#fff;
    font-size:14px;
    margin-right:10px;
    padding:8px 20px;
    position:relative;
    text-transform:none;
    z-index:1
}
.listing-price-with-compare{
    align-items:center;
    bottom:20px;
    display:flex;
    flex-wrap:wrap;
    left:0;
    padding:0 25px;
    position:absolute;
    width:100%;
    z-index:1
}
.listing-price-with-compare h4{
    color:#fff;
    flex:1;
    font-weight:500;
    margin:0
}
.listing-price-with-compare .lpc-right a{
    background:rgba(0,0,0,.3);
    border-radius:.2rem;
    color:#fff;
    display:inline-block;
    height:36px;
    line-height:36px;
    margin-left:10px;
    text-align:center;
    width:40px
}
.property-listing.property-2.modern .list-img-slide{
    border-radius:.5rem;
    max-height:100%;
    min-height:auto;
    overflow:hidden;
    position:relative
}
.property-listing.property-2.modern .listing-img-wrapper img{
    height:100%;
    margin-bottom:-2px;
    max-height:100%;
    min-height:auto;
    -o-object-fit:cover;
    object-fit:cover;
    width:100%
}
.property-listing.property-2.modern .listing-img-wrapper{
    padding:10px 10px 0
}
.property-listing.property-2.modern .property-locations{
    font-size:14px
}
.property-listing.property-2.modern .property-locations i{
    margin-right:4px
}
.property-listing.property-2.modern .price-features-wrapper{
    display:flex;
    flex-wrap:wrap;
    padding:1rem 1.5rem 0
}
.property-listing.property-2.modern .listing-card-info-icon{
    flex:0 0 33.333333%;
    width:33.333333%
}
.property-listing.property-2.modern .home-type.theme-cl{
    font-size:13px;
    margin-bottom:5px
}
.property-listing.property-2.modern .listing-name{
    margin-bottom:5px
}
.modern-property-footer{
    align-items:center;
    border-top:1px solid #f0f1f5;
    display:flex;
    padding:.5rem 1.5rem;
    vertical-align:middle;
    width:100%
}
.property-author{
    flex:1
}
.property-author .path-img{
    display:inline-block;
    float:left;
    margin-right:10px;
    overflow:hidden;
    width:35x
}
.property-author .path-img,.property-author .path-img img{
    border-radius:50%;
    max-width:35px
}
.property-author h5{
    font-size:14px;
    font-weight:500;
    margin:7px 0 0
}
.property-author h5 a{
    color:#808fa0
}
.modern-property-footer .property-pulish-date{
    color:#808fa0;
    font-size:13px
}
.listing-detail-footer{
    align-items:center;
    border-top:1px solid #e4e8f3;
    display:flex;
    margin-top:1rem;
    padding:1rem 1.5rem;
    width:100%
}
.footer-first{
    flex:1
}
.property-reviews{
    align-items:center;
    color:#c6cce0;
    display:flex;
    font-size:10px;
    letter-spacing:4px;
    position:relative
}
.property-reviews i.filled{
    color:#fd9428
}
.foot-location{
    display:flex;
    font-size:15px
}
.foot-location img{
    margin-right:5px
}
.footer-flex .prt-view{
    background:#0fca98;
    border-radius:50px;
    color:#fff!important;
    display:inline-flex;
    height:30px;
    padding:4px 18px
}
.author-box,.footer-flex .prt-view{
    align-items:center;
    justify-content:center
}
.author-box{
    background:#e9eef7;
    display:flex;
    height:75px;
    padding:2px;
    position:relative;
    width:75px
}
.author-box,.author-box img{
    border-radius:50%
}
.author-box:before{
    background:#d9dfef;
    content:"";
    height:80%;
    position:absolute;
    right:-20%;
    width:1px
}
.listing-short-detail-flex.fl-2{
    flex:2
}
.property-listing.property-2 .listing-name .prt-link-detail{
    position:relative
}
.content_block_2 .content-box .btn-box .download-btn.play-store{
    margin-right:30px
}
.content_block_2 .content-box .btn-box .download-btn{
    background:#fff;
    border:1px solid #e81717;
    border-radius:4px;
    box-shadow:0 5px 5px rgba(232,23,23,.2);
    color:#222e64;
    display:inline-block;
    float:left;
    font-size:15px;
    font-weight:600;
    line-height:26px;
    padding:16px 30px 15px 80px;
    position:relative;
    z-index:1
}
.content_block_2 .content-box .btn-box .download-btn i{
    color:#e81717;
    font-size:40px;
    left:30px;
    position:absolute;
    top:16px
}
.content_block_2 .content-box .btn-box .download-btn span{
    color:#828aaf;
    display:block;
    font-family:var(--font-heading);
    font-size:14px;
    font-weight:500;
    line-height:18px;
    position:relative
}
.content_block_2 .content-box .btn-box .download-btn h3{
    color:#242f65;
    display:block;
    font-size:24px;
    font-weight:600;
    line-height:26px;
    margin-bottom:0;
    position:relative;
    transition:all .5s ease
}
.content_block_2 .content-box .btn-box .download-btn.app-store h3{
    color:#2798ff
}
.content_block_2 .content-box .btn-box .download-btn.app-store i{
    color:#242f65
}
.content_block_2 .content-box .btn-box .download-btn.app-store{
    border:1px solid #242f65;
    box-shadow:0 5px 5px rgba(34,46,100,.2)
}
.form-control:focus{
    box-shadow:none
}
.caption-wrap-content h2,.caption-wrap-content p{
    color:#fff
}
.caption-wrap-content a:hover,.caption-wrap-content h2:focus{
    background:#08184b;
    border-color:#08184b;
    color:#fff
}
.shadow-none{
    box-shadow:none!important;
    -webkit-box-shadow:none!important
}
.agents-grid{
    background:#fff;
    border:none;
    border-radius:6px;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    margin-bottom:30px;
    position:relative
}
.fr-grid-thumb{
    border-radius:6px;
    display:table;
    height:200px;
    overflow:hidden;
    padding:.7em;
    position:relative;
    width:100%
}
.fr-grid-thumb a{
    display:inline-flex;
    height:250px;
    overflow:hidden;
    width:100%
}
.fr-grid-thumb img{
    border-radius:6px;
    -o-object-fit:cover;
    object-fit:cover;
    width:100%
}
.fr-grid-deatil{
    align-items:center;
    display:flex;
    padding:0 1.5rem 1rem;
    width:100%
}
.fr-grid-deatil-flex{
    flex:1
}
.fr-grid-deatil-flex .fr-can-name{
    margin-bottom:0
}
.fr-can-rating{
    align-items:center;
    display:flex
}
.agent-property{
    font-size:14px
}
.fr-position{
    font-size:15px
}
.fr-can-rating{
    text-align:center
}
.fr-can-rating i{
    color:#c4c8d0;
    font-size:10px;
    margin-right:3px
}
.fr-can-rating i.filled{
    color:#ff9800
}
.reviews_text{
    font-size:13px;
    font-weight:600;
    margin-left:8px
}
.agent-email a{
    align-items:center;
    background:rgba(226,65,28,.1);
    border-radius:50%;
    color:#e2411c;
    display:inline-flex;
    height:40px;
    justify-content:center;
    transition:all .4s ease;
    width:40px
}
.agent-email a:focus,.agent-email a:hover{
    background:#e2411c;
    color:#fff!important
}
.fr-grid-info{
    margin-bottom:1.5rem
}
.fr-grid-info ul{
    margin:0;
    padding:0
}
.fr-grid-info ul li{
    list-style:none;
    padding:.1rem 1.5rem
}
.fr-grid-footer{
    align-items:center;
    border-top:1px solid #e2e6f1;
    display:flex;
    padding:1rem 1.5rem
}
.fr-grid-footer-flex{
    flex:1
}
.fr-position i{
    margin-right:10px
}
.fr-grid-footer-flex-right .prt-view{
    align-items:center;
    background:#0fca98;
    border-radius:50px;
    color:#fff!important;
    display:inline-flex;
    height:30px;
    justify-content:center;
    padding:4px 18px
}

.fr-grid-footer-flex-right .prt-danger{
    align-items:center;
    background:red;
    border-radius:50px;
    color:#fff!important;
    display:inline-flex;
    height:30px;
    justify-content:center;
    padding:4px 18px
}

.agent-call a,.agent-call a:focus,.agent-call a:hover{
    color:#19b12a;
    font-size:22px
}
span.agent-type{
    display:block;
    font-size:14px
}
ul.fr-grid-social{
    margin:0 0 1.5rem;
    padding:0;
    text-align:center
}
ul.fr-grid-social li{
    display:inline-block;
    list-style:none;
    padding:0 10px
}
ul.fr-grid-social li a{
    font-size:14px
}
.property-listing.property-2 .slick-slide a:before{
    bottom:0;
    content:"";
    display:block;
    left:0;
    opacity:.15;
    position:absolute;
    right:0;
    top:0;
    z-index:1
}
.property-listing.property-2.modern .slick-slide a:before{
    opacity:.1
}
.property-listing .slick-arrow.slick-next{
    transform-origin:100% 0
}
.property-listing .slick-arrow{
    border-radius:50%;
    height:30px;
    opacity:1;
    overflow:hidden;
    transition:.1s;
    width:30px;
    z-index:1
}
.slick-next:before{
    content:"\e628";
    font-family:themify
}
.slick-prev:before{
    content:"\e629";
    font-family:themify
}
.property-listing .slick-next{
    right:5px
}
.property-listing .slick-prev{
    left:5px
}
.property-listing .slick-arrow:before{
    background:hsla(0,0%,100%,.2);
    border-radius:50%;
    box-shadow:0 5px 24px rgba(31,37,59,.15);
    color:#fff;
    cursor:pointer;
    font-size:10px;
    height:30px;
    line-height:30px;
    opacity:0;
    position:absolute;
    top:0;
    transition:.2s;
    width:30px
}
.property-listing .slick-arrow.slick-next:before{
    left:0;
    text-align:center
}
.property-listing .slick-arrow.slick-prev:before{
    right:0;
    text-align:center
}
.property-listing:hover .slick-arrow:before{
    opacity:1
}
.property-search-type{
    display:table;
    margin:0 auto;
    padding-bottom:35px;
    position:relative
}
.property-search-type input[type=radio]{
    display:none
}
.property-search-type label{
    background-color:#fff;
    border-radius:4px;
    color:#333;
    cursor:pointer;
    display:inline-block;
    float:left;
    margin:0 15px 0 0;
    padding:11px 25px;
    text-align:center;
    transition:all .2s
}
.property-search-type label.active,.property-search-type label:hover{
    background-color:#fd5332;
    color:#fff
}
.property-search-type-arrow{
    border-bottom:15px solid #fff;
    border-left:15px solid transparent;
    border-right:15px solid transparent;
    bottom:0;
    height:0;
    left:0;
    position:absolute;
    transform:translate3d(3px,0,0);
    width:0
}
.block-wrap{
    display:block;
    padding:15px 40px 40px;
    width:100%
}
.block-wrap,.block-wraps{
    background:#fff;
    border-radius:8px;
    margin-bottom:30px
}
.block-wraps{
    overflow:hidden
}
.block-wrap:last-child{
    margin-bottom:0
}
.block-wraps .block-header{
    background:#08184b;
    border-bottom:1px solid #08184b
}
.block-header,.block-wraps .block-header{
    display:table;
    margin-bottom:1rem;
    width:100%
}
.block-header{
    border-bottom:1px solid #e8eef7
}
.nav-tabs .nav-link{
    margin-bottom:1px
}
.block-wraps-header .block-body{
    padding:1.5rem
}
.block-header .block-title{
    font-size:16px
}
.block-body{
    display:inline-block;
    line-height:1.7;
    width:100%
}
.avl-features,.dw-proprty-info{
    display:flex;
    flex-wrap:wrap;
    margin:0;
    padding:0
}
.avl-features li{
    display:block;
    flex:0 0 33.333333%;
    list-style:none;
    margin:10px 0;
    max-width:33.333333%;
    padding-left:30px;
    position:relative
}
.avl-features li .icon{
    background:rgba(0,186,116,.1);
    border-radius:50%;
    color:#00ba74;
    font-size:10px;
    height:20px;
    left:0;
    line-height:20px;
    position:absolute;
    text-align:center;
    top:5px;
    width:20px
}
.dw-proprty-info li{
    color:#fd5332;
    display:block;
    flex:0 0 25%;
    list-style:none;
    margin:6px 0;
    max-width:25%;
    position:relative
}
.dw-proprty-info li strong{
    color:#2a3c77;
    display:block
}
.nav-tabs.customize-tab{
    border:none;
    margin-bottom:0
}
.nav-tabs.customize-tab li{
    border-right:1px solid #253465
}
.nav-tabs.customize-tab li:last-child{
    border-right:none
}
.nav-tabs .nav-item{
    margin:0
}
.nav-tabs .nav-link{
    background:#3151b7;
    border:none;
    border-radius:.22rem;
    color:#fff;
    margin-right:10px;
    padding:.7rem 2rem
}
.nav-tabs.customize-tab .nav-link{
    background:transparent!important
}
.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active{
    background-color:#fd5332;
    border-color:#fd5332;
    color:#fff
}
.nav-tabs .nav-link.active,.nav-tabs.customize-tab .nav-item.show .nav-link{
    background:#2f4ba5!important;
    border-radius:0;
    color:#fff;
    margin-right:0
}
.accordion{
    border:1px solid #e2e6ea;
    border-radius:4px
}
.accordion .card{
    border:none
}
.card-header{
    background:#f5f8fb;
    border-bottom:1px solid #e2e6ea
}
.accordion .btn-link{
    color:#5c657f;
    text-decoration:none
}
.accordion .btn-link>span{
    background:#e8ecf1;
    border-radius:2px;
    font-size:12px;
    font-weight:400;
    margin-left:15px;
    padding:6px 15px
}
.sidebar-property-slide .slick-next,.sidebar-property-slide .slick-prev{
    top:-26px
}
.sidebar-property-slide .slick-prev{
    left:auto;
    right:60px
}
.sidebar-property-slide .slick-arrow:before{
    color:#333c56;
    font-size:11px;
    height:25px;
    line-height:25px;
    opacity:1;
    width:25px
}
.sidebar-property-slide .single-items{
    padding:0
}
.accordion .card-title{
    margin-bottom:0
}
.mfp-ready .mfp-figure{
    opacity:1!important
}
.single-advance-property .item-slick.slick-slide.slick-current.slick-active{
    outline:none!important
}
.single-advance-property .slick-slide img{
    display:block;
    max-width:100%
}
.single-advance-property .slider-for img{
    min-height:100%;
    width:100%
}
.single-advance-property .slider-nav .item-slick{
    margin-right:15px
}
.single-advance-property .slider-nav{
    margin:auto
}
.single-advance-property .slider-nav .item-slick{
    cursor:pointer;
    margin-right:0;
    max-width:240px;
    outline:none!important
}
.single-advance-property .slider-nav .item-slick img{
    background-position:50%;
    background-size:cover;
    max-width:100%
}
.single-advance-property .slick-arrow{
    margin-top:-12px;
    position:absolute;
    top:50%;
    z-index:50
}
.single-advance-property .slick-prev{
    left:0
}
.single-advance-property .slick-next{
    right:0
}
.single-advance-caption{
    padding:2em
}
.property-name-info{
    margin-bottom:2rem
}
.property-name-info .property-name{
    margin-bottom:5px
}
.property-price-info{
    margin-bottom:2rem
}
.property-price-info .property-price{
    margin-bottom:5px
}
.property-statement ul{
    display:table;
    margin:0;
    padding:0;
    width:100%
}
.property-statement ul li{
    display:inline-block;
    float:left;
    vertical-align:top;
    width:50%
}
.property-statement ul li>i{
    float:left;
    font-size:35px;
    margin-right:10px;
    margin-top:5px
}
.ps-trep{
    margin-left:50px;
    vertical-align:top
}
.ps-trep span{
    color:#2a3c77;
    display:inline-block;
    margin-bottom:3px
}
.ps-trep .ps-type{
    color:#fd5332
}
.single-advance-property .slick-next{
    right:20px
}
.mfp-zoom-out-cur,.mfp-zoom-out-cur .mfp-image-holder .mfp-close:hover{
    cursor:pointer
}
.mfp-container:hover{
    cursor:default
}
.image-source-link{
    color:#98c3d1
}
.mfp-with-zoom .mfp-container,.mfp-with-zoom.mfp-bg{
    opacity:0;
    transition:all .3s ease-out
}
.mfp-with-zoom.mfp-ready .mfp-container{
    opacity:1
}
.mfp-ready .mfp-figure,.mfp-with-zoom.mfp-removing .mfp-container,.mfp-with-zoom.mfp-removing.mfp-bg{
    opacity:0
}
.mfp-zoom-in .mfp-figure,.mfp-zoom-in .mfp-iframe-holder .mfp-iframe-scaler{
    opacity:0;
    transform:scale(.95);
    transition:all .3s ease-out
}
.mfp-zoom-in.mfp-bg .mfp-zoom-in .mfp-preloader{
    opacity:0;
    transition:all .3s ease-out
}
.mfp-zoom-in.mfp-image-loaded .mfp-figure,.mfp-zoom-in.mfp-ready .mfp-iframe-holder .mfp-iframe-scaler{
    opacity:1;
    transform:scale(1)
}
.mfp-zoom-in.mfp-ready.mfp-bg .mfp-zoom-in.mfp-ready .mfp-preloader{
    opacity:.8
}
.mfp-zoom-in.mfp-removing .mfp-figure,.mfp-zoom-in.mfp-removing .mfp-iframe-holder .mfp-iframe-scaler{
    opacity:0;
    transform:scale(.95)
}
.mfp-zoom-in.mfp-removing.mfp-bg .mfp-zoom-in.mfp-removing .mfp-preloader{
    opacity:0
}
.mfp-iframe-scaler{
    overflow:visible
}
.mfp-zoom-out-cur{
    cursor:auto
}
.mfp-zoom-out-cur .mfp-image-holder .mfp-close{
    cursor:pointer
}
.page-title{
    align-items:center;
    background:#2540a2;
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    height:200px;
    justify-content:center;
    text-align:left;
    width:100%
}
.page-title .ipt-title{
    color:#fff
}
.ipn-subtitle{
    color:#7590f3
}
.overlio-40{
    margin-top:-40px
}
.ipt-title{
    margin-bottom:5px
}
.ipn-subtitle{
    font-family:initial;
    font-size:20px;
    font-style:italic
}
.page-title.bb-title{
    background-position:50%!important;
    background-size:cover!important
}
.page-title.bb-title .ipt-title{
    color:#fff
}
.page-title.bb-title .ipn-subtitle{
    color:#fff;
    opacity:.8
}
.author-review ul{
    margin:0;
    padding:0
}
li.article_comments_wrap{
    border-bottom:1px dashed #e3e7ea;
    margin-bottom:20px
}
li.article_comments_wrap:last-child{
    border:none;
    margin:0
}
.author-review article{
    overflow:hidden;
    padding-bottom:25px
}
.author-review article .article_comments_thumb{
    float:left;
    width:80px
}
.author-review article .article_comments_thumb img{
    border-radius:50%;
    max-width:80px
}
.author-review article .comment-details{
    overflow:hidden;
    padding-left:30px
}
.author-review article .comment-details .comment-meta{
    display:flex;
    flex-wrap:wrap
}
.author-review article .comment-details .comment-meta .comment-left-meta{
    flex:1 1 70%
}
.author-review article .comment-details .comment-meta .comment-left-meta .author-name{
    font-size:17px;
    line-height:26px;
    margin-bottom:0
}
.author-review article .comment-details .comment-meta .comment-left-meta .comment-date{
    color:#00ba74;
    font-size:12px;
    font-weight:500;
    letter-spacing:0;
    line-height:16px;
    margin:7px 0 0;
    text-transform:uppercase
}
.author-review article .comment-details .comment-text{
    margin:18px 0 0
}
.author-review article .comment-details .comment-text p{
    margin:0
}
.author-review article .comment-details .comment-text{
    margin:19px 0 0
}
.reviews-checked{
    display:table;
    font-size:14px;
    font-weight:600;
    margin:0 auto;
    text-align:center
}
.neary_section{
    border-bottom:1px solid #e8eaf1;
    padding:10px 0
}
.neary_section_first .nearby_place_title{
    color:#172e6f
}
.nearby_place_rate{
    align-items:center;
    border-radius:4px;
    color:#fff;
    display:flex;
    flex-wrap:wrap;
    font-size:14px;
    font-weight:600;
    height:auto;
    justify-content:center;
    line-height:25px;
    margin-right:5px;
    padding:5px;
    text-align:center
}
.nearby_place_rate i{
    font-size:8px;
    margin-right:4px;
    position:relative
}
.nearby_place_rate.mid{
    background:#ff9800
}
.nearby_place_rate.good{
    background:#03a07c
}
.nearby_place_rate.high{
    background:#8bc34a
}
.nearby_place_rate.poor{
    background:#f44336
}
.sides_list_property{
    background:#fff;
    border:1px solid #e7eaec;
    border-radius:.4rem;
    display:flex;
    margin-bottom:15px;
    padding:1rem 1rem .8rem;
    width:100%
}
.sides_list_property_thumb{
    border-radius:4px;
    height:75px;
    overflow:hidden;
    width:125px
}
.sides_list_property_thumb img{
    height:100%;
    -o-object-fit:cover;
    object-fit:cover;
    width:100%
}
.sides_list_property_detail{
    padding-left:.7rem;
    width:100%
}
.sides_list_property_detail .lists_property_status{
    font-size:13px
}
.sides_list_property_detail h4{
    font-size:16px;
    line-height:1;
    margin:0 0 2px
}
.sides_list_property_detail span{
    font-size:13px
}
.sides_list_property_detail span i{
    margin-right:4px
}
.lists_property_price .lists_property_types{
    flex:1
}
.lists_property_price{
    align-items:center;
    display:flex;
    padding:8px 0
}
.property_types_vlix{
    background:rgba(249,93,2,.1);
    border-radius:50px;
    color:#f95d02;
    display:inline-block;
    font-size:13px;
    padding:4px 15px
}
.property_types_vlix.sale{
    background:rgba(3,169,138,.1);
    color:#03a98a
}
.property_types_vlix.buy{
    background:rgba(168,113,228,.1);
    color:#a871e4
}
.compare-slide-menu{
    background-color:#fff;
    box-shadow:0 0 25px rgba(0,0,0,.1);
    height:100vh;
    padding:0;
    position:fixed;
    right:-320px;
    top:0;
    transition:.4s ease-in-out;
    width:320px;
    z-index:999999
}
.csm-content{
    height:100%;
    overflow-y:scroll;
    width:340px
}
.compare-slide-menu.active{
    right:0
}
.csm-properties{
    padding:30px 20px 0
}
.csm-content>h4{
    background-color:#274abb;
    color:#fff;
    display:block;
    font-size:18px;
    margin:0;
    padding:30px;
    position:relative;
    width:100%
}
.csm-trigger{
    background-color:#3151b7;
    border-radius:3px 0 0 3px;
    color:#fff;
    cursor:pointer;
    font-size:24px;
    height:50px;
    left:-50px;
    line-height:50px;
    position:absolute;
    text-align:center;
    top:50%;
    transform:translateY(-50%);
    width:50px
}
.csm-trigger:before{
    content:"\e6ac";
    display:inline-block;
    font-family:themify;
    transition:.3s ease-in-out
}
.csm-buttons{
    padding:0 30px 30px
}
.compare-slide-menu.active .csm-trigger:before{
    transform:rotate(180deg)
}
.csm-buttons a.button{
    border-radius:3px;
    display:inline-block;
    font-size:15px;
    padding:10px 20px
}
.csm-buttons a.button.btn-compare,.csm-buttons a.button.reset:hover{
    background-color:#3151b7;
    color:#fff
}
.csm-buttons a.button.btn-reset,.csm-buttons a.button.reset:hover{
    background-color:#ffe6e6;
    color:#ea1e1e
}
.property-listing:focus .remove-compare,.property-listing:hover .remove-compare{
    opacity:1
}
.compare-slide-menu .property-listing{
    margin-bottom:25px
}
.remove-compare{
    left:15px;
    opacity:0;
    position:absolute;
    top:15px;
    transition:all .4s ease;
    z-index:4
}
.remove-compare>a{
    align-items:center;
    background:#f32945;
    border-radius:3px;
    color:#fff;
    display:inline-block;
    display:flex;
    flex-wrap:wrap;
    font-size:10px;
    height:20px;
    justify-content:center;
    width:20px
}
.comp-property{
    display:block;
    min-height:260px;
    padding:0 10px;
    position:relative
}
.comp-property-blank{
    display:block;
    height:259px
}
.compare-slide-menu .property-listing.property-2 .listing-name{
    font-size:13px
}
.compare-slide-menu .listing-card-info-price{
    font-size:18px
}
span.remove-from-compare{
    background:#e22828;
    border-radius:4px;
    color:#fff;
    height:25px;
    left:20px;
    line-height:25px;
    opacity:0;
    position:absolute;
    top:20px;
    transition:all .4s ease;
    width:25px
}
.clp-img{
    border-radius:6px;
    overflow:hidden;
    position:relative;
    width:100%
}
.clp-title{
    padding:12px 0
}
.clp-title h4{
    font-size:17px;
    margin:0 0 2px
}
.comp-property:focus .remove-from-compare,.comp-property:hover .remove-from-compare{
    opacity:1
}
.show-mb{
    display:none!important
}
.pricing-5{
    overflow:hidden
}
.pricing-5 div[class*=col-]{
    padding:0;
    transition:.2s linear;
    -webkit-transition:.2s linear;
    -moz-transition:.2s linear
}
.pricing-5 .pricing__title{
    min-height:144px;
    padding:1.625em 0;
    position:relative
}
.pricing-5 .pricing__title h5,.pricing-5 .pricing__title h6{
    margin:0
}
.pricing-5 .pricing__title h6{
    opacity:.5
}
.pricing-5 .pricing__price{
    margin-top:.8125em
}
.pricing-5 ul{
    margin:0;
    padding:0
}
.pricing-5 ul li{
    line-height:1.625em;
    list-style:none;
    padding:1.625em 0
}
.pricing-5 ul li:nth-child(odd){
    background:#f4f4f4
}
.pricing-5 ul li span:not(.btn__text){
    font-style:italic
}
.checkmark,.crossmark{
    background:#64b671;
    border-radius:50%;
    display:inline-block;
    height:1.625em;
    line-height:1.625em;
    position:relative;
    text-align:center;
    width:1.625em
}
.crossmark{
    background:#e34747
}
.checkmark:before,.crossmark:before{
    color:#fff;
    content:"✓";
    font-size:13px;
    left:0;
    opacity:1;
    position:absolute;
    width:100%
}
.crossmark:before{
    content:"✕"
}
.pricing-5 ul li span:not(:first-child){
    bottom:6px;
    display:inline-block;
    margin-left:.8125em;
    position:relative
}
@media (min-width:990px){
    .pricing-5 ul li{
        height:4.875em
    }
}
.gallery_bottom_block{
    border-bottom:1px solid #e9ecf3;
    padding:1em 0
}
.property_info_detail_wrap{
    align-items:center;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    position:relative;
    width:100%
}
.property_info_detail_wrap_first{
    flex:1
}
.prt-sect-pric h3{
    font-size:30px;
    margin:0
}
.prt-sect-pric h3 sub{
    font-size:14px;
    font-weight:400;
    opacity:.8
}
.pr-price-into h2>i{
    font-size:16px;
    font-style:normal;
    font-weight:400;
    opacity:.9
}
.pr-price-into h2{
    margin-bottom:0
}
.pr-single-info{
    display:inline-block;
    margin-left:.5rem;
    position:relative
}
.pr-single-info:first-child{
    margin:0
}
span.view-pr{
    margin-left:6px;
    position:relative;
    top:-3px
}
.pr-single-info>a{
    border-radius:2px;
    color:#fff!important;
    font-size:18px
}
.btn-share,.btn-share:focus,.btn-share:hover,.pr-single-info>a{
    align-items:center;
    background:#1c4c75;
    display:flex;
    flex-wrap:wrap;
    height:42px;
    justify-content:center;
    width:42px
}
.btn-share,.btn-share:focus,.btn-share:hover{
    border:none;
    border-radius:2px;
    color:#fff;
    cursor:pointer;
    outline:none;
    padding:0
}
.share-opt-wrap .dropdown-menu{
    border:1px solid #e8e9f1;
    bottom:55px;
    left:0!important;
    padding:7px 0 9px;
    text-align:center;
    top:auto!important
}
.share-opt-wrap .dropdown-menu a{
    border-bottom:none;
    display:inline-block;
    line-height:1;
    padding:2px 6px 0;
    position:relative;
    top:3px
}
.dropdown-menu.show a i{
    margin-right:7px
}
.cl-facebook i{
    color:#395693!important
}
.cl-twitter i{
    color:#1c9cea!important
}
.cl-gplus i{
    color:#d23d35!important
}
.cl-instagram i{
    color:#c82f99!important
}
.property_info_detail_wrap.light{
    bottom:2rem;
    color:#fff
}
.prties_list{
    display:block;
    position:relative;
    width:100%
}
.prties_list ul{
    display:block;
    margin:5px 0 0;
    padding:0;
    width:100%
}
.prties_list ul li{
    display:inline-block;
    font-size:14px;
    font-weight:600;
    margin-right:1rem;
    position:relative
}
.prties_list ul li:before{
    border-radius:50%;
    content:".";
    font-size:25px;
    left:-9px;
    line-height:0;
    position:absolute;
    top:2px
}
.prties_list ul li:first-child:before{
    display:none
}
.prt-type{
    background:#333;
    border-radius:2px;
    color:#fff;
    font-size:13px;
    font-weight:500;
    margin-left:10px;
    padding:4px 12px
}
span.prt-type.rent{
    background:#3ebd43;
    color:#fff
}
span.prt-type.sale{
    background:#ff8000;
    color:#fff
}
.like_share_wrap{
    background-color:#fff;
    border:1px solid #e6eaf3;
    border-radius:4px;
    color:#707070;
    margin-bottom:20px;
    padding:20px;
    transition:.25s
}
ul.like_share_list{
    align-items:center;
    display:flex;
    margin:0;
    padding:0;
    width:100%
}
ul.like_share_list li{
    flex:0 0 50%;
    list-style:none;
    padding:0 5px;
    width:50%
}
ul.like_share_list li a{
    background:rgba(3,169,138,.1);
    border:1px solid #03a98a;
    border-radius:.4rem;
    color:#03a98a!important;
    font-size:14px;
    font-weight:600;
    width:100%
}
ul.like_share_list li:last-child a{
    background:rgba(249,93,2,.1);
    border-color:#f95d02;
    color:#f95d02!important
}
ul.like_share_list li a i{
    margin-right:7px
}
.btn.cop_btn{
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    height:56px;
    justify-content:center
}
.btn.cop_btn,.downld_rport{
    margin-bottom:20px;
    width:100%
}
.downld_rport{
    background:#fff;
    border:1px solid #e6eaf3;
    border-radius:4px
}
.downld_rport ul{
    display:flex;
    flex-wrap:wrap;
    margin:0;
    padding:0;
    width:100%
}
.downld_rport ul li{
    color:#172e6f;
    flex:0 0 50%;
    font-size:15px;
    font-weight:600;
    padding:1rem 1.5rem;
    width:50%
}
.downld_rport ul li:first-child{
    border-right:1px solid #e6eaf3
}
.downld_rport ul li i{
    color:#03a98a;
    display:inline-table;
    float:left;
    font-size:40px;
    margin-right:10px
}
.downld_rport ul li span{
    color:#546cb1;
    display:block;
    font-size:13px
}
.downld_rport ul li.pdf i{
    color:#e62a1c
}
.property_block_wrap{
    background:#fff;
    border:1px solid #e6eaf3;
    border-radius:4px;
    display:block;
    margin-bottom:30px;
    padding:0;
    width:100%
}
.property_block_wrap.style-2{
    border:none;
    border-radius:6px
}
.property_block_wrap.style-2 .block-body{
    padding:0 1.5rem 1rem
}
.property_block_wrap.style-2 .property_block_wrap_header h4{
    color:#140c40;
    font-family:var(--font-body);
    font-size:16px;
    font-weight:700;
    margin:0
}
.property_block_wrap.style-2 .property_block_wrap_header h4:after{
    align-items:center;
    background:#f5f7fb;
    border-radius:50%;
    content:"\f106";
    display:flex;
    float:right;
    font-family:Font Awesome\ 5 Free;
    font-weight:600;
    height:26px;
    justify-content:center;
    margin-right:0;
    position:relative;
    transition:.2s ease-out;
    width:26px
}
.property_block_wrap.style-2 .property_block_wrap_header .collapsed h4:after{
    transform:rotate(180deg)
}
.property_block_wrap.style-2 .property_block_wrap_header h4:before{
    display:none
}
.property_block_wrap.style-2 .property_block_wrap_header{
    border:none;
    padding:1rem 1.5rem
}
.property_block_wrap.style-2 .property_block_wrap_header.no-collapse h4:after{
    display:none
}
ul.detail_features{
    display:flex;
    flex-wrap:wrap;
    margin:0;
    padding:0;
    width:100%
}
ul.detail_features li{
    color:#546cb1;
    display:inline-block;
    flex:0 0 33.333333%;
    font-size:13px;
    line-height:1.5;
    margin-bottom:1.2rem;
    width:33.333333%
}
ul.detail_features li strong{
    color:#08184b;
    display:inline-block;
    font-size:14px;
    margin-right:10px
}
.nearby-wrap{
    margin-bottom:2rem
}
.nearby-wrap:last-child{
    margin-bottom:0
}
.neary_section_list{
    display:block;
    position:relative;
    width:100%
}
.neary_section{
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    padding:6px 0;
    width:100%
}
.neary_section:last-child{
    border-bottom:none
}
.neary_section_first{
    flex:1
}
.neary_section_first .nearby_place_title{
    color:#140c40;
    font-family:var(--font-body);
    font-size:14px;
    margin:0
}
.neary_section_last{
    align-items:center;
    display:flex
}
.neary_section_first .nearby_place_title>small{
    margin-left:10px
}
.neary_section_last i{
    color:#ced1e4;
    font-size:10px
}
.neary_section_last i.filled{
    color:#ff9800
}
.reviews-count{
    margin-left:5px
}
.nearby_header{
    align-items:center;
    display:flex;
    margin-bottom:1rem;
    position:relative;
    width:100%
}
.nearby_header_first{
    flex:1
}
.nearby_header_first h5{
    font-size:16px;
    margin:0
}
.nearby_header_last .nearby_powerd{
    align-items:center;
    display:flex
}
.nearby_header_last .nearby_powerd img{
    margin-left:10px;
    max-width:50px
}
.list-fx-features{
    justify-content:end;
    margin-top:20px
}
.listing-card-info-icon{
    align-items:center;
    display:inline-flex;
    flex:inherit;
    margin-right:1.2rem;
    width:auto
}
.listing-card-info-icon:last-child{
    margin:0
}
.prt-price-fix{
    color:#0fca98;
    line-height:1.4
}
.prt-price-fix sub{
    color:#929fc5;
    font-size:15px;
    font-weight:400
}
.featured_slick_gallery{
    position:relative
}
.btn-view-pic{
    background:#fff;
    border-radius:5px;
    bottom:30px;
    color:#08184b;
    font-weight:600;
    padding:15px 26px;
    position:absolute;
    right:50px
}
.btn-view-pic.top{
    bottom:auto;
    position:absolute;
    right:50px;
    top:30px
}
.property_block_wrap.style-3{
    align-items:center;
    border:none;
    display:flex;
    margin-top:-60px;
    padding:1.5rem
}
.pbw-flex-thumb{
    align-items:center;
    background:#f0f5fd;
    border-radius:6px;
    display:flex;
    justify-content:center;
    padding:2.5rem
}
.pbw-flex{
    padding-left:2rem
}
.property_block_wrap.style-4{
    background:transparent;
    border:none;
    color:#fff;
    text-align:center
}
.property_block_wrap.style-4 .prt-types.sale{
    background:#3b58c3;
    color:#fff;
    margin-bottom:5px
}

.property_block_wrap.style-4 .list-fx-features{
    justify-content:center;
    margin-top:20px
}
.property_block_wrap.style-4 .listing-card-info-icon{
    color:#6a84e2
}
.pbwts-social{
    align-items:center;
    display:flex;
    justify-content:center;
    width:100%
}
.pbwts-social ul{
    margin:0;
    padding:0
}
.pbwts-social ul li{
    display:inline-flex;
    font-weight:600;
    margin-right:10px
}
.pbwts-social ul li a{
    align-items:center;
    background:#3151b7;
    border:1px solid #385ac5;
    border-radius:50%;
    color:#fff;
    display:inline-flex;
    font-size:12px;
    height:30px;
    justify-content:center;
    transition:all .4s ease;
    width:30px
}
.pbwts-social ul li a:focus,.pbwts-social ul li a:hover{
    background:#fff;
    border:1px solid #fff;
    color:#2540a2
}
.property_block_wrap.style-4 .prt-price-fix sub{
    color:#6e88e8
}
.property_video{
    position:relative
}
.property_video .thumb{
    border-radius:6px;
    overflow:hidden
}
.bb-video-box{
    left:50%;
    position:absolute;
    top:50%;
    transform:translate(-50%,-50%)
}
.bb-video-box-inner{
    background:hsla(0,0%,100%,.5);
    height:100px;
    margin:0 auto;
    width:100px
}
.bb-video-box-inner,.bb-video-box-innerup{
    align-items:center;
    border-radius:50%;
    display:flex;
    justify-content:center
}
.bb-video-box-innerup{
    background:hsla(0,0%,100%,.9);
    font-size:25px;
    height:65px;
    width:65px
}
.bb-video-box-innerup a{
    line-height:0
}
ul.list-gallery-inline{
    display:flex;
    flex-wrap:wrap;
    margin:0;
    padding:0;
    width:100%
}
ul.list-gallery-inline li{
    flex:0 0 33.33333%;
    padding:5px;
    width:33.333333%
}
ul.list-gallery-inline li a{
    border-radius:4px;
    display:block;
    height:100%;
    overflow:hidden;
    width:100%
}
.property_video:before{
    background:#17203a;
    border-radius:6px;
    bottom:0;
    content:"";
    left:0;
    opacity:.5;
    position:absolute;
    right:0;
    top:0
}
.rating-overview{
    background:#fff;
    border-radius:8px;
    display:flex;
    margin:35px 0 25px;
    padding:30px 20px 30px 30px
}
.rating-overview-box{
    align-items:center;
    border:#ffdead;
    border-radius:0;
    border-right:1px solid #e8e8e8;
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    justify-content:center;
    margin-right:20px;
    padding-right:27px;
    text-align:center;
    width:135px
}
.rating-overview-box-total{
    color:#2a2f3a;
    display:block;
    font-size:58px;
    font-weight:700;
    line-height:1em
}
.rating-overview-box-percent{
    display:block;
    font-size:15px;
    margin-bottom:0
}
.rating-overview-box .star-rating{
    display:block;
    font-size:16px;
    letter-spacing:-.5px;
    margin:0
}
.rating-bars{
    align-items:center;
    display:flex;
    flex-basis:100%;
    flex-direction:row;
    flex-wrap:wrap;
    margin:0;
    padding:0
}
.rating-bars-item{
    align-items:flex-start;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    margin:10px 15px;
    width:calc(50% - 30px)
}
@media (max-width:1366px){
    .rating-bars-item{
        width:calc(50% - 30px)
    }
}
.rating-bars-name{
    color:#4c4f5a;
    display:block;
    font-size:14px;
    font-weight:600;
    line-height:22px
}
.rating-bars-inner{
    display:flex;
    width:100%
}
.rating-bars-rating{
    align-self:center;
    background:#f4f5f7;
    background-color:#f4f5f7;
    border-radius:3px;
    display:inline-block;
    height:6px;
    margin-right:10px;
    overflow:hidden;
    vertical-align:baseline;
    width:100%
}
.rating-bars-rating-inner{
    background-color:#f4f5f7;
    display:block;
    height:6px;
    position:relative;
    transition:width .5s;
    width:0
}
.rating-bars-rating.high .rating-bars-rating-inner{
    background-color:#00ba74
}
.rating-bars-rating.good .rating-bars-rating-inner{
    background-color:#83ce36
}
.rating-bars-rating.mid .rating-bars-rating-inner{
    background-color:#fbb851
}
.rating-bars-rating.poor .rating-bars-rating-inner{
    background-color:#e6453c
}
.rating-bars-item strong{
    align-self:center;
    background:#f4f5f7;
    border-radius:50px;
    color:#4c4f5a;
    font-size:12px;
    font-weight:600;
    height:22px;
    line-height:21px;
    text-align:center;
    width:46px
}
.rating-overview-box .star-rating i{
    color:#ff9500
}
.middle-icon-features{
    display:table;
    text-align:center;
    width:100%
}
.middle-icon-features-item{
    margin-top:30px
}
.middle-icon-large-features-box{
    background:#fff;
    border:1px solid transparent;
    border-radius:20px;
    color:#fd5332;
    font-size:30px;
    height:85px;
    line-height:90px;
    margin:15px auto;
    transition:.25s ease-in-out;
    width:85px
}
.icon-features-wrap,.middle-icon-large-features-box{
    position:relative;
    text-align:center
}
.icon-features-wrap:before{
    height:auto;
    left:100%;
    position:absolute;
    top:40px;
    width:auto
}
.middle-icon-features-item.remove .icon-features-wrap:before{
    display:none
}
.middle-icon-features-content{
    padding:10px 30px 0;
    text-align:center
}
.middle-icon-features-content p{
    font-size:14px;
    margin-bottom:0
}
.middle-icon-features-content h4{
    font-size:20px;
    margin-bottom:10px
}
.middle-icon-large-features-box:before{
    background:transparent;
    border:1px solid transparent;
    border-radius:20px;
    content:"";
    height:85px;
    left:0;
    position:absolute;
    top:0;
    transform:rotate(45deg);
    width:85px
}
.middle-icon-large-features-box.f-light-warning:before{
    background:rgba(255,143,0,.1);
    border-color:rgba(255,143,0,.3);
    color:#ff8f00!important
}
.middle-icon-large-features-box.f-light-success:before{
    background:rgba(15,202,152,.1);
    border-color:rgba(15,202,152,.3);
    color:#29cf8d!important
}
.middle-icon-large-features-box.f-light-blue:before{
    background:rgba(29,150,255,.1);
    border-color:rgba(29,150,255,.3);
    color:#1d96ff!important
}
.middle-icon-large-features-box.f-light-purple:before{
    background:rgba(116,96,238,.1);
    background:rgba(243,48,102,.1);
    border-color:rgba(116,96,238,.3);
    border-color:rgba(243,48,102,.3);
    color:#fd5332!important;
    color:#f33066!important
}
.achievement-wrap{
    display:block;
    padding:2rem 0 0;
    position:relative;
    text-align:center
}
.achievement-content h4{
    color:#08184b;
    font-size:28px;
    line-height:1;
    margin-bottom:5px
}
.contact-info{
    margin-left:1rem
}
.cn-info-detail{
    align-items:center;
    justify-content:end;
    margin-bottom:1.5rem;
    position:relative
}
.cn-info-icon{
    display:inline-block;
    float:left;
    height:50px;
    width:50px
}
.cn-info-icon i{
    color:#fd5332;
    font-size:35px
}
.cn-info-title{
    font-size:15px;
    margin-bottom:2px
}
.modal{
    z-index:99999
}
.modal-body{
    padding:2.5em 3em
}
#avatar-modal .modal-body{
    padding:1em
}
h4.modal-header-title{
    font-size:4em;
    font-weight:800;
    margin:1rem 0 1em;
    text-align:center
}
.btn.pop-login{
    background:#fd5332;
    border-color:#fd5332;
    border-radius:50px;
    margin-top:.6rem;
    padding:20px 0
}
.modal-divider{
    margin:20px 0;
    position:relative;
    text-align:center
}
.modal-divider:before{
    border-bottom:1px solid #e1e5f2;
    content:" ";
    left:0;
    position:absolute;
    right:0;
    top:50%
}
.modal-divider span{
    background:#fff;
    padding:0 20px;
    position:relative
}
.social-login ul{
    display:flex;
    flex-wrap:wrap;
    margin:0;
    padding:0;
    width:100%
}
.social-login ul li{
    display:inline-block;
    flex:0 0 50%;
    list-style:none;
    padding:0 10px;
    width:50%
}
.social-login ul li a.btn{
    background:#f4f5f7;
    border-radius:5px;
    color:#fff;
    padding:20px 0;
    width:100%
}
.social-login ul li a.btn i{
    margin-right:7px
}
.social-login ul li a.btn.connect-fb{
    background:#3b5998
}
.social-login ul li a.btn.connect-google{
    background:#ec4514
}
.social-login ul li a.btn.connect-linkedin{
    background:#0073b0
}
.social-login ul li a.btn.connect-twitter{
    background:#20a4ea
}
.signup .modal-dialog{
    max-width:880px;
    z-index:999999
}
.signup .form-group{
    margin-bottom:1.5rem
}
.modal-dialog-centered .btn{
    font-weight:600
}
span.mod-close{
    align-items:center;
    background:#fff;
    border-radius:50%;
    box-shadow:0 5px 24px rgba(31,37,59,.15);
    -webkit-box-shadow:0 5px 24px rgba(31,37,59,.15);
    color:#fd5332;
    cursor:pointer;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
    height:35px;
    justify-content:center;
    position:absolute;
    right:15px;
    top:15px;
    width:35px;
    z-index:1
}
.sides-widget{
    background-color:#fff;
    border:1px solid #ebeef5;
    border-radius:8px;
    color:#707070;
    margin-bottom:30px;
    overflow:hidden;
    transition:.5s
}
.sides-widget:last-child{
    margin-bottom:0
}
.sides-widget-header{
    align-items:center;
    background:#0fca98;
    color:#fff;
    display:flex;
    padding:1.5rem 1.4rem;
    position:relative
}
.sides-widget-details{
    display:block;
    padding-left:20px;
    position:relative
}
.sides-widget-body{
    padding:1.5rem 1.4rem
}
.sides-widget-details h4{
    color:#fff!important;
    margin-bottom:2px
}
.sides-widget-details h4>a{
    color:#fff!important
}
.sides-widget-header .agent-photo{
    display:block;
    float:left
}
.sides-widget-header .agent-photo img{
    border-radius:50%;
    max-width:60px
}
.sides-widget-details span{
    color:#b8ffec
}
.sides-widget-details span i{
    color:#ffeb3b;
    font-size:18px;
    margin-right:10px;
    position:relative;
    top:3px
}
.modal-dialog-centered .form-control,.simple-form .form-control{
    background:#f6f8fd;
    border:1px solid #edf0f9;
    box-shadow:none
}
.agency{
    background-color:#fff;
    border-radius:8px;
    box-shadow:0 0 10px 0 rgba(0,0,0,.1);
    display:block;
    margin-bottom:30px
}
.agency-list{
    display:flex;
    margin-bottom:30px;
    padding:0 30px 0 0
}
.agency-list .agency-avatar{
    flex:1;
    padding:1rem
}
.agency-list .agency-avatar img{
    border-radius:8px;
    margin:0;
    width:100%
}
.agency-list .agency-content{
    flex:3;
    padding:30px 40px 20px
}
.agency-list .agency-name{
    margin-bottom:5px;
    padding-left:0
}
.agency-list .agency-desc{
    line-height:1.7
}
.agency-list .agency-name h4{
    margin-bottom:5px
}
.agency-list .agency-name span{
    font-size:15px;
    margin-top:2px
}
.agency-list .agency-name span i{
    margin-right:5px
}
.prt-detio span{
    background:rgba(255,87,34,.1);
    border-radius:50px;
    color:#ff5722;
    display:inline-block;
    font-size:13px;
    margin-bottom:5px;
    padding:5px 15px
}
.agency-detail-info{
    color:#909090;
    display:block;
    list-style:none;
    margin:0;
    padding:10px 0
}
.agency-detail-info li{
    line-height:24px;
    margin-left:28px;
    padding:3px 0;
    position:relative;
    word-break:break-all
}
.agency-detail-info li i{
    font-size:18px;
    left:-28px;
    position:relative;
    position:absolute;
    top:5px
}
.agency-list .social-icons{
    float:none;
    left:0;
    margin:7px 0 0;
    padding:0
}
.agency-list .social-icons li{
    display:inline-block;
    margin-right:10px
}
.agency-list .social-icons li a{
    align-items:center;
    background:#f0f3ff;
    border:1px solid #e7ebfb;
    border-radius:50%;
    display:flex;
    flex-wrap:wrap;
    height:40px;
    justify-content:center;
    text-align:center;
    top:0!important;
    width:40px
}
.agency-list .social-icons li a i{
    height:auto;
    width:auto
}
.fs-listings,.listings-container{
    position:relative;
    z-index:9
}
.list-layout .property-listing.property-1 .listing-detail-wrapper{
    padding:1rem 1rem .5rem
}
.list-layout .property-listing.property-1{
    display:flex;
    margin:0 0 30px
}
.list-layout .price-features-wrapper{
    padding:.1rem 1rem .6rem
}
.prt-types{
    background:#efefef;
    border-radius:50px;
    display:inline-block;
    font-size:12px;
    font-weight:600;
    margin-top:5px;
    padding:4px 12px
}
.list-layout .listing-detail-btn .more-btn{
    border-radius:50px!important;
    padding:6px 17px!important
}
.prt-types.sale{
    background:rgba(247,68,0,.1);
    color:#f74400
}
.prt-types.rent{
    background:rgba(136,102,218,.1);
    color:#8866da
}
.listing-locate{
    flex:1
}
.list-layout .property-listing.property-1 .listing-img-wrapper{
    border-radius:3px 0 0 3px;
    flex:2;
    height:0;
    min-height:210px;
    overflow:hidden
}
.list-layout .property-listing.property-1 .listing-img-wrapper a{
    min-height:210px
}
.list-layout .property-listing.property-1 .listing-name{
    font-size:16px;
    line-height:1.4;
    margin-bottom:0
}
.list-layout .property-listing.property-1 .listing-img-wrapper img{
    height:210px;
    -o-object-fit:cover;
    object-fit:cover
}
.list-layout .property-listing.property-1 .listing-img-wrapper>a:before{
    background:transparent
}
.list-layout .property-listing.property-1 .listing-content{
    flex:3;
    position:relative
}
.list-layout .property-listing.property-1 .listing-footer-wrapper{
    align-items:center;
    border-top:1px solid #e5e9ec;
    bottom:0;
    display:flex;
    left:0;
    padding:.9rem 1rem;
    position:absolute;
    right:0;
    vertical-align:middle;
    width:100%
}
.btn.btn-order-by-filt{
    align-items:center;
    background:transparent;
    border:none;
    border-radius:4px;
    box-shadow:0 0 6px 1px rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 6px 1px rgba(62,28,131,.1);
    color:#646e7b;
    display:flex;
    font-size:14px;
    justify-content:center;
    padding:9px 15px
}
.btn.btn-order-by-filt.light{
    background:#fff
}
.custom-drop .dropdown-menu{
    border:1px solid #eceff3;
    left:auto!important;
    right:0!important;
    top:34px!important
}
.custom-drop .dropdown-menu a{
    color:#6e727d;
    display:block;
    padding:5px 16px;
    transition:all .4s
}
.filter_search_opt a{
    align-items:center;
    background:rgba(25,206,109,.1);
    border:2px solid rgba(25,206,109,.2);
    border-radius:4px;
    color:#19ce6d!important;
    display:flex;
    height:54px;
    justify-content:center;
    margin-bottom:20px;
    width:100%
}
.filter_search_opt i{
    margin-left:10px
}
.item-sorting-box{
    align-items:center;
    display:flex;
    margin-bottom:1.5rem;
    width:100%
}
.item-sorting{
    flex:1
}
.sorting-by{
    display:inline-flex;
    margin-right:15px;
    width:140px
}
.item-sorting-box-right{
    align-items:center;
    display:flex
}
.shorting-list{
    display:block;
    margin:0;
    padding:0
}
.shorting-list li{
    display:inline-block;
    list-style:none;
    margin-right:10px
}
.shorting-list li:last-child{
    margin-right:0
}
.shorting-list li a{
    align-items:center;
    background:#fff;
    border:1px solid #e1e9ec;
    border-radius:4px;
    display:inline-flex;
    height:56px;
    justify-content:center;
    transition:all .4s ease;
    width:56px
}
.shorting-list li a.active,.shorting-list li a.focus,.shorting-list li a:hover{
    align-items:center;
    background:rgba(25,206,109,.1);
    border:1px solid #19ce6d;
    border-radius:4px;
    color:#19ce6d;
    display:inline-flex;
    height:56px;
    justify-content:center;
    width:56px
}
.sorting-by .select2-selection.select2-selection--single{
    border:1px solid #e6eaf3;
    border-radius:4px;
    padding-left:15px
}
.page-sidebar{
    background:#fff;
    border:1px solid #e6eaf1;
    border-radius:.4rem;
    margin-bottom:30px;
    padding:25px
}
.form-group.simple .form-control{
    background:#fff;
    border:1px solid #e6eaf3;
    border-radius:4px;
    box-shadow:none!important;
    color:#707e9c;
    margin:0!important
}
.form-group.simple span.select2-selection.select2-selection--single{
    border:1px solid #e6eaf3;
    border-radius:4px;
    padding-left:15px
}
.irs--flat .irs-line{
    background-color:#d9dfe8;
    border-radius:50px;
    height:3px;
    top:25px
}
.irs--flat .irs-max,.irs--flat .irs-min{
    display:none
}
.irs--flat .irs-bar{
    background-color:#00b074;
    height:3px;
    top:25px
}
.irs--flat .irs-handle>i:first-child{
    background-color:#fff;
    border:1px solid #00b074;
    border-radius:50%;
    cursor:pointer;
    display:block;
    height:12px;
    left:50%;
    margin-left:-3px;
    position:absolute;
    top:-1px;
    width:12px
}
.irs--flat .irs-from,.irs--flat .irs-single,.irs--flat .irs-to{
    background-color:#00b074
}
.irs--flat .irs-from:before,.irs--flat .irs-single:before,.irs--flat .irs-to:before{
    border-top-color:#00b074
}
.irs--flat .irs-handle.state_hover>i:first-child,.irs--flat .irs-handle:hover>i:first-child{
    background-color:#00b074
}
.blog-wrap-grid{
    background:#fff;
    border-radius:6px;
    box-shadow:0 0 10px 0 rgba(0,0,0,.1);
    -webkit-box-shadow:0 0 10px 0 rgba(0,0,0,.1);
    margin-top:25px;
    position:relative;
    width:100%
}
.blog-thumb{
    overflow:hidden;
    padding:10px
}
.blog-thumb,.blog-thumb img{
    border-radius:4px
}
.blog-info{
    padding:10px 20px
}
.blog-info .post-meta{
    font-size:.8em
}
.blog-info i{
    margin-right:7px
}
.blog-body{
    padding:5px 20px 30px
}
.bl-title{
    font-size:19px;
    margin-bottom:4px
}
.bl-continue{
    color:#fc5231;
    display:inline-block;
    font-family:Lora,serif;
    font-size:20px;
    font-style:italic;
    margin-top:12px;
    position:relative
}
a.bl-continue:before{
    content:"\ea4c";
    font-family:lineicons!important;
    position:absolute;
    right:-27px;
    top:2px
}
.single-post-item{
    background:#fff;
    border:2px solid #e5e8f3;
    border-radius:6px;
    display:block;
    margin-bottom:30px;
    padding:20px;
    width:100%
}
.post-featured-img{
    display:block;
    margin-bottom:1.5rem
}
.post-featured-img img{
    border-radius:6px
}
.single-post-item .post-details .meta-comment-tag{
    list-style:none;
    padding:0
}
.single-post-item .post-details .meta-comment-tag li{
    display:inline-block;
    margin-right:19px
}
.single-post-item .post-details .meta-comment-tag li a{
    color:#6e7c90;
    display:block;
    letter-spacing:0;
    line-height:18px
}
.single-post-item .post-details .meta-comment-tag li a .icons{
    margin-right:10px
}
.single-post-item .post-details .post-title{
    display:block;
    font-size:30px;
    font-weight:700;
    line-height:1.4;
    margin:15px 0 0
}
.single-post-item .post-details .text,.single-post-item .post-details p{
    color:#6e7c90;
    font-size:16px;
    margin:25px 0 0
}
.blog-page .blog-details blockquote{
    background:#f7f9fc;
    margin:50px 0;
    padding:28px 28px 28px 100px;
    position:relative
}
.blog-page .blog-details blockquote .icon{
    color:#fd5332;
    font-size:28px;
    left:35px;
    line-height:28px;
    position:absolute;
    top:42%
}
.blog-page .blog-details blockquote .name{
    color:#000c35;
    margin:12px 0 0
}
.blog-page .blog-details .post-bottom-meta{
    display:flex;
    flex-wrap:wrap;
    margin:43px 0 0
}
.blog-page .blog-details .post-bottom-meta .post-tags{
    flex:1 1 50%
}
.blog-page .blog-details .post-bottom-meta .post-tags .pbm-title{
    font-size:18px;
    line-height:22px;
    margin:0 0 20px
}
.blog-page .blog-details .post-bottom-meta .post-tags .list{
    list-style:none;
    padding:0
}
.blog-page .blog-details .post-bottom-meta .post-tags .list li{
    display:inline-block;
    margin-bottom:10px
}
.blog-page .blog-details .post-bottom-meta .post-tags .list li a{
    border:1px solid #e4e4e6;
    display:block;
    letter-spacing:0;
    padding:9px 20px
}
.blog-page .blog-details .post-bottom-meta .post-tags .list li a:hover{
    background:#fd5332;
    border-color:#fd5332;
    color:#fff
}
.blog-page .blog-details .post-bottom-meta .post-share .list{
    list-style:none;
    padding:0
}
.blog-page .blog-details .post-bottom-meta .post-share .list li{
    display:inline-block;
    margin-right:22px
}
.blog-page .blog-details .post-bottom-meta .post-share .list li:last-child{
    margin-right:0
}
.blog-page .blog-details .post-bottom-meta .post-share{
    flex:1 1 50%;
    text-align:right
}
.blog-page .blog-details .post-bottom-meta .post-share .pbm-title{
    font-size:18px
}
.blog-page .blog-details .post-bottom-meta .post-share .list li a{
    color:#8c96b5;
    display:block;
    font-size:16px;
    line-height:22px
}
.blog-page .blog-details .post-bottom-meta .post-share .list li a:hover{
    color:#ef4060
}
.single-post-pagination{
    border-top:1px solid #e4e4e6;
    display:flex;
    flex-wrap:wrap;
    margin:40px 0 0;
    padding:48px 0 0;
    position:relative
}
.single-post-pagination .prev-post{
    flex:1 1 50%
}
.single-post-pagination .next-post{
    flex:1 1 50%;
    text-align:right
}
.single-post-pagination .next-post .title,.single-post-pagination .prev-post .title{
    font-size:20px
}
.single-post-pagination .post-pagination-center-grid{
    left:50%;
    position:absolute;
    top:60%;
    transform:translateX(-50%) translateY(-50%)
}
.single-post-pagination .post-pagination-center-grid a{
    color:#fd5332;
    font-size:2.5rem
}
.single-post-pagination .prev-post a{
    display:block;
    overflow:hidden
}
.single-post-pagination .prev-post a .title-with-link .intro{
    color:#6e7c90;
    display:block;
    font-size:14px;
    font-weight:500;
    letter-spacing:1;
    line-height:18px;
    margin:0 0 9px;
    text-transform:uppercase
}
.single-post-pagination .prev-post a .title-with-link .title{
    color:#000c35;
    line-height:28px
}
.blog-page .blog-details .comment-area .all-comments .comments-title{
    font-size:26px;
    line-height:30px;
    margin:0 0 35px
}
.blog-page .blog-details .comment-area .all-comments .comment-list ul{
    list-style:none;
    padding:0
}
.blog-page .blog-details .comment-area .all-comments article{
    margin:0 0 50px;
    overflow:hidden
}
.blog-page .blog-details .comment-area .all-comments article .comment-author{
    float:left;
    width:100px
}
.blog-page .blog-details .comment-area .all-comments article .comment-author img{
    border-radius:50%;
    max-width:100px
}
.blog-page .blog-details .comment-area .all-comments article .comment-details{
    overflow:hidden;
    padding-left:30px
}
.blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta{
    display:flex;
    flex-wrap:wrap
}
.blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta{
    flex:1 1 70%
}
.blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .author-name{
    font-size:18px;
    line-height:28px;
    margin-bottom:2px
}
.blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .author-name .selected{
    color:#cacfef;
    font-size:12px;
    margin-left:4px
}
.blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date{
    color:#ef4060;
    font-size:12px;
    font-weight:500;
    letter-spacing:0;
    line-height:16px;
    margin:7px 0 0;
    text-transform:uppercase
}
.blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-reply{
    flex:1 1 30%;
    text-align:right
}
.blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-reply .reply{
    color:#6e7c90;
    font-size:14px;
    letter-spacing:0;
    line-height:18px
}
.blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-reply .reply .icon{
    margin-right:8px
}
.blog-page .blog-details .comment-area .all-comments article .comment-details .comment-text{
    margin:19px 0 0
}
.blog-page .blog-details .comment-area .all-comments article .comment-details .comment-text p{
    margin:0
}
.blog-page .blog-details .comment-area .all-comments .comment-list ul li ul{
    list-style:none;
    padding-left:40px
}
.posts-author{
    text-align:center
}
.posts-author span.img{
    border:4px solid #e4eaf3;
    border-radius:50%;
    display:table;
    height:120px;
    margin:0 auto 1rem;
    overflow:hidden;
    width:120px
}
span.img img{
    border-radius:50%;
    max-width:120px
}
h3.pa-name{
    font-size:18px;
    margin-bottom:5px
}
.social-links{
    margin:0 0 1rem;
    padding:0
}
.social-links li{
    display:inline-block;
    padding:2px 10px
}
.social-links li a{
    color:#8c96b5;
    font-size:16px
}
.social-links li a:focus,.social-links li a:hover{
    color:#fd5332
}
.single-widgets{
    background:#fff;
    border:2px solid #e5e8f3;
    border-radius:6px;
    margin:0 0 40px;
    padding:30px 28px 35px
}
.single-widgets.widget_search form{
    position:relative
}
.single-widgets.widget_search form input[type=search]{
    background:#f9fafc;
    border:1px solid #ebedf5;
    border-radius:4px;
    font-size:12px;
    height:60px;
    padding:10px 70px 10px 30px;
    width:100%
}
.single-widgets.widget_search form button{
    background:#fd5332;
    border:0;
    border-radius:4px;
    bottom:0;
    color:#fff;
    cursor:pointer;
    font-size:14px;
    height:50px;
    line-height:50px;
    position:absolute;
    right:5px;
    top:5px;
    width:52px
}
.single-widgets.widget_category ul{
    list-style:none;
    padding:0
}
.single-widgets.widget_category ul li{
    border-bottom:1px solid #eaeaea;
    display:block;
    padding:11px 0
}
.single-widgets.widget_category ul li:first-child{
    border-top:none
}
.single-widgets.widget_categories ul li a{
    display:block;
    font-size:14px;
    letter-spacing:0;
    line-height:24px;
    overflow:hidden;
    text-transform:none
}
.single-widgets.widget_category ul li a span{
    float:right
}
.single-widgets.widget_thumb_post ul{
    list-style:none;
    padding:0
}
.single-widgets.widget_thumb_post ul li{
    display:block;
    margin:0 0 15px;
    overflow:hidden
}
.single-widgets.widget_thumb_post ul li .left{
    display:block;
    float:left;
    width:80px
}
.single-widgets.widget_thumb_post ul li .right{
    display:block;
    overflow:hidden
}
.single-widgets.widget_thumb_post ul li .right .feed-title{
    display:block;
    font-size:17px;
    font-weight:700;
    letter-spacing:0;
    line-height:22px;
    margin:0 0 7px;
    overflow:hidden;
    padding-left:20px
}
.single-widgets.widget_thumb_post ul li .right .post-date{
    color:#6e7c90;
    display:block;
    font-size:14px;
    letter-spacing:0;
    line-height:18px;
    overflow:hidden;
    padding-left:20px
}
.single-widgets.widget_thumb_post ul li .right .post-date i{
    margin-right:5px
}
.single-widgets.widget_thumb_post ul li .left img{
    border-radius:3px;
    width:100%
}
.single-widgets.widget_tags ul{
    list-style:none;
    padding:0
}
.single-widgets.widget_tags ul li{
    display:inline-block;
    margin-bottom:5px
}
.single-widgets.widget_tags ul li a{
    border:1px solid #eaeaea;
    display:block;
    font-size:14px;
    letter-spacing:0;
    padding:9px 25px
}
.single-widgets.widget_tags ul li a:focus,.single-widgets.widget_tags ul li a:hover{
    background:#fd5332;
    border-color:#fd5332;
    color:#fff
}
.testimonial-wrap{
    background:#fff;
    border:1px solid #e5e9ec;
    border-radius:8px;
    padding:30px 25px;
    position:relative
}
.client-thumb-box{
    display:table;
    width:100%
}
.client-thumb-content{
    align-items:center;
    display:flex;
    margin-bottom:20px;
    width:100%
}
.client-thumb{
    border:5px solid rgba(45,45,45,.1);
    border-radius:50%;
    float:left;
    margin-right:20px;
    max-width:80px;
    padding:5px;
    position:relative
}
.client-info h5{
    color:#3151b7;
    font-size:16px
}
.client-info .small-font{
    font-size:13px
}
.testimonial-wrap p{
    font-size:15px;
    line-height:1.8
}
.smart-tes-author{
    display:inline-block;
    padding:0 0 1.2rem;
    text-align:left;
    width:100%
}
.smart-center{
    text-align:center
}
.smart-textimonials.smart-light{
    color:#fff
}
.smart-textimonials .item{
    padding:0 15px
}
.smart-textimonials .item-box{
    background:#fff;
    border-radius:8px;
    padding:2rem 1.2rem
}
.st-author-thumb{
    border-radius:50%;
    height:80px;
    position:relative;
    width:80px
}
.smart-tes-content p{
    font-size:15px
}
.st-author-thumb .quotes{
    align-items:center;
    border-radius:50%;
    bottom:-8px;
    color:#fff;
    display:flex;
    height:32px;
    justify-content:center;
    position:absolute;
    right:-5px;
    width:32px
}
.st-author-thumb img{
    border-radius:50%
}
.smart-light .st-author-thumb,.st-author-thumb.light{
    border-radius:50%;
    box-shadow:0 0 0 5px hsla(0,0%,100%,.3);
    -webkit-box-shadow:0 0 0 5px hsla(0,0%,100%,.3);
    float:left;
    height:80px;
    width:80px
}
.st-author-info{
    display:inline-block;
    margin-left:0;
    margin-top:2rem
}
.smart-textimonials.smart-light .st-author-title{
    color:#fff
}
.st-author-info .st-author-title{
    font-size:16px;
    line-height:1;
    margin:0
}
.st-author-info .st-author-subtitle{
    font-family:Lora,serif;
    font-size:12px;
    font-style:italic
}
.smart-center .st-author-box,.st-author-box{
    align-items:center;
    display:flex;
    flex-wrap:wrap
}
.smart-center .st-author-box{
    justify-content:center
}
.pricing-wrap{
    background:#fff;
    border:1px solid #eaecf1;
    border-radius:.5rem;
    margin-top:25px;
    padding:7px;
    position:relative;
    text-align:center;
    width:100%
}
.pricing-header{
    background:#eee;
    border-radius:5px;
    padding:2em 0;
    width:100%
}
.basic-pr .pricing-header{
    background:rgba(253,83,50,.1)
}
.platinum-pr .pricing-header{
    background:rgba(39,183,55,.1)
}
.standard-pr .pricing-header{
    background:rgba(3,169,244,.1)
}
.pricing-header .pr-title{
    font-size:18px;
    font-weight:700;
    margin:0 0 5px;
    text-transform:uppercase
}
.basic-pr .pricing-header .pr-value{
    color:#fd5332
}
.platinum-pr .pricing-header .pr-value{
    color:#27b737
}
.standard-pr .pricing-header .pr-value{
    color:#03a9f4
}
.pricing-header .pr-subtitle{
    font-family:Lora,serif;
    font-size:16px;
    font-style:italic;
    letter-spacing:1px
}
.pricing-value{
    display:table;
    margin:2rem auto;
    position:relative;
    text-align:center
}
.pr-value{
    font-size:4em;
    line-height:1;
    margin-bottom:0;
    position:relative
}
.pr-value sup{
    font-size:1.5rem;
    vertical-align:super
}
.recommended .pr-value{
    color:#27cc8f!important
}
.pricing-body ul{
    margin:0;
    padding:0;
    text-align:left
}
.pricing-body ul li{
    border-bottom:1px dashed #e1e4ec;
    list-style:none;
    padding:14px 15px;
    position:relative
}
.pricing-body ul li:last-child{
    border-bottom:none
}
.pricing-body ul li:before{
    align-items:center;
    background:#e9ebf3;
    border-radius:50%;
    content:"\e64c";
    display:inline-flex;
    font-family:themify;
    font-size:7px;
    height:15px;
    justify-content:center;
    position:absolute;
    right:20px;
    top:19px;
    width:15px
}
.pricing-body ul li.available:before{
    background:rgba(0,212,202,.2);
    color:#0cbfb7
}
.pricing-bottom{
    padding:2rem 0;
    width:100%
}
.pricing-bottom,.pricing-bottom .btn-pricing{
    display:table;
    margin:0 auto;
    text-align:center
}
.pricing-bottom .btn-pricing{
    background:transparent!important;
    border:2px solid #d0d6e6!important;
    border-radius:4px;
    color:#08184b!important;
    font-weight:600;
    padding:16px 50px;
    position:relative;
    transition:all .4s ease;
    width:80%
}
.pricing-bottom .btn-pricing:focus,.pricing-bottom .btn-pricing:hover,.recommended .pricing-bottom .btn-pricing{
    background:rgba(15,202,152,.2)!important;
    border-color:rgba(15,202,152,.3)!important;
    color:#0fca98!important
}
.slick-next{
    right:25px
}
.slick-prev{
    left:25px;
    z-index:1
}
.slick-arrow:before{
    background:hsla(0,0%,100%,.4);
    border-radius:4px;
    box-shadow:0 5px 24px rgba(31,37,59,.15);
    color:#fff;
    cursor:pointer;
    display:inline-block;
    font-size:12px;
    height:40px;
    line-height:40px;
    top:0;
    transition:.2s;
    width:40px
}
.dashboard-navbar{
    background:#fff;
    border-radius:10px;
    box-shadow:none;
    padding:0;
    position:relative;
    width:100%
}
.dashboard .sm-sidebar{
    padding:.6rem 0
}
.dashboard-wraper,.submit-page{
    background:#fff;
    border-radius:10px;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    padding:2em;
    position:relative;
    width:100%
}
.d-user-avater{
    margin:2em 0;
    text-align:center
}
.d-user-avater img{
    border-radius:50%;
    margin-bottom:8px;
    max-width:180px
}
.d-user-avater h4{
    font-size:19px;
    margin:10px 0 3px
}
.d-navigation ul{
    margin:0;
    padding:0
}
.d-navigation ul li{
    border-bottom:1px solid #edf0f7;
    list-style:none;
    padding:10px 15px
}
.d-navigation ul li:last-child{
    border-bottom:none;
    padding-bottom:0
}
.d-navigation ul li.active{
    background:rgba(15,202,152,.2)!important
}
.d-navigation ul li.active a{
    color:#0fca98!important
}
.d-navigation ul li a{
    color:#455892;
    display:inline-block;
    font-family:var(--font-heading);
    font-size:15px;
    font-weight:600;
    padding:3px 0
}
.d-navigation ul li a i{
    margin-right:10px
}
table.property-table-wrap{
    border:none;
    border-collapse:separate;
    border-spacing:0;
    width:100%
}
table.property-table-wrap th{
    background-color:#66676b;
    border:none;
    color:#fff;
    font-size:16px;
    font-weight:500;
    line-height:1;
    padding:18px 20px;
    position:relative;
    text-align:left;
    z-index:9
}
table.property-table-wrap th:first-child{
    border-radius:3px 0 0 3px;
    width:50%
}
table.property-table-wrap th:last-child{
    border-radius:0 3px 3px 0
}
table.property-table-wrap th i{
    font-size:14px;
    margin-right:3px
}
table.property-table-wrap th{
    background:#2e3d5f
}
table.property-table-wrap td{
    border-bottom:1px solid #e4e4e4;
    padding:35px 20px;
    vertical-align:middle
}
table.property-table-wrap .property-container{
    align-items:center;
    display:flex;
    flex-wrap:wrap
}
table.property-table-wrap td:first-child{
    line-height:25px;
    padding-left:0
}
table.property-table-wrap .property-container img{
    border-radius:3px;
    flex:1.1;
    margin-right:26px;
    width:150px
}
table.property-table-wrap .property-container .title{
    flex:2
}
table.property-table-wrap .property-container .title h4{
    display:block;
    font-size:16px;
    margin-bottom:0;
    margin-top:2px
}
table.property-table-wrap td.action a{
    color:#677994;
    display:block;
    line-height:20px;
    margin-left:21px;
    padding:3px 0;
    position:relative
}
table.property-table-wrap td.action a i{
    background:#f1f3f7;
    border-radius:3px;
    display:inline-block;
    height:30px;
    line-height:30px;
    text-align:center;
    width:30px
}
.bkmark a.delete{
    float:right
}
table.property-table-wrap .property-container .title span{
    display:block
}
table.property-table-wrap .property-container .title .table-property-price{
    background:#f1f3f7;
    border-radius:2px;
    color:#677994;
    display:inline-block;
    font-size:13px;
    margin-top:9px;
    padding:2px 10px
}
.call-to-act-wrap{
    padding:50px 0
}
.call-to-act{
    align-items:center;
    color:#fff;
    display:flex;
    flex-wrap:wrap;
    width:100%
}
.call-to-act-head{
    flex:1
}
.call-to-act h3{
    color:#fff;
    margin-bottom:5px
}
.btn.btn-call-to-act{
    background:#fff;
    border-radius:50px;
    box-shadow:0 0 0 5px hsla(0,0%,100%,.2);
    -webkit-box-shadow:0 0 0 5px hsla(0,0%,100%,.2);
    color:#333640;
    font-weight:600;
    padding:18px 40px
}
footer{
    position:relative;
    z-index:99
}
footer.dark-footer{
    background:#252c41
}
.footer-logo{
    border-bottom:1px solid #eaeef3;
    border-top:1px solid #eaeef3;
    display:inline-block;
    padding:20px 0;
    width:100%
}
footer.footer-small{
    padding:50px 0 0
}
img.img-footer{
    margin-bottom:2rem;
    max-width:180px
}
.ft-itext{
    font-size:16px;
    font-weight:500;
    margin:10px;
    text-align:center!important
}
.ft-itext i{
    margin-right:5px
}
ul.social{
    margin:0;
    padding:0
}
ul.social li{
    display:inline-block;
    list-style:none;
    margin:5px
}
ul.social li a{
    background:#fff;
    border-radius:50%;
    box-shadow:0 0 12px 0 rgba(71,119,244,.2);
    display:inline-block;
    height:40px;
    line-height:40px;
    text-align:center;
    width:40px
}
.footer-add{
    color:#858b9f;
    font-size:13px
}
.ft-copyright{
    border-top:1px solid #eaeef3;
    padding:15px 0 12px
}
.ft-copyright p{
    margin-bottom:0
}
.footer-logo img{
    max-width:185px
}
.footer-widget{
    padding:70px 0
}
h4.widget-title{
    font-size:17px;
    font-weight:500;
    margin-bottom:20px
}
.footer-widget ul{
    margin:0;
    padding:0
}
.footer-widget ul li{
    display:block;
    list-style:none;
    margin-top:15px
}
.footer-widget ul li a{
    color:#626a70
}
footer.skin-dark-footer h4{
    color:#fff
}
footer.skin-dark-footer,footer.skin-dark-footer .footer-widget ul li a,footer.skin-dark-footer a{
    color:#858b9f;
    font-size:14px;
    transition:all .4s ease
}
footer.skin-dark-footer .footer-widget ul li a:focus,footer.skin-dark-footer .footer-widget ul li a:hover{
    color:#fff
}
.widget_media_image{
    margin-top:3rem
}
.footer-bottom{
    border-top:1px solid #e9edf3;
    padding:20px 0
}
.skin-dark-footer .footer-bottom{
    background:#1b2132;
    border-top:none
}
ul.footer-bottom-social{
    margin:0;
    padding:0
}
ul.footer-bottom-social li{
    display:inline-block;
    list-style:none;
    margin-right:17px;
    margin-top:0
}
ul.footer-bottom-social li a{
    color:#546e7a
}
ul.footer-bottom-social li a:focus,ul.footer-bottom-social li a:hover{
    color:#007bff
}
ul.footer-bottom-social li i{
    margin-right:5px
}
footer.skin-dark-footer ul.footer-bottom-social li a{
    color:#858b9f;
    font-size:14px
}
footer.skin-dark-footer ul.footer-bottom-social li a:focus,footer.skin-dark-footer ul.footer-bottom-social li a:hover{
    color:#fff;
    opacity:1
}
.fw{
    display:inline-block;
    margin-bottom:18px;
    padding-left:28px;
    position:relative;
    width:100%
}
.fw:last-child{
    margin:0
}
.fw:before{
    font-family:themify;
    font-size:20px;
    left:0;
    position:absolute;
    top:0;
    vertical-align:initial
}
.fw.fw-location:before{
    content:"\e693"
}
.fw.fw-mail:before{
    content:"\e75a"
}
.fw.fw-call:before{
    content:"\e621"
}
.fw.fw-skype:before{
    content:"\e72d"
}
.fw.fw-web:before{
    content:"\e665"
}
.other-store-link{
    display:inline-block;
    margin-bottom:20px;
    width:auto
}
.other-store-link:last-child{
    margin-bottom:0
}
.other-store-app{
    align-items:center;
    border:1px solid #353d56;
    border-radius:4px;
    color:#fff;
    display:flex;
    flex-wrap:wrap;
    height:70px;
    padding:0 14px;
    width:250px
}
.os-app-caps span{
    color:#858b9f;
    display:block;
    font-size:13px;
    font-weight:400
}
.os-app-icon{
    margin-right:13px
}
.os-app-icon i{
    font-size:32px
}
.os-app-caps{
    font-size:18px;
    font-weight:600
}
.f-newsletter{
    display:block;
    margin-top:15px;
    max-width:510px;
    position:relative
}
.f-newsletter .form-control{
    background-color:transparent;
    border:2px solid #a5aebf;
    border-radius:3px;
    color:#7f8ca2;
    font-size:16px;
    font-weight:400;
    height:60px;
    padding:18px 20px;
    width:100%
}
.skin-dark-footer .f-newsletter .form-control{
    border:2px solid #9ea6ba
}
.f-newsletter .btn{
    background:transparent;
    border-radius:2px;
    border-width:0;
    bottom:4px;
    color:#9ea6ba;
    cursor:pointer;
    font-size:16px;
    font-weight:500;
    height:52px;
    letter-spacing:1px;
    padding:18px 12px 14px;
    position:absolute;
    right:4px;
    text-align:center;
    text-decoration:none;
    text-transform:uppercase;
    white-space:normal;
    width:auto
}
.skin-dark-footer .form-control::-moz-placeholder{
    color:#9ea6ba
}
.skin-dark-footer .form-control::placeholder{
    color:#9ea6ba
}
.slick-dots li button:before{
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    background:rgba(1,41,91,.1);
    border-radius:50%;
    color:#26345f;
    content:"•";
    font-family:slick;
    font-size:18px;
    height:16px;
    left:0;
    line-height:16px;
    opacity:.3;
    position:absolute;
    text-align:center;
    top:0;
    width:16px
}
.slick-dots li.slick-active button:before{
    color:#626e80;
    content:"•";
    font-size:7px;
    line-height:18px;
    opacity:1
}
.team-grid{
    background:#fff;
    border-radius:10px;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -moz-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    display:block;
    overflow:hidden;
    padding:3em 0;
    position:relative
}
.single-team{
    padding:15px 10px
}
.teamgrid-user{
    border-radius:50%;
    margin:0 auto;
    max-width:120px;
    overflow:hidden
}
.teamgrid-content{
    padding:15px 0;
    text-align:center
}
.teamgrid-content h4{
    margin:0
}
.teamgrid-social ul{
    margin:0;
    padding:0;
    text-align:center
}
.teamgrid-social ul li{
    display:inline-block;
    list-style:none;
    padding:0 10px
}
.f-cl{
    color:#1943d0
}
.t-cl{
    color:#12b0e8
}
.i-cl{
    color:#d62ba4
}
.l-cl{
    color:#0c9a9e
}
.g-cl{
    color:#f53500
}
.p-cl{
    color:#d21818
}
.icon-mi-left{
    align-items:center;
    background:#fff;
    border-radius:6px;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    display:flex;
    justify-content:center;
    margin-top:20px;
    overflow:hidden;
    padding:1.2em 2em;
    position:relative
}
.icon-mi-left i{
    color:#333;
    font-size:55px;
    left:0;
    line-height:55px;
    position:relative;
    text-align:left;
    top:5px
}
.icon-mi-left .icon-mi-left-content{
    padding:10px 0 0 15px
}
.icon-mi-left h4{
    font-size:20px;
    font-weight:600;
    margin-bottom:5px;
    text-align:left
}
.icon-mi-left p{
    line-height:1.7
}
.faq-search form{
    margin:1.5rem auto;
    max-width:610px;
    position:relative
}
.faq-search form input{
    border:none;
    border-radius:4px;
    height:60px;
    padding-left:30px!important;
    padding-right:50px!important
}
.faq-search form button[type=submit]{
    background-color:transparent;
    border:0;
    bottom:0;
    color:#0050b4;
    cursor:pointer;
    font-size:18px;
    line-height:63px;
    position:absolute;
    right:0;
    top:0;
    width:50px
}
.ac-para{
    line-height:1.8
}
.contact-box{
    background:#fff;
    border-radius:10px;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -moz-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    margin-bottom:30px;
    overflow:hidden;
    padding:2.5em 2em;
    text-align:center
}
.contact-box i{
    display:table;
    font-size:3em;
    margin:0 auto 1rem
}
.contact-box h4{
    font-size:18px;
    margin-bottom:3px
}
.contact-box p{
    margin-bottom:.3rem
}
.contact-box .live-chat{
    background:rgba(25,189,88,.1);
    border:none;
    border-radius:2px;
    color:#19bd58;
    cursor:pointer;
    display:table;
    margin:5px auto 0;
    outline:none;
    padding:4px 12px
}
.pay-wrap{
    background:#fff;
    border-radius:10px;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -moz-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    overflow:hidden
}
.pay-wrap-header{
    align-items:center;
    border-bottom:1px solid #e7eaf1;
    display:flex;
    justify-content:center;
    padding:12px 25px;
    position:relative;
    width:100%
}
.pay-wrap-header h4{
    flex:1;
    font-size:20px;
    font-weight:500;
    margin:0
}
.pw-right h3{
    color:red;
    margin:0
}
.pw-right h3 sub{
    color:#767a8a;
    font-size:15px;
    font-weight:500
}
.pw-first-content{
    align-items:center;
    display:flex;
    justify-content:center;
    position:relative;
    width:100%
}
.pw-first-content h4{
    flex:1;
    font-size:18px;
    font-weight:600;
    margin-bottom:0
}
.pw-first-content{
    padding:10px 25px
}
.pay-wrap button,.pay-wrap button:focus,.pay-wrap button:hover{
    background:rgba(25,189,88,.1);
    border:none;
    border-radius:2px;
    color:#19bd58;
    cursor:pointer;
    outline:none;
    padding:4px 12px
}
#change-plan,.pw-content-detail{
    padding:10px 25px;
    position:relative
}
.pw-content-detail{
    width:100%
}
.pw-content-detail ul{
    margin:0;
    padding:0;
    position:relative
}
.pw-content-detail ul li{
    display:block;
    list-style:none;
    margin-bottom:10px;
    padding-left:30px;
    position:relative
}
.pw-btn-wrap{
    padding:10px 25px 25px;
    position:relative;
    width:100%
}
.btn.btn-payment{
    background:#fff;
    border:2px solid #19bd58;
    border-radius:4px;
    color:#19bd58;
    font-size:17px;
    text-align:center;
    width:100%
}
.pw-content-detail ul li:before{
    align-items:center;
    background:rgba(25,189,88,.1);
    border-radius:50%;
    color:#19bd58;
    content:"\e64c";
    display:flex;
    font-family:themify;
    font-size:10px;
    height:20px;
    justify-content:center;
    left:0;
    position:absolute;
    top:0;
    width:20px
}
.product-wrap{
    background:#fff;
    border-radius:10px;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -webkit-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    -moz-box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    margin-bottom:30px;
    overflow:hidden;
    padding:2em 1rem
}
.product-wrap ul{
    margin:0;
    padding:0
}
.product-wrap ul li{
    border-bottom:1px dashed #dadde8;
    display:flex;
    flex-wrap:wrap;
    padding:14px 0
}
.product-wrap ul li strong{
    flex:1
}
form.dropzone.dz-clickable.primary-dropzone{
    background:#f9fafc;
    border:1px solid #ebedf5
}
.alert-success{
    background-color:#e9ffeb;
    border-color:#e9ffeb;
    color:#27b737
}
.block-header.ags{
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    padding-bottom:1rem;
    width:100%
}
.block-header.ags .block-title{
    flex:1;
    line-height:0;
    margin:0
}
.btn.btn-agent-add{
    background:#00ba74;
    border-radius:4px;
    color:#fff!important
}
form.dropzone.profile-logo.dz-clickable{
    align-items:center;
    border-radius:50%;
    display:flex;
    font-size:49px;
    height:120px;
    justify-content:center;
    min-height:120px;
    width:120px
}
.form-submit.middle-logo{
    display:table;
    margin:1rem auto;
    text-align:center
}
.checkout-wrap{
    padding:2rem 0
}
.checkout-side,.checkout-wrap{
    background:#fff;
    border:1px solid #eef2f7;
    border-radius:4px;
    margin-bottom:30px
}
.checkout-side{
    padding:2rem 0 0
}
.invoice-vew-detail{
    background:#fff;
    border:1px solid #eef2f7;
    border-radius:4px;
    padding:2rem 1rem
}
.checkout-head{
    border-bottom:1px solid #e7eaec;
    padding-bottom:2rem
}
.checkout-body{
    padding:2rem 2rem 0
}
.checkout-head ul{
    align-items:center;
    display:flex;
    width:100%
}
.checkout-head ul li{
    flex:0 0 33.333333%;
    list-style:none;
    text-align:center;
    width:33.333333%
}
.checkout-head ul li span{
    background:#fff;
    border-radius:50%;
    box-shadow:0 0 0 4px hsla(20,9%,81%,.4);
    color:#ff5722;
    display:table;
    font-size:12px;
    height:30px;
    line-height:30px;
    margin:5px auto;
    width:30px
}
.checkout-head ul li.active span{
    background:#1bc193;
    box-shadow:0 0 0 4px rgba(76,220,173,.4);
    color:#fff
}
.success-message{
    text-align:center
}
span.thumb-check{
    background:#1bc193;
    border-radius:50%;
    box-shadow:0 0 0 4px rgba(76,220,173,.4);
    color:#fff;
    display:table;
    font-size:28px;
    height:80px;
    line-height:80px;
    margin:5px auto 20px;
    width:80px
}
ul.booking-detail-list{
    margin:0;
    padding:0
}
ul.booking-detail-list li{
    display:block;
    margin-bottom:13px;
    width:100%
}
ul.booking-detail-list li span{
    float:right
}
.booking-short{
    padding:0 1rem 1rem
}
.booking-short img{
    border-radius:4px;
    margin-bottom:.5rem
}
.booking-short h4{
    font-size:15px;
    margin-bottom:0
}
.card-header{
    background:#fff;
    border:none;
    border-radius:0;
    border-top:1px solid #e9edf3
}
#accordionExample{
    border:none;
    border-radius:0
}
.accordion .btn-link{
    color:#333c56;
    font-size:13px;
    font-size:15px;
    font-weight:600;
    padding:3px 0
}
.booking-bio ul{
    margin:0;
    padding:0
}
.booking-bio ul li{
    list-style:none;
    margin-bottom:12px
}
.booking-bio ul li strong{
    display:block
}
.invoice-vew-detail h4{
    font-size:16px;
    margin-bottom:15px;
    padding-bottom:10px;
    position:relative
}
.invoice-vew-detail h4:before{
    background:#ff5722;
    bottom:0;
    content:"";
    height:2px;
    left:0;
    position:absolute;
    width:60px
}
.dashboard-stat{
    background-color:#444;
    border-radius:4px;
    color:#fff;
    cursor:default;
    display:inline-block;
    height:160px;
    margin-bottom:20px;
    overflow:hidden;
    padding:0;
    position:relative;
    transition:.3s;
    width:100%
}
.dashboard-stat-content{
    left:32px;
    position:absolute;
    top:50%;
    transform:translateY(-50%)
}
.dashboard-stat-content h4{
    color:#fff;
    font-size:42px;
    font-weight:600;
    letter-spacing:1px;
    margin:0;
    padding:0
}
.dashboard-stat-content span{
    display:inline-block;
    font-size:18px;
    font-weight:300;
    line-height:24px;
    margin-top:4px
}
.dashboard-stat-icon{
    font-size:80px;
    font-size:65px;
    opacity:.2;
    position:absolute;
    right:32px;
    top:50%;
    transform:translateY(-40%)
}
.dashboard-stat.widget-1{
    background:linear-gradient(270deg,hsla(0,0%,100%,0) 30%,hsla(0,0%,100%,.1));
    background-color:#1baf65
}
.dashboard-stat.widget-2{
    background:linear-gradient(270deg,hsla(0,0%,100%,0) 30%,hsla(0,0%,100%,.1));
    background-color:#f39f00
}
.dashboard-stat.widget-3{
    background:linear-gradient(270deg,hsla(0,0%,100%,0) 30%,hsla(0,0%,100%,.1));
    background-color:#002758
}
.dashboard-stat.widget-4{
    background:linear-gradient(270deg,hsla(0,0%,100%,0) 30%,hsla(0,0%,100%,.1));
    background-color:#ce024b
}
.dashboard-stat.widget-5{
    background:linear-gradient(270deg,hsla(0,0%,100%,0) 30%,hsla(0,0%,100%,.1));
    background-color:#a671ce
}
.dashboard-stat.widget-6{
    background:linear-gradient(270deg,hsla(0,0%,100%,0) 30%,hsla(0,0%,100%,.1));
    background-color:#3a9dec
}
body #Packages{
    background:#fff!important;
    border-radius:4px;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    display:inline-block;
    margin:10px 0;
    padding:5px 0;
    width:100%
}
.btn.btn-link.collapsed,.btn.btn-link:focus{
    text-decoration:none!important
}
#extraPackages{
    border-radius:4px;
    box-shadow:0 0 20px 0 rgba(62,28,131,.1);
    margin-bottom:25px;
    padding:2rem 1.5rem
}
.package-box{
    border:1px solid #e8eaf1;
    border-radius:4px;
    padding:1rem 0 0;
    text-align:center
}
ul.packages-lists-list{
    margin:0;
    padding:0 1rem 1rem
}
ul.packages-lists-list li{
    list-style:none;
    padding:5px 0
}
.buypackage{
    background:#f4f5f7;
    border-top:1px solid #e3e6ec;
    padding:1rem
}
.packages-features-title{
    font-size:17px
}
.buypackage span{
    display:block;
    margin-top:.7rem
}
.pay-btn{
    background:#f4f5f7;
    border-radius:4px;
    display:inline-block;
    margin:0 13px 5px 0;
    padding:15px 20px
}
.pay-btn.paypal{
    background:#1468d0;
    color:#fff!important
}
.pay-btn.stripe{
    background:#7f35e0;
    color:#fff!important
}
.pay-btn.wire-trans{
    background:#1ca6e4;
    color:#fff!important
}
.singles-dashboard-list{
    align-items:center;
    border:1px solid #eaeef3;
    border-radius:6px;
    display:flex;
    flex-wrap:wrap;
    margin-bottom:30px;
    overflow:hidden;
    width:100%
}
.sd-list-left{
    flex:1;
    height:180px;
    max-height:180px;
    max-width:240px
}
.sd-list-left img{
    height:100%;
    -o-object-fit:cover;
    object-fit:cover
}
.sd-list-right{
    padding:1rem 2rem
}
.sd-list-right .listing_dashboard_title{
    font-size:16px;
    margin-bottom:5px
}
.user_dashboard_listed{
    display:block;
    margin-bottom:2px
}
.sd-list-right .action{
    margin-top:1rem
}
.sd-list-right .action a{
    background:#474f5f;
    border-radius:3px;
    color:#fff!important;
    display:inline-block;
    font-size:12px;
    height:30px;
    line-height:32px;
    margin-right:5px;
    text-align:center;
    width:30px
}
@media (min-height:600px) and (min-width:767px){
    .height-10{
        height:10vh
    }
    .height-20{
        height:20vh
    }
    .height-30{
        height:30vh
    }
    .height-40{
        height:40vh
    }
    .height-50{
        height:50vh
    }
    .height-60{
        height:60vh
    }
    .height-70{
        height:70vh
    }
    .height-80{
        height:80vh
    }
    .height-90{
        height:90vh
    }
    .height-100{
        height:100vh
    }
}
@media (max-width:1199px) and (min-width:991px){
    .list-layout .property-listing.property-1 .listing-name{
        font-size:13px
    }
    .listing-card-info-price{
        font-size:18px
    }
}
@media (max-height:600px){
    .pos-vertical-center{
        top:0;
        transform:none;
        -webkit-transform:none
    }
}
@media (min-width:768px){
    form.search-big-form.search-shadow .form-group,form.search-form.search-shadow .form-group{
        margin-bottom:0
    }
    .no-ul-list.third-row li{
        flex:0 0 33.333333%;
        max-width:33.333333%
    }
    .advance-search.advn-fixed{
        animation-duration:.5s;
        animation-name:slideInDown;
        left:0;
        position:fixed;
        right:0;
        top:89px;
        transition:.2s ease-in;
        width:100%;
        z-index:9
    }
}
@media (min-width:992px){
    .header.header-fixed{
        animation-duration:.5s;
        animation-name:slideInDown;
        background:#fff;
        box-shadow:0 5px 30px rgba(0,22,84,.1);
        -webkit-box-shadow:0 5px 30px rgba(0,22,84,.1);
        position:fixed;
        top:0;
        transition:.2s ease-in;
        width:100%;
        z-index:999
    }
    .filter_search_opt{
        display:none
    }
    .header.header-transparent{
        background:transparent;
        border-bottom:1px solid hsla(0,0%,100%,.1);
        display:inline-block;
        left:0;
        position:absolute;
        right:0;
        top:0;
        width:100%;
        z-index:4
    }
    .change-logo .nav-brand.fixed-logo{
        display:none
    }
    .change-logo.header-fixed .nav-brand.fixed-logo{
        display:block
    }
    .change-logo.header-fixed .nav-brand.static-logo{
        display:none
    }
    .header.header-transparent.header-fixed{
        animation-duration:.5s;
        animation-name:slideInDown;
        background:#fff;
        box-shadow:0 5px 30px rgba(0,22,84,.1);
        -webkit-box-shadow:0 5px 30px rgba(0,22,84,.1);
        position:fixed;
        top:0;
        transition:.2s ease-in;
        width:100%;
        z-index:999
    }
    .header.header-transparent .nav-brand,.header.header-transparent .nav-brand:focus,.header.header-transparent .nav-brand:hover,.header.header-transparent .nav-menu>li>a{
        color:#fff
    }
    .header.header-transparent.dark .nav-brand,.header.header-transparent.dark .nav-brand:focus,.header.header-transparent.dark .nav-brand:hover,.header.header-transparent.dark .nav-menu>li>a{
        color:#39466d
    }
    .header.header-transparent .submenu-indicator-chevron{
        border-color:transparent #fff #fff transparent
    }
    .header.header-transparent .nav-submenu .submenu-indicator-chevron{
        border-color:transparent #70798b #70798b transparent
    }
    .header.header-transparent.dark .submenu-indicator-chevron{
        border-color:transparent #39466d #39466d transparent
    }
    .header .nav-menu>.active>a .submenu-indicator-chevron,.header .nav-menu>.focus>a .submenu-indicator-chevron,.header .nav-menu>li:hover>a .submenu-indicator-chevron{
        border-color:transparent #0fca98 #0fca98 transparent
    }
    .header.header-transparent.header-fixed .nav-brand,.header.header-transparent.header-fixed .nav-brand:focus,.header.header-transparent.header-fixed .nav-brand:hover,.header.header-transparent.header-fixed .nav-menu>li>a{
        color:#647392
    }
    .header.header-transparent.header-fixed .submenu-indicator-chevron{
        border-color:transparent #647392 #647392 transparent
    }
    .nav-menu.nav-menu-social>li.add-listing a{
        top:-16px
    }
    .hero-banner{
        min-height:580px
    }
    .hero-search h1{
        font-size:40px
    }
    .h1,h1{
        font-size:4.142em;
        line-height:1.31818182em
    }
    .breadcrumbs{
        margin-top:92px
    }
    .ct-header h1.big{
        font-size:70px;
        line-height:1
    }


    .ct-u-display-tablex{
        height:100vh;
        min-height:500px
    }
    .hero-banner,.hero-header,.main-banner.image-banner{
        min-height:518px
    }
    .hero-header.min-banner{
        height:80vh;
        min-height:400px
    }
    .hero-banner h2,.main-banner h1{
        font-size:60px;
        line-height:1.1
    }
    .hero-content h1{
        font-size:60px
    }
    .content h1{
        font-size:70px
    }
    .hero-content p{
        font-size:22px
    }
    .jumbo-banner.software .hero-content{
        margin-top:0
    }
    .jumbo-banner.software .hero-content h1{
        font-size:60px
    }
    .sec-heading h2{
        font-size:29px;
        line-height:1.2
    }
    .page-title-wrap h1{
        font-size:55px;
        line-height:1.4
    }
    .modal-dialog{
        margin:30px auto;
        max-width:600px
    }
    .cta-sec h1,.cta-sec h2{
        font-size:38px;
        margin-bottom:12px
    }
}
@media (min-width:993px){
    .head-shadow{
        box-shadow:0 5px 30px rgba(0,22,84,.1);
        -webkit-box-shadow:0 5px 30px rgba(0,22,84,.1)
    }
}
@media (min-width:1200px){
    .hero-content h1{
        font-size:80px
    }
    .core-nav .megamenu.open>.megamenu-content{
        width:700px!important
    }
}
@media (max-width:1199px){
    .property-listing.property-1 .listing-location{
        font-size:13px;
        margin-bottom:2px
    }
    .job-new-list .btn.btn-outline-info.float-right{
        display:table;
        float:none!important;
        margin:0
    }
    .price-features-wrapper{
        padding:.1rem 1rem .6rem
    }
    .listing-card-info-icon{
        font-size:12px;
        margin-right:.7rem
    }
    .author-box{
        height:55px;
        width:55px
    }
    .property-listing.property-2 .listing-detail-wrapper{
        padding:1rem
    }
    .foot-location{
        display:flex;
        font-size:13px
    }
    .listing-short-detail-flex.fl-2{
        flex:2.5
    }
    .verticle-job-detail .jb-title{
        font-size:18px
    }
    .verticle-job-thumb{
        max-width:60px;
        width:60px
    }
    .verticle-job-detail{
        margin-left:65px
    }
    ul li.submit-attri{
        display:none
    }
    .dw-proprty-info li{
        flex:0 0 50%;
        max-width:50%
    }
}
@media (max-width:1023px){
    .half-map .fs-inner-container{
        width:100%
    }
    .fs-left-map-box .hm-map-container.fw-map{
        height:585px;
        overflow:hidden;
        position:relative;
        -webkit-transform:translateZ(0);
        width:100%
    }
    .half-map .fs-left-map-box{
        float:none
    }
    .fr-grid-info ul li{
        font-size:12px
    }
}
@media (max-width:992px){
    .header.nav-left-side .core-nav .wrap-core-nav-list .core-nav-list{
        float:none
    }
    .nav-menu>li .nav-dropdown{
        background:transparent;
        box-shadow:none;
        padding:0
    }
    .nav-menu>li>.nav-dropdown:before{
        display:none
    }
    .navigation-portrait .nav-menu.nav-menu-social{
        padding-top:1rem
    }
    .header-dark-transparent nav .menu li a{
        color:#606c8e
    }
    .header-dark-transparent button.toggle-bar.core-nav-toggle{
        color:#fff
    }
    .header-dark-transparent.header-fixed button.toggle-bar.core-nav-toggle{
        color:#728496
    }
}
@media (max-width:991px){
    .hero-banner,.hero-header,.large-banner,.main-banner.image-banner{
        min-height:580px
    }
    .pricing-body ul li{
        padding:9px 10px
    }
    /* .sm-sidebar{
        animation:animateleft .4s;
        background-color:#fff;
        border-radius:0;
        box-shadow:0 2px 10px 0 #d8dde6;
        height:100%;
        left:-310px;
        max-width:300px;
        min-width:310px;
        overflow:auto;
        position:fixed!important;
        position:relative;
        top:0;
        z-index:1
    } */
    .dashboard .search-sidebar_header{
        padding:0 1rem
    }
    .change-logo .nav-brand.static-logo{
        display:none
    }
    .nav-menu.nav-menu-social>li.add-listing a{
        padding:15px 20px;
        top:0
    }
    .search-sidebar_header{
        align-items:center;
        display:flex
    }
    ul.attributes{
        float:right
    }
    button.toggle-bar.core-nav-toggle{
        color:#8594b3
    }
    .nav-menu.nav-menu-social>li.add-listing{
        top:0
    }
    .core-nav .dropdown>.dropdown-menu{
        width:100%
    }
    .show-mb{
        display:initial!important
    }
    h1.italian-header-capt{
        font-size:25px
    }
    .pricing-body ul li{
        font-size:15px;
        padding:9px 0
    }
    list-layout .property-listing.property-1 .listing-img-wrapper{
        flex:initial
    }
    .list-layout .property-listing.property-1 .listing-content{
        flex:initial;
        position:relative
    }
    .list-layout .property-listing.property-1{
        display:block;
        margin:0 0 30px
    }
    .list-layout .property-listing.property-1 .listing-footer-wrapper{
        align-items:center;
        border-top:1px solid #e5e9ec;
        bottom:0;
        display:flex;
        justify-content:normal;
        left:0;
        padding:.9rem 1rem;
        position:relative;
        vertical-align:middle;
        width:100%
    }
    .agency-list{
        display:block
    }
    .avl-features li{
        flex:0 0 50%;
        max-width:50%
    }
    .slide-property-detail{
        display:block;
        position:relative
    }
    .slide-property-first{
        flex:initial;
        margin-bottom:1.5rem
    }
    .single-advance-property .container-fluid.p-0{
        padding:0 15px!important
    }
    .drops-dashboard{
        display:none
    }
}
@media (max-width:767px){
    .call-to-act{
        display:block;
        text-align:center
    }
    .hero-search h1{
        font-size:20px
    }
    .icon-features-wrap:before{
        display:none
    }
    .middle-icon-features-content h4{
        font-size:16px;
        margin-bottom:2px
    }
    .sec-heading h2{
        font-size:23px
    }
    .item-sorting-box{
        display:block
    }
    .call-to-act-head,.item-sorting{
        margin-bottom:1rem
    }
    .footer-widget{
        padding-bottom:0
    }
    .footer-bottom{
        margin-top:70px
    }
    .dropdown-submenu>.dropdown-menu.show{
        display:block
    }
    .full-search-2.hero-search-radius #advance-search{
        top:auto
    }
    .property-search-type label{
        font-size:14px;
        margin:0 7px 0 0;
        padding:8px 16px
    }
    .property-search-type label:nth-child(3){
        margin-right:0
    }
    .full-search-2.hero-search-radius #advance-search{
        padding:2rem .5rem
    }
    .full-search-2.hero-search-radius .checkbox-custom,.full-search-2.hero-search-radius .checkbox-custom-label,.full-search-2.hero-search-radius .radio-custom,.radio-custom-label{
        font-size:14px
    }
    .p-0.elio{
        padding:0 6px!important
    }
    .full-search-2.eclip-search.italian-search .b-r{
        border-right:none!important
    }
    .full-search-2.eclip-search.italian-search .b-l{
        border-left:none!important
    }
    .full-search-2.italian-search .form-group.borders{
        border:1px solid #dce3ec;
        border-radius:6px;
        height:auto;
        margin-bottom:10px;
        overflow:hidden
    }
    ul.detail_features li{
        flex:0 0 50%;
        width:50%
    }
    .property_block_wrap.style-3{
        display:block
    }
    .pbw-flex-1{
        margin-bottom:1rem
    }
    .pbw-flex{
        padding-left:0
    }
    .rating-overview{
        display:block
    }
    .rating-overview-box{
        border-right:none;
        margin-bottom:1rem;
        width:100%
    }
    .rating-bars-item{
        width:calc(100% - 30px)
    }
    .seo-contact .p-0{
        padding:0 15px!important
    }
    .seo-contact button.btn{
        width:100%
    }
    .nav-tabs .nav-item a{
        padding:.5em 1em
    }
    .boxed.boxed--lg{
        padding:2.77em 1em
    }
    .icon--lg{
        font-size:3.57142857em
    }
    .error-page h2{
        font-size:100px
    }
    .pos-vertical-center{
        top:0;
        transform:none;
        -webkit-transform:none
    }
    .listing-detail-item{
        display:inline-block;
        margin-left:4px
    }
    .tr-list-thumb{
        height:75px;
        margin-right:10px;
        max-width:75px
    }
    .tr-list-wrap{
        bottom:50px;
        display:block;
        position:relative
    }
    a.btn.btn-list{
        font-size:12px;
        margin-bottom:5px;
        padding:12px 10px
    }
    .advance-search .form-group{
        border:1px solid #e0ecf5;
        margin:.6rem 0
    }
    .advance-search .search-big-form{
        padding:10px!important
    }
    .job-new-list .vc-content,.job-new-list .vc-thumb{
        display:table;
        float:none;
        margin:.5rem 0
    }
    .mpl-0{
        padding-left:0!important
    }
    .verticle-candidate-list .vc-content{
        margin-top:1rem
    }
    .top-header .cn-info{
        display:none
    }
    .full-search-2.hero-search-radius{
        border-radius:10px
    }
    .full-search-2.hero-search-radius .form-control{
        border:none!important
    }
    .dashboard-navbar{
        margin-bottom:2rem
    }
    table.property-table-wrap .property-container img{
        display:none
    }
    table.property-table-wrap td{
        padding:35px 2px
    }
    table.property-table-wrap .property-container .title h4{
        font-size:14px
    }
    table.property-table-wrap td.action a{
        margin-left:10px
    }
    th.expire-date{
        display:none
    }
    .page-sidebar{
        margin-top:2rem
    }
    li.login-attri.theme-log a{
        padding:0 22px!important
    }
    .blog-page .blog-details blockquote{
        margin:30px 0;
        padding:20px
    }
    .blog-page .blog-details blockquote .icon{
        font-size:20px;
        left:20px;
        top:15px
    }
    .blog-page .blog-details .post-bottom-meta{
        display:block
    }
    .blog-page .blog-details .post-bottom-meta .post-tags{
        margin-bottom:1rem
    }
    .blog-page .blog-details .post-bottom-meta .post-share{
        text-align:left
    }
    .single-post-pagination{
        display:block
    }
    .single-post-pagination .next-post{
        text-align:left
    }
    .single-post-pagination .post-pagination-center-grid{
        display:none
    }
    .blog-page .blog-details .comment-area .all-comments article .comment-author{
        width:55px
    }
    .blog-page .blog-details .comment-area .all-comments article .comment-author img{
        border-radius:50%;
        max-width:50px
    }
    .blog-page .blog-details .comment-area .all-comments article .comment-details{
        overflow:hidden;
        padding-left:10px
    }
    .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .author-name{
        font-size:14px;
        line-height:13px;
        margin-bottom:0
    }
    .blog-page .blog-details .comment-area .all-comments .comment-list ul li ul{
        list-style:none;
        padding-left:30px
    }
    .modal-body{
        padding:2.5em 1em
    }
}
@media (max-width:600px){
    .hero-header{
        min-height:400px
    }
    .signup-frm .btn{
        border-width:0;
        bottom:4px;
        height:52px;
        margin-top:12px;
        position:relative;
        right:0;
        width:100%
    }
    ul.our-team-profile li a{
        height:34px;
        line-height:33px;
        width:34px
    }
    .rt-content-box ul.our-team-profile li{
        margin-right:6px;
        padding:0
    }
    .lt-img-box{
        display:table;
        float:none;
        margin:0 auto;
        width:105px
    }
    .rt-content-box{
        display:table;
        margin:0 auto;
        text-align:center
    }
    .hero-banner .btn-trans-video,.jumbo-banner .btn-trans-video{
        display:block;
        margin-left:0!important;
        margin-top:10px
    }
    .error-page h2{
        font-size:50px
    }
    .freelancer-thumb{
        float:none;
        margin-bottom:1rem
    }
    .freelancer-caption{
        display:block;
        margin-left:0
    }
    .hero-search-wrap{
        padding:45px 20px 30px
    }
    .header.nav-left-side ul.attributes li a{
        font-size:12px;
        padding:22px 8px
    }
    .avl-features li{
        flex:0 0 100%;
        max-width:100%
    }
    .agency-list .agency-content{
        padding:15px 20px 20px
    }
    .agency-list .agency-name h4{
        font-size:20px;
        margin-bottom:0
    }
    .agency-list .social-icons li{
        display:inline-block;
        margin-right:7px
    }
    .btn.btn-agent-add{
        display:none
    }
}
@media (max-width:500px){
    .list-layout .property-listing.property-1 .listing-name{
        font-size:14px
    }
    .listing-card-info-price{
        font-size:17px
    }
    .fs-inner-container .property-listing.property-1 .listing-location{
        font-size:12px
    }
    .list-layout .listing-detail-btn .more-btn{
        border-radius:50px!important;
        padding:5px 14px!important
    }
    .prt-detail-title-desc h3{
        font-size:18px;
        line-height:1.4
    }
    ul.detail_features li{
        flex:0 0 100%;
        width:100%
    }
    .author-review article .article_comments_thumb{
        float:left;
        width:60px
    }
    .author-review article .article_comments_thumb img{
        border-radius:50%;
        max-width:60px
    }
    .author-review article .comment-details{
        overflow:hidden;
        padding-left:20px
    }
    .author-review article .comment-details .comment-text p{
        font-size:13px
    }
    .nearby_header_first h5{
        font-size:13px;
        margin:0
    }
    .single-post-item .post-details .post-title{
        font-size:20px;
        line-height:1.3;
        margin:5px 0 0
    }
    .single-post-item .post-details .text,.single-post-item .post-details p{
        font-size:15px;
        margin:15px 0 0
    }
}
#back2Top{
    background:#333c56;
    border-radius:4px;
    bottom:10px;
    color:#fff;
    cursor:pointer;
    display:none;
    font-size:15px;
    line-height:40px;
    overflow:hidden;
    position:fixed;
    right:20px;
    text-align:center;
    text-decoration:none;
    width:40px;
    z-index:999
}
#back2Top:hover{
    background-color:#fff;
    color:#333c56
}
.style-switcher{
    background-color:#fff;
    box-shadow:0 0 25px rgba(0,0,0,.1);
    left:-220px;
    padding:0;
    position:fixed;
    top:35%;
    transition:.4s ease-in-out;
    width:220px;
    z-index:1010
}
.style-switcher .css-trigger{
    background-color:#2d4767;
    border-radius:0 3px 3px 0;
    color:#fff;
    cursor:pointer;
    font-size:24px;
    height:50px;
    line-height:55px;
    position:absolute;
    right:-50px;
    text-align:center;
    top:37%;
    transform:translateY(-50%);
    width:50px
}
.style-switcher .css-trigger a{
    color:#fff
}
.style-switcher.active{
    left:0
}
ul#themecolors{
    background:#334e6f;
    margin:0;
    padding:15px 0;
    text-align:center
}
ul#themecolors li{
    display:inline-block;
    list-style:none;
    padding:5px
}
ul#themecolors li a{
    background:#f4f5f7;
    border:3px solid #fff;
    border-radius:50%;
    display:inline-block;
    font-size:0;
    height:35px;
    width:35px
}
a.default-theme{
    background:#fb7618!important
}
a.blue-theme{
    background:#3a5bbc!important
}
a.green-theme{
    background:#28b446!important
}
a.red-theme{
    background:#d32e2a!important
}
a.purple-theme{
    background:#9886ec!important
}
a.yellow-theme{
    background:#fbbb00!important
}
a.oceangreen-theme{
    background:#00a877!important
}
a.goodred-theme{
    background:#f40c43!important
}
a.goodgreen-theme{
    background:#19b521!important
}
a.blue2-theme{
    background:#0176ff!important
}
footer .choose-currency{
    text-align:right
}
.choose-currency a{
    color:#fff;
    display:inline-block
}
@media only screen and (max-width:767px){
    .singles-dashboard-list{
        display:block;
        width:100%
    }
    .sd-list-left{
        max-width:100%
    }
    .sd-list-right{
        padding:1rem
    }
}
#additional_property_fields{
    display:none
}
.blue-skin .btn-theme-2,.blue-skin .pricing-bottom .btn-pricing,.blue-skin .top-header,.blue2-skin .btn-theme-2,.blue2-skin .pricing-bottom .btn-pricing,.blue2-skin .top-header,.darkblue-skin .btn-theme-2,.darkblue-skin .pricing-bottom .btn-pricing,.darkblue-skin .top-header,.goodgreen-skin .btn-theme-2,.goodgreen-skin .pricing-bottom .btn-pricing,.goodgreen-skin .top-header,.goodred-skin .btn-theme-2,.goodred-skin .pricing-bottom .btn-pricing,.goodred-skin .top-header,.green-skin .btn-theme-2,.green-skin .pricing-bottom .btn-pricing,.green-skin .top-header,.oceangreen-skin .btn-theme-2,.oceangreen-skin .pricing-bottom .btn-pricing,.oceangreen-skin .top-header,.purple-skin .btn-theme-2,.purple-skin .pricing-bottom .btn-pricing,.purple-skin .top-header,.red-skin .btn-theme-2,.red-skin .pricing-bottom .btn-pricing,.red-skin .top-header,.yellow-skin .btn-theme-2,.yellow-skin .pricing-bottom .btn-pricing,.yellow-skin .top-header{
    background-color:#252c41;
    background:#252c41;
    border-color:#252c41
}
.blue-skin .hero-search h1,.blue2-skin .hero-search h1,.darkblue-skin .hero-search h1,.goodgreen-skin .hero-search h1,.goodred-skin .hero-search h1,.green-skin .hero-search h1,.oceangreen-skin .hero-search h1,.purple-skin .hero-search h1,.red-skin .hero-search h1,.yellow-skin .hero-search h1{
    color:#2d3954!important
}
.red-skin .btn-outline-theme:focus,.red-skin .btn-outline-theme:hover,.red-skin .btn-theme-2:focus,.red-skin .btn-theme-2:hover,.red-skin .btn.btn-theme,.red-skin .btn.btn-theme:focus,.red-skin .btn.btn-theme:hover,.red-skin .btn.pop-login,.red-skin .btn.search-btn,.red-skin .btn.search-btn-outline:focus,.red-skin .btn.search-btn-outline:hover,.red-skin .checkbox-custom:checked+.checkbox-custom-label:before,.red-skin .home-slider-desc .read-more,.red-skin .nav-tabs .nav-item.show .nav-link,.red-skin .nav-tabs .nav-link.active,.red-skin .pagination li:first-child a,.red-skin .pagination>.active>a,.red-skin .pagination>.active>a:focus,.red-skin .pagination>.active>a:hover,.red-skin .pagination>.active>span,.red-skin .pagination>.active>span:focus,.red-skin .pagination>.active>span:hover,.red-skin .pagination>li>a:focus,.red-skin .pagination>li>a:hover,.red-skin .pricing-bottom .btn-pricing:focus .red-skin .select2-container--default .select2-results__option--highlighted[aria-selected],.red-skin .pricing-bottom .btn-pricing:hover,.red-skin .property-listing.property-1 .listing-detail-btn .more-btn,.red-skin .property-search-type label.active,.red-skin .property-search-type label:hover,.red-skin .radio-custom:checked+.radio-custom-label:before,.red-skin .range-slider .ui-slider .ui-slider-handle,.red-skin .range-slider .ui-widget-header,.red-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.red-skin .single-widgets.widget_search form button,.red-skin .single-widgets.widget_tags ul li a:focus,.red-skin .single-widgets.widget_tags ul li a:hover,.red-skin .theme-bg,.red-skin li.login-attri.theme-log a{
    background-color:#f02c2d;
    background:#f02c2d
}
.red-skin .bl-continue,.red-skin .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date,.red-skin .blog-page .blog-details blockquote .icon,.red-skin .btn-outline-theme,.red-skin .btn.btn-theme-light,.red-skin .btn.search-btn-outline,.red-skin .client-info h5,.red-skin .cn-info-icon i,.red-skin .d-navigation ul li.active a,.red-skin .dw-proprty-info li,.red-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.red-skin .listing-card-info-price,.red-skin .ps-trep .ps-type,.red-skin .recommended .pr-value,.red-skin .single-post-pagination .post-pagination-center-grid a,.red-skin .theme-cl,.red-skin a.link:focus,.red-skin a.link:hover,.red-skin a:hover,.red-skin nav .menu .mg-menu li a i,.red-skin nav .menu li a.active,.red-skin nav .menu li a:hover,.red-skin nav .menu li.dropdown.open>a,.red-skin span.mod-close,a:focus{
    color:#f02c2d
}
.red-skin .attributes li.submit-attri.theme-log a,.red-skin .btn-outline-theme,.red-skin .btn-outline-theme:focus,.red-skin .btn-outline-theme:hover,.red-skin .btn-theme-2:focus,.red-skin .btn-theme-2:hover,.red-skin .btn.btn-theme,.red-skin .btn.btn-theme:focus,.red-skin .btn.btn-theme:hover,.red-skin .btn.pop-login,.red-skin .btn.search-btn-outline,.red-skin .btn.search-btn-outline:focus,.red-skin .btn.search-btn-outline:hover,.red-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.red-skin .nav-tabs .nav-item.show .nav-link,.red-skin .nav-tabs .nav-link.active,.red-skin .pagination li:first-child a,.red-skin .pagination>.active>a,.red-skin .pagination>.active>a:focus,.red-skin .pagination>.active>a:hover,.red-skin .pagination>.active>span,.red-skin .pagination>.active>span:focus,.red-skin .pagination>.active>span:hover,.red-skin .pagination>li>a:focus,.red-skin .pagination>li>a:hover,.red-skin .property-listing.property-1 .listing-detail-btn .more-btn,.red-skin .range-slider .ui-slider .ui-slider-handle,.red-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.red-skin .single-widgets.widget_tags ul li a:focus,.red-skin .single-widgets.widget_tags ul li a:hover{
    border-color:#f02c2d
}
.red-skin li.login-attri.theme-log a{
    box-shadow:0 8px 5px rgba(240,44,45,.2);
    -webkit-box-shadow:0 8px 5px rgba(240,44,45,.2)
}
.green-skin .btn-outline-theme:focus,.green-skin .btn-outline-theme:hover,.green-skin .btn-theme-2:focus,.green-skin .btn-theme-2:hover,.green-skin .btn.btn-theme,.green-skin .btn.btn-theme:focus,.green-skin .btn.btn-theme:hover,.green-skin .btn.pop-login,.green-skin .btn.search-btn,.green-skin .btn.search-btn-outline:focus,.green-skin .btn.search-btn-outline:hover,.green-skin .checkbox-custom:checked+.checkbox-custom-label:before,.green-skin .home-slider-desc .read-more,.green-skin .nav-tabs .nav-item.show .nav-link,.green-skin .nav-tabs .nav-link.active,.green-skin .pagination li:first-child a,.green-skin .pagination>.active>a,.green-skin .pagination>.active>a:focus,.green-skin .pagination>.active>a:hover,.green-skin .pagination>.active>span,.green-skin .pagination>.active>span:focus,.green-skin .pagination>.active>span:hover,.green-skin .pagination>li>a:focus,.green-skin .pagination>li>a:hover,.green-skin .pricing-bottom .btn-pricing:focus .green-skin .select2-container--default .select2-results__option--highlighted[aria-selected],.green-skin .pricing-bottom .btn-pricing:hover,.green-skin .property-listing.property-1 .listing-detail-btn .more-btn,.green-skin .property-search-type label.active,.green-skin .property-search-type label:hover,.green-skin .radio-custom:checked+.radio-custom-label:before,.green-skin .range-slider .ui-slider .ui-slider-handle,.green-skin .range-slider .ui-widget-header,.green-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.green-skin .single-widgets.widget_search form button,.green-skin .single-widgets.widget_tags ul li a:focus,.green-skin .single-widgets.widget_tags ul li a:hover,.green-skin .theme-bg,.green-skin li.login-attri.theme-log a{
    background-color:#0fca98;
    background:#0fca98
}
.green-skin .attributes li.submit-attri.theme-log a,.green-skin .bl-continue,.green-skin .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date,.green-skin .blog-page .blog-details blockquote .icon,.green-skin .btn-outline-theme,.green-skin .btn.btn-theme-light,.green-skin .btn.search-btn-outline,.green-skin .client-info h5,.green-skin .cn-info-icon i,.green-skin .d-navigation ul li.active a,.green-skin .dw-proprty-info li,.green-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.green-skin .ps-trep .ps-type,.green-skin .recommended .pr-value,.green-skin .single-post-pagination .post-pagination-center-grid a,.green-skin .theme-cl,.green-skin a.link:focus,.green-skin a.link:hover,.green-skin a:hover,.green-skin nav .menu .mg-menu li a i,.green-skin nav .menu li a.active,.green-skin nav .menu li a:hover,.green-skin nav .menu li.dropdown.open>a,.green-skin span.mod-close,a:focus{
    color:#0fca98
}
.green-skin .attributes li.submit-attri.theme-log a,.green-skin .btn-outline-theme,.green-skin .btn-outline-theme:focus,.green-skin .btn-outline-theme:hover,.green-skin .btn-theme-2:focus,.green-skin .btn-theme-2:hover,.green-skin .btn.btn-theme,.green-skin .btn.btn-theme:focus,.green-skin .btn.btn-theme:hover,.green-skin .btn.pop-login,.green-skin .btn.search-btn-outline,.green-skin .btn.search-btn-outline:focus,.green-skin .btn.search-btn-outline:hover,.green-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.green-skin .nav-tabs .nav-item.show .nav-link,.green-skin .nav-tabs .nav-link.active,.green-skin .pagination li:first-child a,.green-skin .pagination>.active>a,.green-skin .pagination>.active>a:focus,.green-skin .pagination>.active>a:hover,.green-skin .pagination>.active>span,.green-skin .pagination>.active>span:focus,.green-skin .pagination>.active>span:hover,.green-skin .pagination>li>a:focus,.green-skin .pagination>li>a:hover,.green-skin .property-listing.property-1 .listing-detail-btn .more-btn,.green-skin .range-slider .ui-slider .ui-slider-handle,.green-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.green-skin .single-widgets.widget_tags ul li a:focus,.green-skin .single-widgets.widget_tags ul li a:hover{
    border-color:#0fca98
}
.green-skin li.login-attri.theme-log a{
    box-shadow:0 8px 5px rgba(0,186,116,.2);
    -webkit-box-shadow:0 8px 5px rgba(0,186,116,.2)
}
.green-skin .btn.btn-theme-light{
    background:rgba(0,186,116,.1)
}
.blue-skin .btn-outline-theme:focus,.blue-skin .btn-outline-theme:hover,.blue-skin .btn-theme-2:focus,.blue-skin .btn-theme-2:hover,.blue-skin .btn.btn-theme,.blue-skin .btn.btn-theme:focus,.blue-skin .btn.btn-theme:hover,.blue-skin .btn.pop-login,.blue-skin .btn.search-btn,.blue-skin .btn.search-btn-outline:focus,.blue-skin .btn.search-btn-outline:hover,.blue-skin .checkbox-custom:checked+.checkbox-custom-label:before,.blue-skin .home-slider-desc .read-more,.blue-skin .nav-tabs .nav-item.show .nav-link,.blue-skin .nav-tabs .nav-link.active,.blue-skin .pagination li:first-child a,.blue-skin .pagination>.active>a,.blue-skin .pagination>.active>a:focus,.blue-skin .pagination>.active>a:hover,.blue-skin .pagination>.active>span,.blue-skin .pagination>.active>span:focus,.blue-skin .pagination>.active>span:hover,.blue-skin .pagination>li>a:focus,.blue-skin .pagination>li>a:hover,.blue-skin .pricing-bottom .btn-pricing:focus .blue-skin .select2-container--default .select2-results__option--highlighted[aria-selected],.blue-skin .pricing-bottom .btn-pricing:hover,.blue-skin .property-listing.property-1 .listing-detail-btn .more-btn,.blue-skin .property-search-type label.active,.blue-skin .property-search-type label:hover,.blue-skin .radio-custom:checked+.radio-custom-label:before,.blue-skin .range-slider .ui-slider .ui-slider-handle,.blue-skin .range-slider .ui-widget-header,.blue-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.blue-skin .single-widgets.widget_search form button,.blue-skin .single-widgets.widget_tags ul li a:focus,.blue-skin .single-widgets.widget_tags ul li a:hover,.blue-skin .theme-bg,.blue-skin li.login-attri.theme-log a{
    background-color:#1266e3;
    background:#1266e3
}
.blue-skin .attributes li.submit-attri.theme-log a,.blue-skin .bl-continue,.blue-skin .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date,.blue-skin .blog-page .blog-details blockquote .icon,.blue-skin .btn-outline-theme,.blue-skin .btn.btn-theme-light,.blue-skin .btn.search-btn-outline,.blue-skin .client-info h5,.blue-skin .cn-info-icon i,.blue-skin .d-navigation ul li.active a,.blue-skin .dw-proprty-info li,.blue-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.blue-skin .listing-card-info-price,.blue-skin .ps-trep .ps-type,.blue-skin .recommended .pr-value,.blue-skin .single-post-pagination .post-pagination-center-grid a,.blue-skin .theme-cl,.blue-skin a.link:focus,.blue-skin a.link:hover,.blue-skin a:hover,.blue-skin nav .menu .mg-menu li a i,.blue-skin nav .menu li a.active,.blue-skin nav .menu li a:hover,.blue-skin nav .menu li.dropdown.open>a,.blue-skin span.mod-close,a:focus{
    color:#1266e3
}
.blue-skin .attributes li.submit-attri.theme-log a,.blue-skin .btn-outline-theme,.blue-skin .btn-outline-theme:focus,.blue-skin .btn-outline-theme:hover,.blue-skin .btn-theme-2:focus,.blue-skin .btn-theme-2:hover,.blue-skin .btn.btn-theme,.blue-skin .btn.btn-theme:focus,.blue-skin .btn.btn-theme:hover,.blue-skin .btn.pop-login,.blue-skin .btn.search-btn-outline,.blue-skin .btn.search-btn-outline:focus,.blue-skin .btn.search-btn-outline:hover,.blue-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.blue-skin .nav-tabs .nav-item.show .nav-link,.blue-skin .nav-tabs .nav-link.active,.blue-skin .pagination li:first-child a,.blue-skin .pagination>.active>a,.blue-skin .pagination>.active>a:focus,.blue-skin .pagination>.active>a:hover,.blue-skin .pagination>.active>span,.blue-skin .pagination>.active>span:focus,.blue-skin .pagination>.active>span:hover,.blue-skin .pagination>li>a:focus,.blue-skin .pagination>li>a:hover,.blue-skin .property-listing.property-1 .listing-detail-btn .more-btn,.blue-skin .range-slider .ui-slider .ui-slider-handle,.blue-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.blue-skin .single-widgets.widget_tags ul li a:focus,.blue-skin .single-widgets.widget_tags ul li a:hover{
    border-color:#1266e3
}
.blue-skin li.login-attri.theme-log a{
    box-shadow:0 8px 5px rgba(0,88,243,.2);
    -webkit-box-shadow:0 8px 5px rgba(0,88,243,.2)
}
.blue-skin .btn.btn-theme-light{
    background:rgba(0,88,243,.1)
}
.yellow-skin .btn-outline-theme:focus,.yellow-skin .btn-outline-theme:hover,.yellow-skin .btn-theme-2:focus,.yellow-skin .btn-theme-2:hover,.yellow-skin .btn.btn-theme,.yellow-skin .btn.btn-theme:focus,.yellow-skin .btn.btn-theme:hover,.yellow-skin .btn.pop-login,.yellow-skin .btn.search-btn,.yellow-skin .btn.search-btn-outline:focus,.yellow-skin .btn.search-btn-outline:hover,.yellow-skin .checkbox-custom:checked+.checkbox-custom-label:before,.yellow-skin .home-slider-desc .read-more,.yellow-skin .nav-tabs .nav-item.show .nav-link,.yellow-skin .nav-tabs .nav-link.active,.yellow-skin .pagination li:first-child a,.yellow-skin .pagination>.active>a,.yellow-skin .pagination>.active>a:focus,.yellow-skin .pagination>.active>a:hover,.yellow-skin .pagination>.active>span,.yellow-skin .pagination>.active>span:focus,.yellow-skin .pagination>.active>span:hover,.yellow-skin .pagination>li>a:focus,.yellow-skin .pagination>li>a:hover,.yellow-skin .pricing-bottom .btn-pricing:focus .yellow-skin .select2-container--default .select2-results__option--highlighted[aria-selected],.yellow-skin .pricing-bottom .btn-pricing:hover,.yellow-skin .property-listing.property-1 .listing-detail-btn .more-btn,.yellow-skin .property-search-type label.active,.yellow-skin .property-search-type label:hover,.yellow-skin .radio-custom:checked+.radio-custom-label:before,.yellow-skin .range-slider .ui-slider .ui-slider-handle,.yellow-skin .range-slider .ui-widget-header,.yellow-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.yellow-skin .single-widgets.widget_search form button,.yellow-skin .single-widgets.widget_tags ul li a:focus,.yellow-skin .single-widgets.widget_tags ul li a:hover,.yellow-skin .theme-bg,.yellow-skin li.login-attri.theme-log a{
    background-color:#ffa50d;
    background:#ffa50d
}
.yellow-skin .attributes li.submit-attri.theme-log a,.yellow-skin .bl-continue,.yellow-skin .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date,.yellow-skin .blog-page .blog-details blockquote .icon,.yellow-skin .btn-outline-theme,.yellow-skin .btn.btn-theme-light,.yellow-skin .btn.search-btn-outline,.yellow-skin .client-info h5,.yellow-skin .cn-info-icon i,.yellow-skin .d-navigation ul li.active a,.yellow-skin .dw-proprty-info li,.yellow-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.yellow-skin .listing-card-info-price,.yellow-skin .ps-trep .ps-type,.yellow-skin .recommended .pr-value,.yellow-skin .single-post-pagination .post-pagination-center-grid a,.yellow-skin .theme-cl,.yellow-skin a.link:focus,.yellow-skin a.link:hover,.yellow-skin a:hover,.yellow-skin nav .menu .mg-menu li a i,.yellow-skin nav .menu li a.active,.yellow-skin nav .menu li a:hover,.yellow-skin nav .menu li.dropdown.open>a,.yellow-skin span.mod-close,a:focus{
    color:#ffa50d
}
.yellow-skin .attributes li.submit-attri.theme-log a,.yellow-skin .btn-outline-theme,.yellow-skin .btn-outline-theme:focus,.yellow-skin .btn-outline-theme:hover,.yellow-skin .btn-theme-2:focus,.yellow-skin .btn-theme-2:hover,.yellow-skin .btn.btn-theme,.yellow-skin .btn.btn-theme:focus,.yellow-skin .btn.btn-theme:hover,.yellow-skin .btn.pop-login,.yellow-skin .btn.search-btn-outline,.yellow-skin .btn.search-btn-outline:focus,.yellow-skin .btn.search-btn-outline:hover,.yellow-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.yellow-skin .nav-tabs .nav-item.show .nav-link,.yellow-skin .nav-tabs .nav-link.active,.yellow-skin .pagination li:first-child a,.yellow-skin .pagination>.active>a,.yellow-skin .pagination>.active>a:focus,.yellow-skin .pagination>.active>a:hover,.yellow-skin .pagination>.active>span,.yellow-skin .pagination>.active>span:focus,.yellow-skin .pagination>.active>span:hover,.yellow-skin .pagination>li>a:focus,.yellow-skin .pagination>li>a:hover,.yellow-skin .property-listing.property-1 .listing-detail-btn .more-btn,.yellow-skin .range-slider .ui-slider .ui-slider-handle,.yellow-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.yellow-skin .single-widgets.widget_tags ul li a:focus,.yellow-skin .single-widgets.widget_tags ul li a:hover{
    border-color:#ffa50d
}
.yellow-skin li.login-attri.theme-log a{
    box-shadow:0 8px 5px rgba(255,165,13,.2);
    -webkit-box-shadow:0 8px 5px rgba(255,165,13,.2)
}
.yellow-skin .btn.btn-theme-light{
    background:rgba(255,165,13,.1)
}
.darkblue-skin .btn-outline-theme:focus,.darkblue-skin .btn-outline-theme:hover,.darkblue-skin .btn-theme-2:focus,.darkblue-skin .btn-theme-2:hover,.darkblue-skin .btn.btn-theme,.darkblue-skin .btn.btn-theme:focus,.darkblue-skin .btn.btn-theme:hover,.darkblue-skin .btn.pop-login,.darkblue-skin .btn.search-btn,.darkblue-skin .btn.search-btn-outline:focus,.darkblue-skin .btn.search-btn-outline:hover,.darkblue-skin .checkbox-custom:checked+.checkbox-custom-label:before,.darkblue-skin .home-slider-desc .read-more,.darkblue-skin .nav-tabs .nav-item.show .nav-link,.darkblue-skin .nav-tabs .nav-link.active,.darkblue-skin .pagination li:first-child a,.darkblue-skin .pagination>.active>a,.darkblue-skin .pagination>.active>a:focus,.darkblue-skin .pagination>.active>a:hover,.darkblue-skin .pagination>.active>span,.darkblue-skin .pagination>.active>span:focus,.darkblue-skin .pagination>.active>span:hover,.darkblue-skin .pagination>li>a:focus,.darkblue-skin .pagination>li>a:hover,.darkblue-skin .pricing-bottom .btn-pricing:focus .darkblue-skin .select2-container--default .select2-results__option--highlighted[aria-selected],.darkblue-skin .pricing-bottom .btn-pricing:hover,.darkblue-skin .property-listing.property-1 .listing-detail-btn .more-btn,.darkblue-skin .property-search-type label.active,.darkblue-skin .property-search-type label:hover,.darkblue-skin .radio-custom:checked+.radio-custom-label:before,.darkblue-skin .range-slider .ui-slider .ui-slider-handle,.darkblue-skin .range-slider .ui-widget-header,.darkblue-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.darkblue-skin .single-widgets.widget_search form button,.darkblue-skin .single-widgets.widget_tags ul li a:focus,.darkblue-skin .single-widgets.widget_tags ul li a:hover,.darkblue-skin .theme-bg,.darkblue-skin li.login-attri.theme-log a{
    background-color:#0f52ba;
    background:#0f52ba
}
.darkblue-skin .attributes li.submit-attri.theme-log a,.darkblue-skin .bl-continue,.darkblue-skin .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date,.darkblue-skin .blog-page .blog-details blockquote .icon,.darkblue-skin .btn-outline-theme,.darkblue-skin .btn.btn-theme-light,.darkblue-skin .btn.search-btn-outline,.darkblue-skin .client-info h5,.darkblue-skin .cn-info-icon i,.darkblue-skin .d-navigation ul li.active a,.darkblue-skin .dw-proprty-info li,.darkblue-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.darkblue-skin .listing-card-info-price,.darkblue-skin .ps-trep .ps-type,.darkblue-skin .recommended .pr-value,.darkblue-skin .single-post-pagination .post-pagination-center-grid a,.darkblue-skin .theme-cl,.darkblue-skin a.link:focus,.darkblue-skin a.link:hover,.darkblue-skin a:hover,.darkblue-skin nav .menu .mg-menu li a i,.darkblue-skin nav .menu li a.active,.darkblue-skin nav .menu li a:hover,.darkblue-skin nav .menu li.dropdown.open>a,.darkblue-skin span.mod-close,a:focus{
    color:#0f52ba
}
.darkblue-skin .attributes li.submit-attri.theme-log a,.darkblue-skin .btn-outline-theme,.darkblue-skin .btn-outline-theme:focus,.darkblue-skin .btn-outline-theme:hover,.darkblue-skin .btn-theme-2:focus,.darkblue-skin .btn-theme-2:hover,.darkblue-skin .btn.btn-theme,.darkblue-skin .btn.btn-theme:focus,.darkblue-skin .btn.btn-theme:hover,.darkblue-skin .btn.pop-login,.darkblue-skin .btn.search-btn-outline,.darkblue-skin .btn.search-btn-outline:focus,.darkblue-skin .btn.search-btn-outline:hover,.darkblue-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.darkblue-skin .nav-tabs .nav-item.show .nav-link,.darkblue-skin .nav-tabs .nav-link.active,.darkblue-skin .pagination li:first-child a,.darkblue-skin .pagination>.active>a,.darkblue-skin .pagination>.active>a:focus,.darkblue-skin .pagination>.active>a:hover,.darkblue-skin .pagination>.active>span,.darkblue-skin .pagination>.active>span:focus,.darkblue-skin .pagination>.active>span:hover,.darkblue-skin .pagination>li>a:focus,.darkblue-skin .pagination>li>a:hover,.darkblue-skin .property-listing.property-1 .listing-detail-btn .more-btn,.darkblue-skin .range-slider .ui-slider .ui-slider-handle,.darkblue-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.darkblue-skin .single-widgets.widget_tags ul li a:focus,.darkblue-skin .single-widgets.widget_tags ul li a:hover{
    border-color:#0f52ba
}
.darkblue-skin li.login-attri.theme-log a{
    box-shadow:0 8px 5px rgba(15,82,186,.2);
    -webkit-box-shadow:0 8px 5px rgba(15,82,186,.2)
}
.darkblue-skin .btn.btn-theme-light{
    background:rgba(15,82,186,.1)
}
.oceangreen-skin .btn-outline-theme:focus,.oceangreen-skin .btn-outline-theme:hover,.oceangreen-skin .btn-theme-2:focus,.oceangreen-skin .btn-theme-2:hover,.oceangreen-skin .btn.btn-theme,.oceangreen-skin .btn.btn-theme:focus,.oceangreen-skin .btn.btn-theme:hover,.oceangreen-skin .btn.pop-login,.oceangreen-skin .btn.search-btn,.oceangreen-skin .btn.search-btn-outline:focus,.oceangreen-skin .btn.search-btn-outline:hover,.oceangreen-skin .checkbox-custom:checked+.checkbox-custom-label:before,.oceangreen-skin .home-slider-desc .read-more,.oceangreen-skin .nav-tabs .nav-item.show .nav-link,.oceangreen-skin .nav-tabs .nav-link.active,.oceangreen-skin .pagination li:first-child a,.oceangreen-skin .pagination>.active>a,.oceangreen-skin .pagination>.active>a:focus,.oceangreen-skin .pagination>.active>a:hover,.oceangreen-skin .pagination>.active>span,.oceangreen-skin .pagination>.active>span:focus,.oceangreen-skin .pagination>.active>span:hover,.oceangreen-skin .pagination>li>a:focus,.oceangreen-skin .pagination>li>a:hover,.oceangreen-skin .pricing-bottom .btn-pricing:focus .oceangreen-skin .select2-container--default .select2-results__option--highlighted[aria-selected],.oceangreen-skin .pricing-bottom .btn-pricing:hover,.oceangreen-skin .property-listing.property-1 .listing-detail-btn .more-btn,.oceangreen-skin .property-search-type label.active,.oceangreen-skin .property-search-type label:hover,.oceangreen-skin .radio-custom:checked+.radio-custom-label:before,.oceangreen-skin .range-slider .ui-slider .ui-slider-handle,.oceangreen-skin .range-slider .ui-widget-header,.oceangreen-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.oceangreen-skin .single-widgets.widget_search form button,.oceangreen-skin .single-widgets.widget_tags ul li a:focus,.oceangreen-skin .single-widgets.widget_tags ul li a:hover,.oceangreen-skin .theme-bg,.oceangreen-skin li.login-attri.theme-log a{
    background-color:#00a877;
    background:#00a877
}
.oceangreen-skin .attributes li.submit-attri.theme-log a,.oceangreen-skin .bl-continue,.oceangreen-skin .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date,.oceangreen-skin .blog-page .blog-details blockquote .icon,.oceangreen-skin .btn-outline-theme,.oceangreen-skin .btn.btn-theme-light,.oceangreen-skin .btn.search-btn-outline,.oceangreen-skin .client-info h5,.oceangreen-skin .cn-info-icon i,.oceangreen-skin .d-navigation ul li.active a,.oceangreen-skin .dw-proprty-info li,.oceangreen-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.oceangreen-skin .listing-card-info-price,.oceangreen-skin .ps-trep .ps-type,.oceangreen-skin .recommended .pr-value,.oceangreen-skin .single-post-pagination .post-pagination-center-grid a,.oceangreen-skin .theme-cl,.oceangreen-skin a.link:focus,.oceangreen-skin a.link:hover,.oceangreen-skin a:hover,.oceangreen-skin nav .menu .mg-menu li a i,.oceangreen-skin nav .menu li a.active,.oceangreen-skin nav .menu li a:hover,.oceangreen-skin nav .menu li.dropdown.open>a,.oceangreen-skin span.mod-close,a:focus{
    color:#00a877
}
.oceangreen-skin .attributes li.submit-attri.theme-log a,.oceangreen-skin .btn-outline-theme,.oceangreen-skin .btn-outline-theme:focus,.oceangreen-skin .btn-outline-theme:hover,.oceangreen-skin .btn-theme-2:focus,.oceangreen-skin .btn-theme-2:hover,.oceangreen-skin .btn.btn-theme,.oceangreen-skin .btn.btn-theme:focus,.oceangreen-skin .btn.btn-theme:hover,.oceangreen-skin .btn.pop-login,.oceangreen-skin .btn.search-btn-outline,.oceangreen-skin .btn.search-btn-outline:focus,.oceangreen-skin .btn.search-btn-outline:hover,.oceangreen-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.oceangreen-skin .nav-tabs .nav-item.show .nav-link,.oceangreen-skin .nav-tabs .nav-link.active,.oceangreen-skin .pagination li:first-child a,.oceangreen-skin .pagination>.active>a,.oceangreen-skin .pagination>.active>a:focus,.oceangreen-skin .pagination>.active>a:hover,.oceangreen-skin .pagination>.active>span,.oceangreen-skin .pagination>.active>span:focus,.oceangreen-skin .pagination>.active>span:hover,.oceangreen-skin .pagination>li>a:focus,.oceangreen-skin .pagination>li>a:hover,.oceangreen-skin .property-listing.property-1 .listing-detail-btn .more-btn,.oceangreen-skin .range-slider .ui-slider .ui-slider-handle,.oceangreen-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.oceangreen-skin .single-widgets.widget_tags ul li a:focus,.oceangreen-skin .single-widgets.widget_tags ul li a:hover{
    border-color:#00a877
}
.oceangreen-skin li.login-attri.theme-log a{
    box-shadow:0 8px 5px rgba(0,168,119,.2);
    -webkit-box-shadow:0 8px 5px rgba(0,168,119,.2)
}
.oceangreen-skin .btn.btn-theme-light{
    background:rgba(0,168,119,.1)
}
.purple-skin .btn-outline-theme:focus,.purple-skin .btn-outline-theme:hover,.purple-skin .btn-theme-2:focus,.purple-skin .btn-theme-2:hover,.purple-skin .btn.btn-theme,.purple-skin .btn.btn-theme:focus,.purple-skin .btn.btn-theme:hover,.purple-skin .btn.pop-login,.purple-skin .btn.search-btn,.purple-skin .btn.search-btn-outline:focus,.purple-skin .btn.search-btn-outline:hover,.purple-skin .checkbox-custom:checked+.checkbox-custom-label:before,.purple-skin .home-slider-desc .read-more,.purple-skin .nav-tabs .nav-item.show .nav-link,.purple-skin .nav-tabs .nav-link.active,.purple-skin .pagination li:first-child a,.purple-skin .pagination>.active>a,.purple-skin .pagination>.active>a:focus,.purple-skin .pagination>.active>a:hover,.purple-skin .pagination>.active>span,.purple-skin .pagination>.active>span:focus,.purple-skin .pagination>.active>span:hover,.purple-skin .pagination>li>a:focus,.purple-skin .pagination>li>a:hover,.purple-skin .pricing-bottom .btn-pricing:focus .purple-skin .select2-container--default .select2-results__option--highlighted[aria-selected],.purple-skin .pricing-bottom .btn-pricing:hover,.purple-skin .property-listing.property-1 .listing-detail-btn .more-btn,.purple-skin .property-search-type label.active,.purple-skin .property-search-type label:hover,.purple-skin .radio-custom:checked+.radio-custom-label:before,.purple-skin .range-slider .ui-slider .ui-slider-handle,.purple-skin .range-slider .ui-widget-header,.purple-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.purple-skin .single-widgets.widget_search form button,.purple-skin .single-widgets.widget_tags ul li a:focus,.purple-skin .single-widgets.widget_tags ul li a:hover,.purple-skin .theme-bg,.purple-skin li.login-attri.theme-log a{
    background-color:#7065ef;
    background:#7065ef
}
.purple-skin .attributes li.submit-attri.theme-log a,.purple-skin .bl-continue,.purple-skin .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date,.purple-skin .blog-page .blog-details blockquote .icon,.purple-skin .btn-outline-theme,.purple-skin .btn.btn-theme-light,.purple-skin .btn.search-btn-outline,.purple-skin .client-info h5,.purple-skin .cn-info-icon i,.purple-skin .d-navigation ul li.active a,.purple-skin .dw-proprty-info li,.purple-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.purple-skin .listing-card-info-price,.purple-skin .ps-trep .ps-type,.purple-skin .recommended .pr-value,.purple-skin .single-post-pagination .post-pagination-center-grid a,.purple-skin .theme-cl,.purple-skin a.link:focus,.purple-skin a.link:hover,.purple-skin a:hover,.purple-skin nav .menu .mg-menu li a i,.purple-skin nav .menu li a.active,.purple-skin nav .menu li a:hover,.purple-skin nav .menu li.dropdown.open>a,.purple-skin span.mod-close,a:focus{
    color:#7065ef
}
.purple-skin .attributes li.submit-attri.theme-log a,.purple-skin .btn-outline-theme,.purple-skin .btn-outline-theme:focus,.purple-skin .btn-outline-theme:hover,.purple-skin .btn-theme-2:focus,.purple-skin .btn-theme-2:hover,.purple-skin .btn.btn-theme,.purple-skin .btn.btn-theme:focus,.purple-skin .btn.btn-theme:hover,.purple-skin .btn.pop-login,.purple-skin .btn.search-btn-outline,.purple-skin .btn.search-btn-outline:focus,.purple-skin .btn.search-btn-outline:hover,.purple-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.purple-skin .nav-tabs .nav-item.show .nav-link,.purple-skin .nav-tabs .nav-link.active,.purple-skin .pagination li:first-child a,.purple-skin .pagination>.active>a,.purple-skin .pagination>.active>a:focus,.purple-skin .pagination>.active>a:hover,.purple-skin .pagination>.active>span,.purple-skin .pagination>.active>span:focus,.purple-skin .pagination>.active>span:hover,.purple-skin .pagination>li>a:focus,.purple-skin .pagination>li>a:hover,.purple-skin .property-listing.property-1 .listing-detail-btn .more-btn,.purple-skin .range-slider .ui-slider .ui-slider-handle,.purple-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.purple-skin .single-widgets.widget_tags ul li a:focus,.purple-skin .single-widgets.widget_tags ul li a:hover{
    border-color:#7065ef
}
.purple-skin li.login-attri.theme-log a{
    box-shadow:0 8px 5px rgba(112,101,239,.2);
    -webkit-box-shadow:0 8px 5px rgba(112,101,239,.2)
}
.purple-skin .btn.btn-theme-light{
    background:rgba(112,101,239,.1)
}
.goodred-skin .btn-outline-theme:focus,.goodred-skin .btn-outline-theme:hover,.goodred-skin .btn-theme-2:focus,.goodred-skin .btn-theme-2:hover,.goodred-skin .btn.btn-theme,.goodred-skin .btn.btn-theme:focus,.goodred-skin .btn.btn-theme:hover,.goodred-skin .btn.pop-login,.goodred-skin .btn.search-btn,.goodred-skin .btn.search-btn-outline:focus,.goodred-skin .btn.search-btn-outline:hover,.goodred-skin .checkbox-custom:checked+.checkbox-custom-label:before,.goodred-skin .home-slider-desc .read-more,.goodred-skin .nav-tabs .nav-item.show .nav-link,.goodred-skin .nav-tabs .nav-link.active,.goodred-skin .pagination li:first-child a,.goodred-skin .pagination>.active>a,.goodred-skin .pagination>.active>a:focus,.goodred-skin .pagination>.active>a:hover,.goodred-skin .pagination>.active>span,.goodred-skin .pagination>.active>span:focus,.goodred-skin .pagination>.active>span:hover,.goodred-skin .pagination>li>a:focus,.goodred-skin .pagination>li>a:hover,.goodred-skin .pricing-bottom .btn-pricing:focus .goodred-skin .select2-container--default .select2-results__option--highlighted[aria-selected],.goodred-skin .pricing-bottom .btn-pricing:hover,.goodred-skin .property-listing.property-1 .listing-detail-btn .more-btn,.goodred-skin .property-search-type label.active,.goodred-skin .property-search-type label:hover,.goodred-skin .radio-custom:checked+.radio-custom-label:before,.goodred-skin .range-slider .ui-slider .ui-slider-handle,.goodred-skin .range-slider .ui-widget-header,.goodred-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.goodred-skin .single-widgets.widget_search form button,.goodred-skin .single-widgets.widget_tags ul li a:focus,.goodred-skin .single-widgets.widget_tags ul li a:hover,.goodred-skin .theme-bg,.goodred-skin li.login-attri.theme-log a{
    background-color:#f40c43;
    background:#f40c43
}
.goodred-skin .attributes li.submit-attri.theme-log a,.goodred-skin .bl-continue,.goodred-skin .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date,.goodred-skin .blog-page .blog-details blockquote .icon,.goodred-skin .btn-outline-theme,.goodred-skin .btn.btn-theme-light,.goodred-skin .btn.search-btn-outline,.goodred-skin .client-info h5,.goodred-skin .cn-info-icon i,.goodred-skin .d-navigation ul li.active a,.goodred-skin .dw-proprty-info li,.goodred-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.goodred-skin .listing-card-info-price,.goodred-skin .ps-trep .ps-type,.goodred-skin .recommended .pr-value,.goodred-skin .single-post-pagination .post-pagination-center-grid a,.goodred-skin .theme-cl,.goodred-skin a.link:focus,.goodred-skin a.link:hover,.goodred-skin a:hover,.goodred-skin nav .menu .mg-menu li a i,.goodred-skin nav .menu li a.active,.goodred-skin nav .menu li a:hover,.goodred-skin nav .menu li.dropdown.open>a,.goodred-skin span.mod-close,a:focus{
    color:#f40c43
}
.goodred-skin .attributes li.submit-attri.theme-log a,.goodred-skin .btn-outline-theme,.goodred-skin .btn-outline-theme:focus,.goodred-skin .btn-outline-theme:hover,.goodred-skin .btn-theme-2:focus,.goodred-skin .btn-theme-2:hover,.goodred-skin .btn.btn-theme,.goodred-skin .btn.btn-theme:focus,.goodred-skin .btn.btn-theme:hover,.goodred-skin .btn.pop-login,.goodred-skin .btn.search-btn-outline,.goodred-skin .btn.search-btn-outline:focus,.goodred-skin .btn.search-btn-outline:hover,.goodred-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.goodred-skin .nav-tabs .nav-item.show .nav-link,.goodred-skin .nav-tabs .nav-link.active,.goodred-skin .pagination li:first-child a,.goodred-skin .pagination>.active>a,.goodred-skin .pagination>.active>a:focus,.goodred-skin .pagination>.active>a:hover,.goodred-skin .pagination>.active>span,.goodred-skin .pagination>.active>span:focus,.goodred-skin .pagination>.active>span:hover,.goodred-skin .pagination>li>a:focus,.goodred-skin .pagination>li>a:hover,.goodred-skin .property-listing.property-1 .listing-detail-btn .more-btn,.goodred-skin .range-slider .ui-slider .ui-slider-handle,.goodred-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.goodred-skin .single-widgets.widget_tags ul li a:focus,.goodred-skin .single-widgets.widget_tags ul li a:hover{
    border-color:#f40c43
}
.goodred-skin li.login-attri.theme-log a{
    box-shadow:0 8px 5px rgba(244,12,67,.2);
    -webkit-box-shadow:0 8px 5px rgba(244,12,67,.2)
}
.goodred-skin .btn.btn-theme-light{
    background:rgba(244,12,67,.1)
}
.goodgreen-skin .btn-outline-theme:focus,.goodgreen-skin .btn-outline-theme:hover,.goodgreen-skin .btn-theme-2:focus,.goodgreen-skin .btn-theme-2:hover,.goodgreen-skin .btn.btn-theme,.goodgreen-skin .btn.btn-theme:focus,.goodgreen-skin .btn.btn-theme:hover,.goodgreen-skin .btn.pop-login,.goodgreen-skin .btn.search-btn,.goodgreen-skin .btn.search-btn-outline:focus,.goodgreen-skin .btn.search-btn-outline:hover,.goodgreen-skin .checkbox-custom:checked+.checkbox-custom-label:before,.goodgreen-skin .home-slider-desc .read-more,.goodgreen-skin .nav-tabs .nav-item.show .nav-link,.goodgreen-skin .nav-tabs .nav-link.active,.goodgreen-skin .pagination li:first-child a,.goodgreen-skin .pagination>.active>a,.goodgreen-skin .pagination>.active>a:focus,.goodgreen-skin .pagination>.active>a:hover,.goodgreen-skin .pagination>.active>span,.goodgreen-skin .pagination>.active>span:focus,.goodgreen-skin .pagination>.active>span:hover,.goodgreen-skin .pagination>li>a:focus,.goodgreen-skin .pagination>li>a:hover,.goodgreen-skin .pricing-bottom .btn-pricing:focus .goodgreen-skin .select2-container--default .select2-results__option--highlighted[aria-selected],.goodgreen-skin .pricing-bottom .btn-pricing:hover,.goodgreen-skin .property-listing.property-1 .listing-detail-btn .more-btn,.goodgreen-skin .property-search-type label.active,.goodgreen-skin .property-search-type label:hover,.goodgreen-skin .radio-custom:checked+.radio-custom-label:before,.goodgreen-skin .range-slider .ui-slider .ui-slider-handle,.goodgreen-skin .range-slider .ui-widget-header,.goodgreen-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.goodgreen-skin .single-widgets.widget_search form button,.goodgreen-skin .single-widgets.widget_tags ul li a:focus,.goodgreen-skin .single-widgets.widget_tags ul li a:hover,.goodgreen-skin .theme-bg,.goodgreen-skin li.login-attri.theme-log a{
    background-color:#19b521;
    background:#19b521
}
.goodgreen-skin .attributes li.submit-attri.theme-log a,.goodgreen-skin .bl-continue,.goodgreen-skin .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date,.goodgreen-skin .blog-page .blog-details blockquote .icon,.goodgreen-skin .btn-outline-theme,.goodgreen-skin .btn.btn-theme-light,.goodgreen-skin .btn.search-btn-outline,.goodgreen-skin .client-info h5,.goodgreen-skin .cn-info-icon i,.goodgreen-skin .d-navigation ul li.active a,.goodgreen-skin .dw-proprty-info li,.goodgreen-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.goodgreen-skin .listing-card-info-price,.goodgreen-skin .ps-trep .ps-type,.goodgreen-skin .recommended .pr-value,.goodgreen-skin .single-post-pagination .post-pagination-center-grid a,.goodgreen-skin .theme-cl,.goodgreen-skin a.link:focus,.goodgreen-skin a.link:hover,.goodgreen-skin a:hover,.goodgreen-skin nav .menu .mg-menu li a i,.goodgreen-skin nav .menu li a.active,.goodgreen-skin nav .menu li a:hover,.goodgreen-skin nav .menu li.dropdown.open>a,.goodgreen-skin span.mod-close,a:focus{
    color:#19b521
}
.goodgreen-skin .attributes li.submit-attri.theme-log a,.goodgreen-skin .btn-outline-theme,.goodgreen-skin .btn-outline-theme:focus,.goodgreen-skin .btn-outline-theme:hover,.goodgreen-skin .btn-theme-2:focus,.goodgreen-skin .btn-theme-2:hover,.goodgreen-skin .btn.btn-theme,.goodgreen-skin .btn.btn-theme:focus,.goodgreen-skin .btn.btn-theme:hover,.goodgreen-skin .btn.pop-login,.goodgreen-skin .btn.search-btn-outline,.goodgreen-skin .btn.search-btn-outline:focus,.goodgreen-skin .btn.search-btn-outline:hover,.goodgreen-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.goodgreen-skin .nav-tabs .nav-item.show .nav-link,.goodgreen-skin .nav-tabs .nav-link.active,.goodgreen-skin .pagination li:first-child a,.goodgreen-skin .pagination>.active>a,.goodgreen-skin .pagination>.active>a:focus,.goodgreen-skin .pagination>.active>a:hover,.goodgreen-skin .pagination>.active>span,.goodgreen-skin .pagination>.active>span:focus,.goodgreen-skin .pagination>.active>span:hover,.goodgreen-skin .pagination>li>a:focus,.goodgreen-skin .pagination>li>a:hover,.goodgreen-skin .property-listing.property-1 .listing-detail-btn .more-btn,.goodgreen-skin .range-slider .ui-slider .ui-slider-handle,.goodgreen-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.goodgreen-skin .single-widgets.widget_tags ul li a:focus,.goodgreen-skin .single-widgets.widget_tags ul li a:hover{
    border-color:#19b521
}
.goodgreen-skin li.login-attri.theme-log a{
    box-shadow:0 8px 5px rgba(25,181,33,.2);
    -webkit-box-shadow:0 8px 5px rgba(25,181,33,.2)
}
.goodgreen-skin .btn.btn-theme-light{
    background:rgba(25,181,33,.1)
}
.blue2-skin .btn-outline-theme:focus,.blue2-skin .btn-outline-theme:hover,.blue2-skin .btn-theme-2:focus,.blue2-skin .btn-theme-2:hover,.blue2-skin .btn.btn-theme,.blue2-skin .btn.btn-theme:focus,.blue2-skin .btn.btn-theme:hover,.blue2-skin .btn.pop-login,.blue2-skin .btn.search-btn,.blue2-skin .btn.search-btn-outline:focus,.blue2-skin .btn.search-btn-outline:hover,.blue2-skin .checkbox-custom:checked+.checkbox-custom-label:before,.blue2-skin .home-slider-desc .read-more,.blue2-skin .nav-tabs .nav-item.show .nav-link,.blue2-skin .nav-tabs .nav-link.active,.blue2-skin .pagination li:first-child a,.blue2-skin .pagination>.active>a,.blue2-skin .pagination>.active>a:focus,.blue2-skin .pagination>.active>a:hover,.blue2-skin .pagination>.active>span,.blue2-skin .pagination>.active>span:focus,.blue2-skin .pagination>.active>span:hover,.blue2-skin .pagination>li>a:focus,.blue2-skin .pagination>li>a:hover,.blue2-skin .pricing-bottom .btn-pricing:focus .blue2-skin .select2-container--default .select2-results__option--highlighted[aria-selected],.blue2-skin .pricing-bottom .btn-pricing:hover,.blue2-skin .property-listing.property-1 .listing-detail-btn .more-btn,.blue2-skin .property-search-type label.active,.blue2-skin .property-search-type label:hover,.blue2-skin .radio-custom:checked+.radio-custom-label:before,.blue2-skin .range-slider .ui-slider .ui-slider-handle,.blue2-skin .range-slider .ui-widget-header,.blue2-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.blue2-skin .single-widgets.widget_search form button,.blue2-skin .single-widgets.widget_tags ul li a:focus,.blue2-skin .single-widgets.widget_tags ul li a:hover,.blue2-skin .theme-bg,.blue2-skin li.login-attri.theme-log a{
    background-color:#0176ff;
    background:#0176ff
}
.blue2-skin .attributes li.submit-attri.theme-log a,.blue2-skin .bl-continue,.blue2-skin .blog-page .blog-details .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date,.blue2-skin .blog-page .blog-details blockquote .icon,.blue2-skin .btn-outline-theme,.blue2-skin .btn.btn-theme-light,.blue2-skin .btn.search-btn-outline,.blue2-skin .client-info h5,.blue2-skin .cn-info-icon i,.blue2-skin .d-navigation ul li.active a,.blue2-skin .dw-proprty-info li,.blue2-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.blue2-skin .listing-card-info-price,.blue2-skin .ps-trep .ps-type,.blue2-skin .recommended .pr-value,.blue2-skin .single-post-pagination .post-pagination-center-grid a,.blue2-skin .theme-cl,.blue2-skin a.link:focus,.blue2-skin a.link:hover,.blue2-skin a:hover,.blue2-skin nav .menu .mg-menu li a i,.blue2-skin nav .menu li a.active,.blue2-skin nav .menu li a:hover,.blue2-skin nav .menu li.dropdown.open>a,.blue2-skin span.mod-close,a:focus{
    color:#0176ff
}
.blue2-skin .attributes li.submit-attri.theme-log a,.blue2-skin .btn-outline-theme,.blue2-skin .btn-outline-theme:focus,.blue2-skin .btn-outline-theme:hover,.blue2-skin .btn-theme-2:focus,.blue2-skin .btn-theme-2:hover,.blue2-skin .btn.btn-theme,.blue2-skin .btn.btn-theme:focus,.blue2-skin .btn.btn-theme:hover,.blue2-skin .btn.pop-login,.blue2-skin .btn.search-btn-outline,.blue2-skin .btn.search-btn-outline:focus,.blue2-skin .btn.search-btn-outline:hover,.blue2-skin .header-dark-transparent.header-fixed .attributes li.submit-attri a,.blue2-skin .nav-tabs .nav-item.show .nav-link,.blue2-skin .nav-tabs .nav-link.active,.blue2-skin .pagination li:first-child a,.blue2-skin .pagination>.active>a,.blue2-skin .pagination>.active>a:focus,.blue2-skin .pagination>.active>a:hover,.blue2-skin .pagination>.active>span,.blue2-skin .pagination>.active>span:focus,.blue2-skin .pagination>.active>span:hover,.blue2-skin .pagination>li>a:focus,.blue2-skin .pagination>li>a:hover,.blue2-skin .property-listing.property-1 .listing-detail-btn .more-btn,.blue2-skin .range-slider .ui-slider .ui-slider-handle,.blue2-skin .simple-search-wrap .pk-input-group .pk-subscribe-submit,.blue2-skin .single-widgets.widget_tags ul li a:focus,.blue2-skin .single-widgets.widget_tags ul li a:hover{
    border-color:#0176ff
}
.blue2-skin li.login-attri.theme-log a{
    box-shadow:0 8px 5px rgba(1,118,255,.2);
    -webkit-box-shadow:0 8px 5px rgba(1,118,255,.2)
}
.blue2-skin .btn.btn-theme-light{
    background:rgba(1,118,255,.1)
}
.half-circle-spinner,.half-circle-spinner *{
    box-sizing:border-box
}
.half-circle-spinner{
    border-radius:100%;
    height:60px;
    margin:20px auto;
    position:relative;
    width:60px
}
.half-circle-spinner .circle{
    border:6px solid transparent;
    border-radius:100%;
    content:"";
    height:100%;
    position:absolute;
    width:100%
}
.half-circle-spinner .circle.circle-1{
    animation:half-circle-spinner-animation 1s infinite;
    border-top-color:#2b4db9
}
.half-circle-spinner .circle.circle-2{
    animation:half-circle-spinner-animation 1s infinite alternate;
    border-bottom-color:#2b4db9
}
@keyframes half-circle-spinner-animation{
    0%{
        transform:rotate(0deg)
    }
    to{
        transform:rotate(1turn)
    }
}
.header-select-language{
    padding:30px 12px
}
.language_bar_list li a img{
    margin-top:2px
}
.topbar{
    background:var(--primary-color);
    font-size:14px
}
.topbar .language_bar_list{
    margin-bottom:0;
    padding-left:0
}
.topbar .language_bar_list li a span{
    color:#fff
}
.fr-grid-detail{
    align-items:center;
    display:flex;
    padding:0 1.5rem 1rem;
    width:100%
}
.fr-grid-detail-flex{
    flex:1
}
.fr-grid-detail-flex .fr-can-name{
    margin-bottom:0
}
.blog-details img,.property-detail img{
    max-width:100%
}
#alert-container{
    position:fixed;
    right:5px;
    top:100px;
    z-index:9999999
}
#alert-container .alert{
    box-shadow:0 4px 10px 0 rgba(3,3,3,.1);
    font-weight:400;
    margin-bottom:10px;
    max-width:450px!important;
    min-height:45px!important;
    padding:15px 40px 15px 60px;
    position:relative
}
#alert-container .alert.alert-success{
    background-color:#bff9d0;
    color:#299c77
}
#alert-container .alert .message-icon{
    fill:#299c77;
    stroke:#299c77;
    font-size:20px;
    left:5%;
    position:absolute;
    top:30%
}
#alert-container .alert.alert-dismissible .close{
    font-size:10px;
    padding:0;
    position:absolute;
    right:15px;
    top:20px
}
.boxmarker{
    background-color:#2b4db9;
    border-radius:3px;
    box-shadow:0 0 5px #333;
    color:#fff;
    font-weight:700;
    padding:2px 5px;
    text-align:center
}
.leaflet-cluster-anim .leaflet-marker-icon,.leaflet-cluster-anim .leaflet-marker-shadow{
    transition:transform .3s ease-out,opacity .3s ease-in
}
.leaflet-cluster-spider-leg{
    transition:stroke-dashoffset .3s ease-out,stroke-opacity .3s ease-in
}
.marker-cluster-small{
    background-color:hsla(91,60%,72%,.6)
}
.marker-cluster-small div{
    background-color:rgba(110,204,57,.6)
}
.marker-cluster-medium{
    background-color:rgba(241,211,87,.6)
}
.marker-cluster-medium div{
    background-color:rgba(240,194,12,.6)
}
.marker-cluster-large{
    background-color:hsla(18,97%,72%,.6)
}
.marker-cluster-large div{
    background-color:rgba(241,128,23,.6)
}
.leaflet-oldie .marker-cluster-small{
    background-color:#b5e28c
}
.leaflet-oldie .marker-cluster-small div{
    background-color:#6ecc39
}
.leaflet-oldie .marker-cluster-medium{
    background-color:#f1d357
}
.leaflet-oldie .marker-cluster-medium div{
    background-color:#f0c20c
}
.leaflet-oldie .marker-cluster-large{
    background-color:#fd9c73
}
.leaflet-oldie .marker-cluster-large div{
    background-color:#f18017
}
.marker-cluster{
    background-clip:padding-box;
    border-radius:20px
}
.marker-cluster div{
    border-radius:15px;
    font:12px Helvetica Neue,Arial,Helvetica,sans-serif;
    height:30px;
    margin-left:5px;
    margin-top:5px;
    text-align:center;
    width:30px
}
.marker-cluster span{
    line-height:30px
}
#trafficMap{
    min-height:400px;
    z-index:9
}
#trafficMap .boxmarker{
    background-color:#2b4db9;
    border-radius:3px;
    box-shadow:0 0 5px #333;
    color:#fff;
    display:inline-block;
    font-weight:700;
    padding:2px 5px;
    text-align:center;
    white-space:nowrap;
    width:auto!important
}
@media screen and (max-height:400px){
    #trafficMap{
        max-height:99vh;
        min-height:99vh
    }
}
.status-label{
    background-color:#2b4db9;
    color:#fff;
    font-size:1rem;
    padding:3px 7px
}
.status-label.label-success{
    background-color:#36c6d3
}
.status-label.label-danger{
    background-color:#ed6b75
}
.status-label.label-warning{
    background-color:#f1c40f
}
.status-label.label-info{
    background-color:#659be0
}
#map.leaflet-container a.leaflet-popup-close-button{
    color:#fd5332
}
.leaflet-pane .listing-card-info-price{
    padding-left:0
}
.leaflet-pane .property-listing{
    margin-bottom:0
}
.leaflet-pane .leaflet-popup-content{
    margin:0
}
.leaflet-pane .leaflet-popup{
    margin-bottom:28px
}
.leaflet-popup-content{
    min-width:300px
}
.leaflet-popup-content .blii{
    align-items:center;
    display:flex;
    flex:0 0 100px;
    padding-top:0
}
.leaflet-popup-content .blii img{
    border-radius:4px
}
.leaflet-popup-content .infomarker a{
    color:#2d3954
}
#map{
    height:calc(100% - 103px);
    min-height: 542px;
    z-index:1
}
#map .leaflet-top{
    z-index:999
}
#map .boxmarker{
    background-color:#2b4db9;
    border-radius:2px;
    box-shadow:none;
    color:#fff;
    display:inline-block;
    font-weight:700;
    padding:2px 5px;
    text-align:center;
    white-space:nowrap;
    width:auto!important
}
#map .listing-name a{
    color:#2d3954
}
#map .leaflet-popup-content-wrapper,#map .leaflet-popup-tip{
    background:none
}
.leaflet-popup-content-wrapper{
    border-radius:4px
}
.infomarker{
    flex:auto;
    padding-left:8px
}
.infomarker h4{
    font-size:14px;
    font-weight:700;
    margin:0 0 5px;
    overflow:hidden;
    padding:0
}
.infomarker .lists_property_price{
    display:flex;
    justify-content:space-between;
    padding:0
}
.infomarker .property_types_vlix{
    font-size:12px;
    padding:4px 10px
}
.infomarker .lists_property_price_value h5{
    font-size:13px
}
.br-theme-fontawesome-stars .br-widget a{
    font-family:Font Awesome\ 5 Free!important;
    font-weight:900!important
}
.avg-total-pilx{
    background:#f2f5f9;
    border-radius:4px;
    display:inline-block;
    min-width:100px;
    padding:2rem .5rem;
    text-align:center;
    width:100%
}
.avg-total-pilx .high{
    color:#00ba74;
    font-size:36px
}
.avg-total-pilx span{
    color:#5f6c8a;
    font-size:14px
}
.rating_wrap{
    display:flex
}
.rating_wrap .rating{
    display:inline-block;
    font-family:Font Awesome\ 5 Free!important;
    font-weight:900;
    height:20px;
    overflow:hidden;
    position:relative;
    vertical-align:top;
    width:70px
}
.rating_wrap .rating:before{
    color:#d2d2d2;
    content:"\f005\f005\f005\f005\f005";
    float:left;
    font-size:11px;
    left:0;
    letter-spacing:2px;
    position:absolute;
    top:2px
}
.rating_wrap .rating_num{
    color:#696969;
    display:inline-block;
    font-size:11px;
    vertical-align:middle
}
.rating_wrap .product_rate{
    color:#ff9800;
    font-family:Font Awesome\ 5 Free!important;
    left:0;
    overflow:hidden;
    padding-top:15px;
    position:absolute;
    top:0
}
.rating_wrap .product_rate:before{
    content:"\f005\f005\f005\f005\f005";
    font-size:11px;
    font-weight:900;
    left:0;
    letter-spacing:2px;
    position:absolute;
    top:2px
}
.list-fx-features{
    margin-top:10px
}
.sorting-by{
    width:400px
}
.select2-selection__clear{
    background:none;
    border:none;
    color:#888
}
.select2-container--default,.select2-selection--single,.select2-selection__clear{
    right:26px;
    top:11px
}
.social_share_list{
    position:relative
}
.social_share_list .social_share_panel{
    background:#fff;
    border-radius:5px;
    box-shadow:0 0 20px rgba(0,0,0,.1);
    display:flex;
    left:5px;
    margin-bottom:20px;
    opacity:0;
    position:absolute;
    top:-150%;
    transition:.5s;
    visibility:hidden
}
.social_share_list:hover .social_share_panel{
    margin-bottom:20px;
    opacity:1;
    top:-100%;
    visibility:visible
}
ul.like_share_list li .social_share_panel a{
    background:0 0;
    border:none;
    border-radius:0;
    border-right:1px solid #f1f1f1;
    color:#03a98a!important;
    font-size:18px;
    padding:10px;
    text-align:center;
    width:48px
}
ul.like_share_list li .social_share_panel a i{
    margin:0
}
.button-loading{
    border:1px solid #c4cdd5;
    cursor:default;
    position:relative;
    text-shadow:none;
    transition:border-color .2s ease-out
}
.button-loading,.button-loading span{
    color:transparent!important
}
.button-loading,.button-loading:active,.button-loading:focus,.button-loading:hover{
    color:transparent
}
.button-loading:before{
    animation:button-loading-spinner 1s linear infinite;
    border:3px solid;
    border-color:#fff #fff transparent;
    border-radius:50%;
    content:"";
    height:18px;
    left:50%;
    margin-left:-9px;
    margin-top:-9px;
    position:absolute;
    top:50%;
    width:18px
}
.button-loading.btn-fill-out{
    border:1px solid #2b4db9;
    color:transparent!important
}
.button-loading.btn-fill-out:before{
    background-color:transparent;
    border-color:#2b4db9 #2b4db9 transparent;
    width:18px
}
.button-loading.btn-fill-out:after{
    display:none
}
@keyframes button-loading-spinner{
    0%{
        transform:rotate(0deg)
    }
    to{
        transform:rotate(1turn)
    }
}
@media (min-width:992px){
    .show-admin-bar .header.header-fixed{
        top:40px
    }
}
#loading{
    background:rgba(0,0,0,.3);
    bottom:0;
    display:none;
    left:0;
    position:absolute;
    right:0;
    top:0;
    z-index:10
}
#loading .half-circle-spinner .circle.circle-1{
    border-top-color:#fff
}
#loading .half-circle-spinner .circle.circle-2{
    border-bottom-color:#fff
}
#loading .half-circle-spinner{
    left:0;
    position:absolute;
    right:0;
    top:200px
}
#loading .half-circle-spinner .circle{
    border:5px solid transparent
}
.no-ul-list.second-row{
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    margin-bottom:15px;
    width:100%
}
.max-w-screen-xl{
    max-width:1280px
}
.max-w-screen-2xl{
    max-width:1536px
}
.simple-form .form-control[readonly]{
    background-color:#e9ecef;
    cursor:not-allowed;
    opacity:.5
}
.foot-location,.listing-locate,.property-listing.property-1 .listing-name,.property-listing.property-2 .listing-name{
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:normal
}
.property-listing.property-1 .listing-detail-wrapper{
    align-items:inherit;
    flex-direction:column
}
.property-listing.property-1 .listing-detail-wrapper .list-price{
    display:flex;
    justify-content:space-between
}
.prt-types{
    margin:5px 0
}
.mua-ban,.sale{
    background:rgba(247,68,0,.1);
    color:#f74400
}
.cho-thue,.rent{
    background:rgba(136,102,218,.1);
    color:#8866da
}
.property-listing.property-2 .listing-name .prt-link-detail:before{
    display:none
}
.choose-property-type ul li{
    flex:inherit
}
.social-login ul li{
    flex:0 0 100%;
    margin-top:10px;
    width:100%
}
.social-login ul li a.btn-dark{
    background:#212529
}
a.text-danger:hover{
    color:#0a58ca!important
}
.embed-responsive-16by9{
    display:block;
    height:0;
    overflow:hidden;
    padding:0 0 56.25%;
    position:relative
}
.embed-responsive .embed-responsive-item,.embed-responsive embed,.embed-responsive iframe,.embed-responsive object,.embed-responsive video{
    border:0;
    bottom:0;
    height:100%;
    left:0;
    position:absolute;
    top:0;
    width:100%
}
.listing-card-info-icon{
    font-size:.8rem
}
.topbar-items{
    color:#fff
}
.choose-currency{
    display:inline-block
}
.choose-currency a{
    margin-right:3px;
    position:relative;
    text-decoration:none
}
.choose-currency a:after{
    background:#fff;
    content:"";
    height:10px;
    position:absolute;
    right:-5px;
    top:4px;
    width:1px
}
.choose-currency a:last-child:after{
    display:none
}
.choose-currency a.active{
    font-weight:700
}
.select2-container--default,.select2-selection--single,.select2-selection__clear{
    right:0;
    top:0
}
.mobile-menu .mobile-menu-item{
    font-family:var(--font-heading);
    font-weight:500;
    padding:12px 15px 12px 26px
}
.mobile-menu .mobile-menu-item .language_bar_list{
    padding-left:0
}
.mobile-menu .mobile-menu-item .active{
    font-weight:700
}
.mobile-menu .language_bar_list li{
    float:none;
    margin-left:0
}
.footer-bottom-social{
    text-align:right
}
.select2-dropdown{
    z-index:1
}
.select2-container--default .select2-selection--single .select2-selection__clear{
    right:30px;
    top:12px
}
.featured_slick_gallery{
    max-height:610px;
    overflow:hidden
}
.property-slide{
    max-height:500px;
    overflow:hidden
}
.footer-widget{
    padding:20px 0
}
.owl-slider .owl-carousel .slider-item{
    max-height:600px
}
.nav-brand img{
    max-height:60px
}
.topbar{
    font-size:12px
}
@media (max-width:767px){
    .language-wrapper .dropdown-toggle{
        color:#4e5c79!important;
        font-size:16px;
        font-weight:500!important
    }
    .mobile-menu .mobile-menu-item.language-wrapper{
        padding:0 15px 0 26px
    }
}
.show-admin-bar .navigation-portrait .nav-menus-wrapper{
    top:40px
}
.property-listing .icon-actions-wrapper{
    background:rgba(0,0,0,.5);
    border-radius:10px;
    padding:5px 10px;
    position:absolute;
    right:15px;
    top:15px
}
.property-listing .icon-actions-wrapper .add-to-wishlist{
    color:#fff;
    display:inline-block!important;
    font-size:20px;
    line-height:1;
    min-height:unset!important
}
.currency-wrapper.mobile-menu-item .dropdown-toggle,.currency-wrapper.mobile-menu-item li a{
    color:#4e5c79!important;
    font-size:16px;
    font-weight:500!important
}
.currency-wrapper.mobile-menu-item .dropdown-toggle,.currency-wrapper.mobile-menu-item .dropdown-toggle:hover{
    color:#39466d
}
.currency-wrapper .dropdown-toggle{
    background:transparent;
    box-shadow:none!important;
    color:#fff;
    font-size:12px;
    height:inherit;
    outline:none!important;
    padding:7px 0
}
.currency-wrapper .dropdown-toggle:hover{
    color:#fff
}
.currency-wrapper .btn{
    border:none
}
.currency-wrapper .dropdown-menu{
    min-width:50px;
    padding:0;
    text-align:center
}
.currency-wrapper .language_bar_chooser{
    width:-moz-max-content;
    width:max-content
}
.currency-wrapper .language_bar_chooser li a{
    align-items:center;
    display:flex!important;
    justify-content:center;
    width:100%
}
.currency-wrapper li span{
    color:#222;
    margin-left:3px
}
.currency-wrapper li a{
    align-items:center;
    display:flex!important;
    font-size:12px;
    height:40px;
    justify-content:center;
    padding:0 10px;
    text-align:left;
    width:100%
}
.currency-wrapper li{
    display:block;
    margin:0;
    width:100%
}
.dashboard-wraper .form-control{
    height:inherit
}
#frmhomesearch .choose-property-type [type=radio]:checked,#frmhomesearch .choose-property-type [type=radio]:not(:checked){
    left:unset
}
#filter_search{
    padding-top:50px
}
#filter_search .form-group{
    margin-bottom:15px
}
@media screen and (min-width:992px){
    #filter_search{
        padding-top:20px
    }
    #filter_search .hero-search-filter-wrapper{
        display:flex;
        justify-content:space-between
    }
    #filter_search .hero-search-filters-group{
        align-items:center;
        display:flex;
        max-width:calc(100% - 200px)
    }
    #filter_search .hero-search-filters-group .form-group{
        margin-bottom:0;
        margin-right:10px;
        min-width:150px
    }
}
.property_block_wrap img{
    max-width:100%
}
.project-category{
    left:10px;
    top:10px
}

.hamburger{
    display:none;
    height: fit-content;
    cursor: pointer;
    padding:3px 8px;
    border-radius:5px;
    transition:0.2s;
}
.hamburger:hover{
    background:#2b4db9;
}

.hamburger div{
    width:30px;
    height:2px;
    margin:6px 0;
    background:#212526;
} 
  

@media only screen and (max-width:1200px){
    .nav-toggle{
        display:block;
    }
    .navigation-landscape .nav-menus-wrapper{
        -webkit-overflow-scrolling:touch;
        background-color:#fff;
        height:100%;
        left:-400px;
        overflow-y:auto;
        position:fixed;
        top:0;
        transition-duration:.8s;
        transition-timing-function:ease;
        width:320px;
        z-index:20000;
    }
    .nav-menus-wrapper.nav-menus-wrapper-right{
        left:auto;
        right:-400px;
    }
    .nav-menus-wrapper.nav-menus-wrapper-open{
        left:0;
    }
    .nav-menus-wrapper.nav-menus-wrapper-right.nav-menus-wrapper-open{
        right:0;
    }

    .navigation-landscape .nav-menu > li > a {
        height: auto;
        padding: 12px 15px 12px 26px;
        width: 100%;
    }
    
    .nav-menu>li{
        display:inline-block;
        float:left;
        text-align:left;
    }
    .navigation-landscape .nav-menu>li{
        border-top:1px solid #f0f0f0;
        position:relative;
        width:100%
    }
    .navigation-landscape .nav-menu>li:last-child{
        border-bottom:1px solid #f0f0f0
    }
    .nav-menu+.nav-menu>li:first-child{
        border-top:none;
    }

    .navigation-landscape .nav-menu.nav-menu-social{
        text-align:center;
        width:100%
    }
    .nav-menu.nav-menu-social>li{
        border:none!important;
        float:none;
        text-align:center
    }
    .navigation-landscape .nav-menu.nav-menu-social>li{
        width:auto;
        margin:0;
    }
    .nav-menu.nav-menu-social>li>a>[class*=bi-]{
        font-size:12px;
    }
    .nav-menu.nav-menu-social>li>a>.fa{
        font-size:14px
    }
    .navigation-landscape .nav-menu.nav-menu-social>li>a{
        padding:15px;
    }
    .navigation-landscape .nav-menu.nav-menu-social {
        padding-top: 1rem;
    }


    .nav-menus-wrapper-close-button{
        color:#70798b;
        cursor:pointer;
        display:none;
        float:right;
        font-size:26px;
        height:40px;
        margin:10px 7px;
        width:30px;
    }
    .navigation-landscape .nav-menus-wrapper-close-button{
        display:block;
    }
}

.img-fluid{
    cursor:pointer;
}